import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from '../context';
import OrderService from '../service/OrderService';
import moment from 'moment'
import { Dialog } from 'primereact/dialog';
import { isMobile } from '../utilities/general';
const DashboardCustomerUser = () => {
    const [orderToDisplay, setOrderToDisplay] = useState();
    const [visibleBanner, setVisibleBanner] = useState(true);
    const orderService = new OrderService();

    const authContext = useContext(AuthContext);
    useEffect(() => {
        let searchObject = {
            userId: authContext.id,
            minDate: moment().format("YYYY-MM-DD").toString(),
            maxDate: moment().format("YYYY-MM-DD").toString(),
            pageNumber: 0,
            pageSize: 1
        }
        orderService.search(searchObject).then(data => {
            if (data?.items?.length === 1) {
                setOrderToDisplay(data.items[0])
            }
        })

    }, [])
    const returnMenuByCategory = (data) => {
        return <div className="col-12">
            <div className="card m-3 border-1 surface-border">
                <div className='flex justify-content-end'>
                    <span className={`product-badge status`}>{data.isVegetarian ? <img src={"assets/layout/images/vegetarian.jpg"} alt={data.name} className="h-2rem" />
                        : <></>}</span>
                    <span className={`product-badge status`}>{data.isVegan ? <img src={"assets/layout/images/vegan.png"} alt={data.name} className="h-2rem" />
                        : <></>}</span>
                    <span className={`product-badge status`}>{data.isLenten ? <img src={"assets/layout/images/posno.svg"} alt={data.name} className="h-2rem" />
                        : <></>}</span>
                </div>
                <div className="text-center">
                    <img src={"data:image/png;base64," + data.image} alt={data.name} className="h-8rem my-3 mx-0" />
                    <div className="text-2xl font-bold">{data.name}</div>
                    <div className="mb-3">{data.description}</div>
                </div>
            </div>
        </div>
    }
    const displayBanner = true

    const { t } = useTranslation();
    return (
        <div className="layout-dashboard">
            <div className="grid">

                <div className="col-12">
                    <div className='grid'>
                        <div className='col-12 lg:col-8'>
                            <div className="card card-w-title tasks w-full text-center">
                                <h5>{t('todayOrder')}</h5>
                                {orderToDisplay ?
                                    orderToDisplay?.menuItems?.map(x => {
                                        return returnMenuByCategory(x)
                                    })
                                    : <div className="col-12">
                                        <div className="card m-3 border-1 surface-border">
                                            <div className="text-center">
                                                {t('noOrder')}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="col-12 lg:col-4">
                            <div className='grid'>
                                <div className='col-12'>
                                    <div className="user-card card">
                                        <div className="user-card-header">
                                            <img src="assets/layout/images/dashboard/bg-header.png" alt="babylon-layout" className="profile-image" />
                                        </div>
                                        <div className="user-card-content">
                                            <img src="assets/layout/images/user-avatar.png" alt="babylon-layout" />

                                            <div className="user-card-name">
                                                <span>{authContext?.name}</span>
                                            </div>

                                            <div className="user-detail">
                                                <ul>
                                                    <li className="clearfix">
                                                        <i className="pi pi-user"></i>
                                                        <span className="project-title">{t('username')}</span>
                                                        <span className="project-detail">{authContext?.username}</span>
                                                    </li>
                                                    <li className="clearfix">
                                                        <i className="pi pi-user"></i>
                                                        <span className="project-title">{t('role')}</span>
                                                        <span className="project-detail">{authContext?.roleDTO.description}</span>
                                                    </li>
                                                    <li className="clearfix">
                                                        <i className="pi pi-envelope"></i>
                                                        <span className="project-title">{t('email')}</span>
                                                        <span className="project-detail">{authContext?.email}</span>
                                                    </li>
                                                    <li className="clearfix">
                                                        <i className="pi pi-phone"></i>
                                                        <span className="project-title">{t('phone')}</span>
                                                        <span className="project-detail">{authContext?.phone}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-12 lg:col-4 md:col-4">
                </div>
            </div>
            {displayBanner && <Dialog  visible={visibleBanner} position={isMobile() ? "bottom" : 'bottom-left'} modal={false} style={{ width: isMobile() ? '100%' : '30%' }} onHide={() => setVisibleBanner(false)}
                draggable={false} resizable={false}>
                {/* <img src="assets/layout/images/savanova_banner.jpg" alt="hyper" width={"100%"} /> */}
                <div className='flex-column justify-content-center'>
                <span className='w-full text-center'>Mobilna aplikacija je sada dostupna! <br/> Za preuzimanje klinkni na odgovarajući link ispod.</span>

                    <div className='text-center my-2'>
                        <a href='https://play.google.com/store/apps/details?id=com.radovanovica.cateringmobile'><img src={"assets/layout/images/google-play.png"} alt={"google play"} height={"30px"} /></a><br />
                        <a href='https://apps.apple.com/us/app/gfood-serbia/id6474066832'><img src={"assets/layout/images/app-store.png"} alt={"google play"} height={"30px"} /></a>
                    </div>
                </div>
            </Dialog>}
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(DashboardCustomerUser, comparisonFn);
