import axios from "axios";
import { BLCUrl, defaultHeader, NodeSericeURl } from "./serviceUtil";
const resourceName = "/payment"

export default class PaymentService {
    handlePayment(requestBody) {
        return axios.post(`${NodeSericeURl}/paymeny/handle/rf`, requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    redirectToPage(url, headers) {
        return axios.get(url, headers).then(res => res.data);
    }
}