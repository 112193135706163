import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';
import { Ripple } from 'primereact/ripple';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../context';
import CustomerService from '../../service/CustomerService';
import MenuService from '../../service/MenuService';
import { searchMenuParams, searchCustomerParams } from '../../service/serviceObjects';
import ShareMenuFormModal from './ShareMenuFormModal';
import moment from 'moment';
const MenuOverview = () => {
    const [deleteMenuDialog, setDeleteMenuDialog] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const [menuToDelete, setMenuToDelete] = useState(null)
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [menuToShare, setMenuToShare] = useState(null);
    const [shareMenuDialog, setShareMenuDialog] = useState(false)
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const [myCustomers, setMyCustomers] = useState([])
    const [expandedRows, setExpandedRows] = useState(null);
    const { t } = useTranslation();

    const toast = useRef(null);
    const authContext = useContext(AuthContext);

    const menuService = new MenuService();
    const customerService = new CustomerService();
    useEffect(() => {
        getAllMenus()
    }, []);
    const getAllMenus = (searchData) => {
        setLoadingTable(true)
        let searchObject = searchMenuParams
        if (searchData) {
            searchObject = { ...searchMenuParams, ...searchData }
        }
        searchObject.cateringId = authContext.cateringId

        menuService.search(searchObject).then(data => {
            setFirst(data.pageNumber * data.pageSize);
            setRows(data.pageSize);
            setTotalRecords(data.itemCount)
            setMenuData(data.items)
            setLoadingTable(false)
        })

    }
    const openNew = () => {

    }
    const shareMenu = (item) => {
        let searchObject = searchCustomerParams
        searchObject.pageSize = 1000
        searchObject.cateringId = authContext.cateringId
        setLoadingTable(true)
        customerService.search(searchObject).then(data => {
            setMyCustomers(data.items)
            setLoadingTable(false)
            setShareMenuDialog(true)
            setMenuToShare(item)
        })
    
    }
    const saveSharedMenu = (item) => {
        let requestObject = {
            customerId:item.customer,
            comment: item.comment,
            menuDate: moment(item.menuDate).format("YYYY-MM-DD").toString(),
            dateActiveFrom: item.activeMenuValidityDate[0],
            dateActiveTo: item.activeMenuValidityDate[1],
            menuDTO: menuToShare
        }
        menuService.share(requestObject).then((value) => {
            setShareMenuDialog(false)
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('menuShared'), life: 3000 })
            setMenuToShare()
        }).catch(reason => {
            setShareMenuDialog(false)
            setMenuToShare()
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringMenuShare'), life: 3000 })
        })
    }
    const hideShareMenuModal = () => {
        setShareMenuDialog(false)
        setMenuToShare()
    }

    const hideDeleteMenuDialog = () => {
        setMenuToDelete();
        setDeleteMenuDialog(false);
    }


    const editMenu = (item) => {
        setShowProgressBar(true)
    }

    const confirmDeleteMenu = (item) => {
        setMenuToDelete(item);
        setDeleteMenuDialog(true);
    }

    const deleteMenu = () => {
        menuService.delete(menuToDelete.id).then(data => {
            setMenuToDelete(null);
            setDeleteMenuDialog(false);
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('menuItemDeleted'), life: 3000 });
            getAllMenus()
        })
    }

    const onPage = (event) => {
        let searchParams = searchMenuParams
        searchParams.cateringId = authContext.cateringId
        searchParams.pageNumber = event.page
        searchParams.pageSize = event.rows
        getAllMenus(searchParams)
    }


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t('new')} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t('manageMenu')}</h5>
        </div>
    );

    const deleteMenuDialogFooter = (
        <>
            <Button label={t('no')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteMenuDialog} />
            <Button label={t('yes')} icon="pi pi-check" className="p-button-text" onClick={deleteMenu} />
        </>
    );


    const paginationTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t('previous')}</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t('next')}</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: t('all'), value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        }
    };
    const imageTemplate = (data) => {
        return (
            <img src={"data:image/png;base64," + data.image} alt={data.name} className="product-image" />
        );
    }
    const allowExpansion = (rowData) => {
        return rowData?.menuItemDTOList?.length > 0;
    };
    const rowExpansionTemplate = (data) => {
        return (
            <div className="orders-subtable">
                <h5>Menu items for {data.name}</h5>
                <DataTable value={data.menuItemDTOList} responsiveLayout="scroll">
                    <Column field="image" header={t('image')} body={imageTemplate} sortable></Column>
                    <Column field="name" header={t('name')} sortable></Column>
                    <Column field="itemCategoryName" header={t('itemCategory')} sortable></Column>
                    <Column field="description" header={t('description')} sortable></Column>
                    {/*<Column field="status" header="Status" body={statusOrderBodyTemplate} sortable></Column>
                    <Column headerStyle={{ width: '4rem'}} body={searchBodyTemplate}></Column> */}
                </DataTable>
            </div>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions mt-2 text-center">
                <Button icon="pi pi-share-alt" className="p-button-rounded p-button-info mr-2" onClick={() => shareMenu(rowData)} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editMenu(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteMenu(rowData)} />
            </div>
        );
    }
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    <DataTable value={menuData} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                        responsiveLayout="scroll"
                        rowExpansionTemplate={rowExpansionTemplate} dataKey="id" header={header}
                        loading={loadingTable} paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate={t('defaultPaginationMessage')} onPage={onPage}>
                        <Column expander={allowExpansion} style={{ width: '3em' }} />
                        <Column field="name" header={t('name')} sortable />
                        <Column body={actionBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>

                    </DataTable>
                    {/* <DataView ref={dt} value={menuData} layout={layout} header={header}
                        itemTemplate={itemTemplate} dataKey="id" paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} menu items" onPage={onPage}
                        globalFilter={globalFilter} emptyMessage="No items found" responsiveLayout="scroll" loading={loadingTable} /> */}
                    <Dialog visible={deleteMenuDialog} style={{ width: '450px' }} header={t('confirm')} modal footer={deleteMenuDialogFooter} onHide={hideDeleteMenuDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {menuToDelete && <span>Are you sure you want to delete <b>{menuToDelete.name}</b>?</span>}
                        </div>
                    </Dialog>
                    {menuToShare && <ShareMenuFormModal myCustomers={myCustomers} shareMenuDialog={shareMenuDialog} hideDialog={hideShareMenuModal} shareMenu={saveSharedMenu} menuToShare={menuToShare} />}
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MenuOverview, comparisonFn);
