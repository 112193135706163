import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getCookie } from './utilities/general';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      srb: {
        translation: {
          "notRobot":"Nisam robot",
          "registerWithCodeMessage1":"Unesite registracioni kod koji ste dobili od Vaše kompanije",
          "registerWithCodeMessage2":"Unesite Vaše podatke",
          "registerWithCodeMessage3":"Na Vaš email dobićete verifikacioni kod",
          "registerWithCodeMessage4":"Unesite kod da biste verifikovali nalog",
          "forgotPasswordMessage1":"Molimo upišite email kojim ste se registrovali",
          "forgotPasswordMessage2":"Privremena lozinku dobićete na vaš email",
          "forgotPasswordMessage3":"Nakon prijave privremenom lozinkom unesite novu lozinku",
          "backToLogin":"Nazad na prijavu",
          "reset":"Resetuj",
          "forgotPassword":"Zaboravljena lozinka",
          "codeIsValid":"Kod je validan: ",
          "resendCode":"Pošalji kod ponovo",
          "types":"Tipovi",
          "ADD_CREDIT":"Dodat kredit",
          "PAID_FROM_CREDIT":"Plaćeno iz kredita",
          "CANCELED_WITH_REFUND":"Otkazano sa povratom",
          "PAYMENT_IN_PROGRESS":"Nepotpuno plaćanje ili plaćanje u toku",
          "PAID_FROM_BANK":"Potvrdjeno plaćanje od banke",
          "CANCELED_FROM_BANK":"Poništeno plaćanje od banke",
          "manageTransactions":"Upravljanje transakcijama",
          "dateAndTime":"Datum i vreme",
          "userId":"ID korisnika",
          "cateringName":"Ketering",
          "registerKeyMessage":"Kod za registraciju korisnika",
          "copied":"Kopirano!",
          "success":"Uspešno",
          "transactionNumber":"Broj transakcije",
          "orderId":"Broj narudžbine",
          "register": "Registracija",
          "registerKey": "Kompanijski kod",
          "check": "Proveri",
          "confirmationCode": "Konfirmacioni kod",
          "registerWithCode": "Registruj se pomoću koda",
          "registerKeyRequired": "Obavezno polje",
          "confirmationCodeRequired": "Obavezno polje",
          "keyIsNotValid": "Kod nije validan",
          "confirmationCodeIsNotValid": "Kod nije validan",
          "accountCreated": "Nalog uspesno kreiran",
          "noItems":"Nema izabranih stavki",
          "substract":"Umanji",
          "creditAdded":"Kredit je dodat",
          "errorDuringCreditAdd":"Greška prilikom dodavanja kredita",
          "errorDuringPublicUserRegistration":"Greška prilikom registracije korisnika",
          "creditState":"Stanje kredita",
          "creditManagement":"Upravljanje kreditima",
          "addCreditDetails":"Kredit može biti dodat ili umanjen jednom korisniku ili za više korisnika",
          "selectUsers":"Izaberi korisnike",
          "addCredit":"Dodaj kredit",
          "showQR":"Digitalna kartica",
          "creditDetails":"Sredstva će biti preusmerena na gFood kredit koji možete koristiti prilikom budućih narudžbina",
          "cancelWithRefund":"Otkaži (kredit)",
          "credit":"Kredit",
          "chooseCredit":"Izaberi iznos kredita",
          "creditMessage":"Trenutno stanje kredita:",
          "edited":"Izmenjeno",
          "addOtherItems":"Dodaj jela iz drugih kategorija",
          "otherDays":"Ostali datumi",
          "currentWeek":"Trenutna nedelja",
          "groupOrder":"Grupne naružbine",
          "order":"Naružbina",
          "orderDeleted":"Naružbina je obrisana",
          "errorDuringOrderDelete":"Greška prilikom brisanja naružbine",
          "areYouSureYouWantToDeleteOrder":"Da li ste sigurni da želite da obrišete naružbinu za dan ",
          "logout":"Odjavi se",
          "employees":"Zaposleni",
          "accounts":"Naloga",
          "me":"Ja",
          "PAID":"Plaćeno",
          "PAYMENT_IN_PROGRESS":"Nepotpuno plaćanje",
          "orderTimeExpired":"Vreme za porudžbine je isteklo",
          "chooseMenuItem":"Odaberi obrok",
          "totalAmount":"Ukupno",
          "orderNumber":"Broj poružbine",
          "orderDate":"Datum poružbine",
          "remove":"Obriši",
          "choosePaymentMethod":"Odaberi način plaćanja",
          "alreadyAdded":"je već dodat(o/a)!",
          "maxNumberOfOrderPerCategory":"Maksimalan broj poručenih artikala po kategoriji je: ",
          "maxNumberOfOrdersForCategory":"Maksimalan broj poručenih artikala po kategoriji",
          "ordersOpenMessage":"Porudžbine su otvorene: ",
          "goToPayment":"Idi na plaćanje",
          "yourOrderIsAlmostDone":"Tvoja poružbina je skoro gotova!",
          "orderSummary":"Pregled",
          "total":"Ukupno",
          "chooseDay":"Izaberi dan",
          "explore":"Pronadji",
          "chooseOtherDay":"Izaberi drugi dan",
          "checkout":"Pregled porudžbina",
          "isAdded":"dodato u korpu!",
          "orderPaymentTypeRequired":"Plaćanje je obavezno polje",
          "NO_PAYMENT":"Bez plaćanja",
          "WITH_PAYMENT":"Obavezno plaćanje",
          "OPTIONAL":"Opciono plaćanje",
          "orderPaymentType":"Plaćanje",
          "Sunday":"Nedelja",
          "Monday":"Ponedeljak",
          "Tuesday":"Utorak",
          "Wednesday":"Sreda",
          "Thursday":"Četvrtak",
          "Friday":"Petak",
          "Saturday":"Subota",
          "cartType":"Dozvoli korpu",
          "allowPricing":"Prikaži cenu",
          "newMenuItemAttribute":"Novi atribut",
          "manageMenuItemAttributes":"Upravljanje atributima jelovnika",
          "menuItemAttribute":"Atributi jelovnika",
          "recipeAdded":"Recept dodat",
          "norm":"Po normativu",
          "typeOrder":"Tip pretrage",
          "singleOrder":"Po korisniku",
          "amountOrder":"Po obroku",
          "dailyBasedOrder":"Dozvoli dnevno naručivanje",
          "personalNumber":"Lični broj",
          "errorWithExternalLogin":"Greška prilikom eksterne prijave, molimo kontaktirajte administratora",
          "backToStandardLogin":"Nazad na standarnu prijavu",
          "domainIsNotAvailable":"Domen nije dostupan. Molimo proverite da li je uneseni format registrovan u GFood aplikaciji",
          "domainValid":"Domen je validan",
          "domainFormat":"Domen treba biti u formatu",
          "domain":"Domen",
          "loginMicrosoftTitle":"Prijava sa Microsoft nalogom",
          "loginWIthMicrosoft":"Prijavi se sa Microsoft nalogom",
          "domainsRequired":"Obavezno polje",
          "clientSecretRequired":"Obavezno polje",
          "tenantIdRequired":"Obavezno polje",
          "clientIdRequired":"Obavezno polje",
          "externalAuth":"Eksterni sistem prijave",
          "microsoftAccountSettings":"Podešavanja",
          "domains":"Domeni",
          "clientId":"Client ID(Application ID)",
          "clientSecret":"Client secret",
          "tenantId":"Tenant ID",
          "passwordNotMatch":"Lozinka i lozinka ponovo moraju biti iste",
          "usernameExists":"Korisnik sta ovim korisničkim imenom je već dodat",
          "notifyUser":"Obavesti korisnika",
          "customerName":"Ime klijenta",
          "invalidPhoneFormat":"Format broja telefona nije validan",
          "customerUpdated":"Klijent je ažuriran",
          "menuCopied":"Jelovnik je kopiran",
          "paste":"Nalepi",
          "minDateRequired":"Datum je obavezan",
          "maxDateRequired":"Datum je obavezan",
          "newLocation":"Nova lokacija",
          "manageCustomersLocations":"Upravljanje lokacijama",
          "location":"Lokacija",
          "locations":"Lokacije",
          "locationRequired":"Lokacija je obavezna",
          "timeRequired":"Datum je obavezan",
          "invalidTimeFormat":"Format nije validan",
          "defaultMenu":"Podrazumevani jelovnik",
          "template":"Šablon",
          "templateMenu":"Šabloni",
          "multiDay":"Sedmični",
          "singleDay":"Dnevni",
          "paymentMethod":"Način plaćanja",
          "amountPrice":"Ukupno za plaćanje",
          "placeOrder":"Sačuvaj porudžbinu",
          "orderDetails":"Detalji porudžbine",
          "price":"Cena",
          "addToCart":"Dodaj u korpu",
          "isPublic":"Javno dostupno",
          "publicMenu":"Javno dostupno",
          "cardOrUserIsNotValid":"Korisnik ili kartica nisu pronadjeni",
          "socketServerTimeout":"Server trenutno nije dostupan. Molimo sačekajte...",
          "rememberMe":"Zapamti me",
          "apply":"Primeni",
          "errorDuringDeleteUser":"Greška prilikom brisanja korisnika",
          "errorDuringSearchOrder":"Greška prilikom pretrage narudžbina",
          "loginLabel":"Prijava",
          "login":"Prijavi se",
          "isVegetarian": "Vegeterijansko",
          "isLenten": "Posno",
          "isVegan": "Vegansko",
          "single": "Pojedinačno",
          "type": "Tip",
          "count": "Ukupno",
          "dataExported": "Podaci su otpremljeni",
          "lang": "Jezik",
          "newStock": "Novi ulaz",
          "stockItemId": "Namirnica",
          "stockImport": "Ulaz namirnica",
          "manageStocks": "Upravljanje ulazom namirnica",
          "amount": "Količina",
          "importDate": "Datum ulaza",
          "stockItemDeleted": "Namirnica je obrisana",
          "errorSavingStockItem": "Greška prilikom čuvanja namirnice",
          "stockItemUpdated": "Namirnica je izmenjena",
          "stockItemAdded": "Namirnica je dodata",
          "unitRequired": "Merna jedinica je obavezna",
          "GRAM": "Gram",
          "KG": "Kilogram",
          "L": "Litar",
          "PSC": "Komad",
          "unit": "Merna jedinica",
          "newStockItem": "Nova namirnica",
          "stockItem": "Namirnice",
          "stock": "Namirnice",
          "manageStockItems": "Upravljanje namirnicama",
          "passwordRestarted": "Lozinka je resetovana",
          "options": "Opcije",
          "resetPassword": "Resetuj lozinku",
          "VERIFIED": "Verifikovan",
          "MISSING_ITEM": "Nepotpuna narududžbina",
          "verified": "Verifikovan",
          "user": "Korisnik",
          "orderAlreadyVerified": "Narududžbina je već оčitana",
          "orderSaved": "Narudžbina sačuvana",
          "orderSuccessfullySaved": "Narudžbina je uspešno sačuvana",
          "userWithCardNumberNotFound": "Korisnik sa očitanim brojem kartice ne postoji",
          "confirmPassword": "NOVA LOZINKA",
          "invalidUsername": "Format korisničkog imena nije validan",
          "invalidEmailFormat": "Format email-a nije validan",
          "menuSaved": "Jelovnik sačuvan",
          "errorDuringSaveMenu": "Greška prilikom čuvanja jelovnika",
          "errorCustomerSettingsUpdated": "Greška prilikom ažuriranja podešavanja",
          "customerSettingsUpdated": "Podešavanja uspešno ažurirana",
          "addressRequired": "Adresa je obavezno polje",
          "customerForm": "Klijent",
          "filter": "Filtriraj",
          "CONFIRMED": "Potvrdjen",
          "CREATED": "Kreiran",
          "noOrder": "Nema narudžbine",
          "todayOrder": "Današnja narudžbina",
          "caterings": "Ketering kompanije",
          "companies": "Kompanije",
          "role": "Uloga",
          "team": "Tim",
          "view": "Pogledaj",
          "start": "Počni",
          "userNotFound": "Korisnik nije pronadjen",
          "pleaseCanYourCard": "Molimo skenirajte karticu",
          "noMenu": "Nema jelovnika",
          "acceptImageFormat": "Format slike: .jpeg, .png. Max veličina:300kb",
          "acceptImageFormat100": "Format slike: .jpeg, .png. Max veličina:100kb",
          "generate": "Generiši",
          "copy": "Kopiraj",
          "noValidLicense": "Nema validne licence",
          "licenseInfo": "Informacije o licenci",
          "VALID": "VALIDNA",
          "EXPIRED": "ISTEKLA",
          "SUSPENDED": "SUSPENDOVANA",
          "manageLicenses": "Upravljanje licencama",
          "licenseKey": "Ključ licence",
          "status": "Status",
          "expiryDate": "Datum isteka",
          "newLicense": "Nova licenca",
          "dateActiveFrom": "Validno od",
          "dateActiveTo": "Validno do",
          "license": "Licenca",
          "LICENSE_NOT_VALID": "Licenca nije validna!",
          "finish": "Završi",
          "selectedItems": "Odabrane stavke",
          "minDate": "Od datuma",
          "maxDate": "Do datuma",
          "print": "Štampaj",
          "orderList": "Lista narudžbina",
          "company": "Kompanija",
          "customerCapital": "KLIJENT",
          "dateCapital": "DATUM",
          "commentCapital": "KOMENTAR",
          "shift": "Smena",
          "Mon": "Pon",
          "Tue": "Uto",
          "Wed": "Sre",
          "Thu": "Čet",
          "Fri": "Pet",
          "Sat": "Sub",
          "Sun": "Ned",
          "settings": "Podešavanja",
          "numberOfShifts": "Broj smena",
          "numberOfDaysBeforeMenuIsActive": "Broj dana od kada je jelovnik aktivan",
          "numberOfDaysBeforeMenuIsNotActive": "Broj dana od kada je jelovnik neaktivan",
          "numberOfDaysBeforeMenuIsActiveTime": "Vreme od",
          "numberOfDaysBeforeMenuIsNotActiveTime": "Vreme do",
          "publicIntegrationKey": "API ključ",
          "ok": "Gotovo",
          "selectItems": "Odaberi stavke jelovnika",
          "selectCustomer": "Odaberi klijenta",
          "previousMenus": "Prethodni jelovnici",
          "previousWeek": "Prethodna nedelja",
          "nextWeek": "Sledeca nedelja",
          "menuClient": "Upravljanje jelovnicima",
          "scanCardMessage": "Skenirajte karticu",
          "orderIsNotValid": "Narudzbina nije validna",
          "orderIsValid": "Narudzbina je validna",
          "date": "Datum",
          "userFullName": "Ime korisnika",
          "userPersonalNumber": "Lični broj",
          "verificationClient": "Verifikacija obroka",
          "verificationClientCard": "Verifikacija obroka QR kod",
          "all": "Svi",
          "username": "Korisničko ime",
          "password": "Lozinka",
          //general
          "new": "Novo",
          "delete": "Obriši",
          "import": "Uvezi",
          "export": "Otpremi",
          "name": "Ime",
          "description": "Opis",
          "search": "Pretraga",
          "previous": "Nazad",
          "next": "Napred",
          "active": "Aktivno",
          "confirm": "Potrvrdi",
          "yes": "Da",
          "no": "Ne",
          "cancel": "Odustani",
          "save": "Sačuvaj",
          "defaultPaginationMessage": "Prikaži {first} do {last} od {totalRecords} ukupno rezultata",
          "image": "Slika",
          "selectable": "Moguće odabrati",
          "removeAll": "Obriši sve",
          "add": "Dodaj",
          "edit": "Izmeni",
          "noItemsFound": "Nema pronađenih rezultata",
          "noItemsSelected": "Nema odabranih stavki",
          //general
          "manageMenuItems": "Upravljanje stavkama jelovnika",
          "manageMenu": "Upravljanje jelovnikom",
          "newMenuItem": "Nova stavka jelovnika",
          "homePage": "Početna strana",
          "dashboard": "Početna strana",
          "menu": "Jelovnik",
          "itemCategory": "Kategorija artikla",
          "menuItems": "Jelovnik",
          "customers": "Partneri",
          "connections": "Konekcije",
          "orders": "Lista narudžbina",
          "myCatering": "Moj ketering",
          "catering": "Ketering",
          "stats": "Statistika",
          "repors": "Izveštaj",
          "manageItemCategories": "Obrada kategorija stavki",
          "menuDetails": "Detalji jelovnika",
          "areYouSureYouWantToDelete": "Da li želite da obrišete",
          "newItemCategory": "Nova kategorija stavke",
          "nameRequired": "Ime je obavezno",
          "descriptionRequired": "Opis je obavezan",
          "itemCategoryRequired": "Kategorija stavke je obavezna",
          "error": "Greska",
          "successful": "Uspešno",
          "itemCategoryAdded": "Nova kategorija stavke dodata",
          "itemCategoryUpdated": "Kategorija stavke izmenjena",
          "itemCategoryDeleted": "Kategorija stavke obrisana",
          "errorSavingItemCategory": "Greska prilikom čuvanja kategorije stavke",
          "menuItemAdded": "Nova stavka jelovnika dodata",
          "menuItemUpdated": "Stavka jelovnika izmenjena",
          "menuItemDeleted": "Stavka jelovnika obrisana",
          "errorSavingmenuItem": "Greška prilikom čuvanja stavke jelovnika",
          "menuAdded": "Novi jelovnik je dodat",
          "shareMenu": "Podeli jelovnik",
          "comment": "Komentar",
          "commentRequired": "Komentar je obavezan",
          "menuDate": "Datum jelovnika",
          "menuValidityDate": "Datum aktivnosti jelovnika",
          "dateRequired": "Datum je obavezan",
          "cateringAdded": "Novi ketering je dodat",
          "errorSavingCatering": "Greska prilikom čuvanja keteringa",
          "cateringUpdated": "Ketering je izmenjen",
          "cateringDeleted": "Ketering je obrisan",
          "manageCaterings": "Upravljanje kategorijama",
          "cateringForm": "Ketering",
          "email": "Email",
          "phone": "Telefon",
          "addressCountry": "Država",
          "addressCity": "Grad",
          "emailRequired": "Email je obavezan",
          "phoneRequired": "Telefon je obavezan",
          "addressCountryRequired": "Država je obavezna",
          "addressCityRequired": "Grad je obavezan",
          "basicInformation": "Osnovne informacije",
          "users": "Korisnici",
          "companyName": "Ime kompanije",
          "address": "Adresa",
          "userUpdated": "Korisnik izmenjen",
          "userAdded": "Novi korisnik je dodat",
          "userDeleted": "Korisnik je izbrisan",
          "errorSavingUser": "Greska prilikom čuvanja korisnika",
          "manageUsers": "Upravljanje korisnicima",
          "passwordAgain": "Lozinka ponovo",
          "usernameRequired": "Korisničko ime je obavezno",
          "passwordRequired": "Lozinka je obavezna",
          "passwordAgainRequired": "Lozinka ponovo je obavezna",
          "cateringRequired": "Ketering je obavezan",
          "cateringId": "Ketering",
          "roleIdRequired": "Uloga je obavezna",
          "roleId": "Uloga",
          "sadmin": "Super admin",
          "catering.admin": "Admin (Ketering)",
          "catering.operator": "Operater (Ketering)",
          "customer.admin": "Admin (Kompanija)",
          "customer.operator": "Operater (Kompanija)",
          "customer.verification": "Verifikacioni klijent (Kompanija)",
          "customer.user": "Korisnik (Kompanija)",
          "customer.user.public": "Javni Korisnik (Kompanija)",
          "userForm": "Korisnik",
          "contactData": "Kontakt podaci",
          "manageCustomers": "Upravljanje partnerima",
          "cardNumber": "Broj kartice",
          "customer": "Klijent",
          "myCompany": "Moja kompanija",
          "orderClient": "Moje narudžbine"

        }
      },
      en: {
        translation: {
          "notRobot":"I'm not a robot",
          "registerWithCodeMessage1":"Enter register code provided from your company",
          "registerWithCodeMessage2":"Enter your data",
          "registerWithCodeMessage3":"Verification code will be sent to your email",
          "registerWithCodeMessage4":"Verify your account with code",
          "forgotPasswordMessage1":"Enter your email",
          "forgotPasswordMessage2":"Temporary password will be sent to your email",
          "forgotPasswordMessage3":"Login with temporary password and enter new password",
          "backToLogin":"Back to login",
          "reset":"Reset",
          "forgotPassword":"Forgot password",
          "codeIsValid":"Code is valid: ",
          "resendCode":"Resend code",
          "types":"Types",
          "ADD_CREDIT":"Credit is added",
          "PAID_FROM_CREDIT":"Paid from credit",
          "CANCELED_WITH_REFUND":"Cancel with refund",
          "PAYMENT_IN_PROGRESS":"Payment in progress",
          "PAID_FROM_BANK":"Paid confirmed from bank",
          "CANCELED_FROM_BANK":"Payment canceled from bank",
          "manageTransactions":"Manage transactions",
          "dateAndTime":"Date and time",
          "userId":"User ID",
          "cateringName":"Catering",
          "success":"Success",
          "registerKeyMessage":"Code for user registration",
          "copied":"Copied!",
          "transactionNumber":"Transaction number",
          "orderId":"Order number",
          "register": "Register",
          "registerWithCode": "Register with code",
          "registerKey": "Register key",
          "confirmationCode": "Confirmation code",
          "check":"Check",
          "registerKeyRequired": "Required field",
          "confirmationCodeRequired": "Required field",
          "keyIsNotValid": "Key is not valid",
          "confirmationCodeIsNotValid": "Code is not valid",
          "accountCreated": "Account successfully created",
          "noItems":"No selected items",
          "substract":"Substract",
          "creditAdded":"Credit added",
          "errorDuringCreditAdd":"Error during credit save",
          "errorDuringPublicUserRegistration":"Error during public user registration",
          "creditState":"Credit state",
          "creditManagement":"Manage credits",
          "addCreditDetails":"Credit could be added or substract for single or multiple users",
          "selectUsers":"Select users",
          "addCredit":"Add credit",
          "showQR":"Digital card",
          "creditDetails":"Money will be transfer to gFood credits and you will be able to use it in futher orders",
          "cancelWithRefund":"Cancel with refund",
          "credit":"Credit",
          "chooseCredit":"Choose credit amount",
          "creditMessage":"Current credit state:",
          "edited":"Edited",
          "addOtherItems":"Add other items",
          "currentWeek":"Current week",
          "otherDays":"Other days",
          "groupOrder":"Group orders",
          "order":"Order",
          "orderDeleted":"Order is deleted",
          "errorDuringOrderDelete":"Error during delete order",
          "areYouSureYouWantToDeleteOrder":"Are you sure you want to delete order for data ",
          "logout":"Logout",
          "employees":"Employees",
          "accounts":"Accounts",
          "me":"Me",
          "PAID":"Paid",
          "PAYMENT_IN_PROGRESS":"Payment in progress",
          "chooseMenuItem":"Choose menu item",
          "orderTimeExpired":"Order time expired",
          "totalAmount":"Total amount",
          "orderNumber":"Order number",
          "orderDate":"Order date",
          "remove":"Remove",
          "choosePaymentMethod":"Choose payment method",
          "alreadyAdded":"is already added!",
          "maxNumberOfOrderPerCategory":"Max number of items per category is: ",
          "maxNumberOfOrdersForCategory":"Max number of items per category",
          "ordersOpenMessage":"Orders are open: ",
          "goToPayment":"Go to payment",
          "yourOrderIsAlmostDone":"Your order is almost done",
          "orderSummary":"Order summary",
          "total":"Total",
          "chooseDay":"Choose day",
          "explore":"Explore",
          "chooseOtherDay":"Choose other day",
          "checkout":"Order preview",
          "isAdded":"added to cart!",
          "orderPaymentTypeRequired":"Order payment is required",
          "NO_PAYMENT":"No payment",
          "WITH_PAYMENT":"Payment required",
          "OPTIONAL":"Optional",
          "orderPaymentType":"Payment",
          "cartType":"Allow cart",
          "allowPricing":"Display price",
          "manageMenuItemAttributes":"Manage attributes",
          "menuItemAttribute":"Menu item attributes",
          "newMenuItemAttribute":"New attribute",
          "recipeAdded":"Recipe added",
          "norm":"By norm",
          "typeOrder":"Search type",
          "singleOrder":"By user",
          "amountOrder":"By menu item",
          "dailyBasedOrder":"Allow daily order",
          "personalNumber":"Personal number",
          "errorWithExternalLogin":"Error during external login, please contact administrator",
          "backToStandardLogin":"Back to standard login",
          "domainIsNotAvailable":"Domain is not available in the system. Please make sure that your company domain is registered in GFood",
          "domainValid":"Domain is valid",
          "domainFormat":"Domain should be in format",
          "domain":"Domain",
          "loginMicrosoftTitle":"Sign in with Microsoft account",
          "loginWIthMicrosoft":"Sign in with Microsoft account",
          "domainsRequired":"Field is required",
          "clientSecretRequired":"Field is required",
          "tenantIdRequired":"Field is required",
          "clientIdRequired":"Field is required",
          "externalAuth":"External authentication",
          "microsoftAccountSettings":"External authentication settings",
          "passwordNotMatch":"Password and password again are not the same",
          "domains":"Domains",
          "clientId":"Client ID(Application ID)",
          "clientSecret":"Client secret",
          "tenantId":"Tenant ID",
          "usernameExists":"User with username already exists",
          "notifyUser":"Notify user",
          "invalidPhoneFormat":"Wrong phone number format",
          "customerUpdated":"Customer is updated",
          "menuCopied":"Menu copied",
          "paste":"Paste",
          "minDateRequired":"Date is required",
          "maxDateRequired":"Date is required",
          "newLocation":"New location",
          "manageCustomersLocations":"Manage locations",
          "locations":"Locations",
          "location":"Location",
          "locationRequired":"Locations is required",
          "timeRequired":"Date is required",
          "invalidTimeFormat":"Format od date is not valid",
          "defaultMenu":"Default menu",
          "template":"Template",
          "templateMenu":"Menu templates",
          "multiDay":"Week view",
          "singleDay":"Day view",
          "paymentMethod":"Payment method",
          "amountPrice":"Amount price",
          "placeOrder":"Place order",
          "orderDetails":"Order details",
          "price":"Price",
          "addToCart":"Add to cart",
          "isPublic":"Public visible",
          "publicMenu":"Public visible",
          "cardOrUserIsNotValid":"User or card is not valid!",
          "socketServerTimeout":"Server is disconnected. Please wait...",
          "rememberMe":"Remember me",
          "apply":"Apply",
          "errorDuringDeleteUser":"Error during delete user",
          "errorDuringSearchOrder":"Error during search orders",
          "loginLabel":"LOGIN",
          "login":"Sign in",
          "isLenten": "Lenten",
          "isVegetarian": "Vegetarian",
          "isVegan": "Vegan",
          "single": "Single",
          "type": "Type",
          "count": "Count",
          "dataExported": "Data exported",
          "lang": "Language",
          "newStock": "New stock import",
          "stockItemId": "Stock item",
          "stockImport": "Stock import",
          "manageStocks": "Manage stock import",
          "amount": "Amount",
          "importDate": "Import date",
          "stockItemDeleted": "Stock item deleted",
          "errorSavingStockItem": "Error saving stock item",
          "stockItemUpdated": "Stock item updated",
          "stockItemAdded": "Stock item added",
          "unitRequired": "Unit is required",
          "GRAM": "Gram",
          "PSC": "Piece",
          "KG": "Kilogram",
          "L": "Litre",
          "unit": "Unit",
          "newStockItem": "New stock item",
          "stockItem": "Stock items",
          "stock": "Stock",
          "manageStockItems": "Manage stock items",
          "passwordRestarted": "Password restarted",
          "options": "Options",
          "resetPassword": "Reset password",
          "verified": "Verified",
          "user": "User",
          "orderAlreadyVerified": "Nardudzbina je već оčitana",
          "orderSuccessfullySaved": "Order successfully saved!",
          "orderSaved": "Order saved",
          "userWithCardNumberNotFound": "User with read card number doesn't exists",
          "confirmPassword": "NEW PASSWORD",
          "invalidUsername": "Invalid username format",
          "invalidEmailFormat": "Invalid email format",
          "menuSaved": "Menu saved",
          "errorDuringSaveMenu": "Error during menu save",
          "errorCustomerSettingsUpdated": "Error during update settings",
          "customerSettingsUpdated": "Settings updated successfully",
          "addressRequired": "Address is required",
          "customerForm": "Customer",
          "filter": "Filter",
          "CONFIRMED": "Confirmed",
          "CREATED": "Created",
          "noOrder": "No order",
          "todayOrder": "Today's order",
          "caterings": "Caterings",
          "companies": "Companies",
          "role": "Role",
          "team": "Team",
          "view": "View",
          "start": "Start",
          "userNotFound": "No user found",
          "pleaseCanYourCard": "Please can your card",
          "noMenu": "No menu",
          "acceptImageFormat": "Format of image: .jpeg, .png. Max size:300kb",
          "acceptImageFormat100": "Format of image: .jpeg, .png. Max size:100kb",
          "generate": "Generate",
          "copy": "Copy",
          "noValidLicense": "No valid license",
          "licenseInfo": "License information",
          "VALID": "VALID",
          "EXPIRED": "EXPIRED",
          "SUSPENDED": "SUSPENDED",
          "manageLicenses": "Manage licenses",
          "licenseKey": "License key",
          "status": "Status",
          "expiryDate": "Expiry date",
          "newLicense": "New license",
          "dateActiveFrom": "Date active from",
          "dateActiveTo": "Date active to",
          "license": "License",
          "LICENSE_NOT_VALID": "License is not valid",
          "finish": "Finish",
          "selectedItems": "Selected items",
          "minDate": "From date",
          "maxDate": "To date",
          "print": "Print",
          "orderList": "Order list",
          "company": "Company",
          "customerCapital": "CUSTOMER",
          "dateCapital": "DATE",
          "commentCapital": "COMMENT",
          "shift": "Shift",
          "Mon": "Mon",
          "Tue": "Tue",
          "Wed": "Wed",
          "Thu": "Thu",
          "Fri": "Fri",
          "Sat": "Sat",
          "Sun": "Sun",
          "settings": "Settings",
          "numberOfShifts": "Number of shifts",
          "numberOfDaysBeforeMenuIsActive": "Number of days before menu is active",
          "numberOfDaysBeforeMenuIsNotActive": "Number of days before menu is not active",
          "numberOfDaysBeforeMenuIsActiveTime": "Time from",
          "numberOfDaysBeforeMenuIsNotActiveTime": "Time to",
          "publicIntegrationKey": "API integration key",
          "ok": "Ok",
          "selectItems": "Select items",
          "selectCustomer": "Select customer",
          "previousMenus": "Previous menus",
          "previousWeek": "Previous week",
          "nextWeek": "Next week",
          "menuClient": "Menu client",
          "orderIsValid": "Order is valid",
          "orderIsNotValid": "Order is not valid",
          "scanCardMessage": "Please scan card",
          "date": "Date",
          "customerName": "Customer name",
          "userFullName": "User name",
          "userPersonalNumber": "Personal number",
          "verificationClient": "Verification client",
          "verificationClientCard": "Verification client(Scan)",
          "all": "All",
          "username": "Username",
          "password": "Password",
          //general
          "new": "New",
          "delete": "Delete",
          "import": "Import",
          "export": "Export",
          "name": "Name",
          "description": "Description",
          "search": "Search",
          "previous": "Previous",
          "next": "Next",
          "confirm": "Confirm",
          "yes": "Yes",
          "no": "No",
          "cancel": "Cancel",
          "save": "Save",
          "defaultPaginationMessage": "Showing {first} to {last} of {totalRecords} records",
          "image": "Image",
          "selectable": "Selectable",
          "removeAll": "Remove all",
          "add": "Add",
          "edit": "Edit",
          "noItemsFound": "No items found",
          "noItemsSelected": "No items selected",
          //general
          "manageMenuItems": "Manage menu item",
          "manageMenu": "Manage menu",
          "newMenuItem": "New menu item",
          "homePage": "Home page",
          "dashboard": "Dashboard",
          "menu": "Menu",
          "itemCategory": "Item category",
          "menuItems": "Menu items",
          "active": "Active",
          "customers": "Customers",
          "connections": "Connections",
          "orders": "Orders",
          "myCatering": "My catering",
          "catering": "Catering",
          "stats": "Stats",
          "repors": "Reports",
          "manageItemCategories": "Manage item categories",
          "menuDetails": "Menu details",
          "areYouSureYouWantToDelete": "Are you sure you want to delete",
          "newItemCategory": "New item category",
          "nameRequired": "Name is required",
          "descriptionRequired": "Description is required",
          "itemCategoryRequired": "Item category is required",
          "error": "Error",
          "successful": "Successful",
          "itemCategoryAdded": "Item category added",
          "itemCategoryUpdated": "Item category updated",
          "itemCategoryDeleted": "Item category deleted",
          "errorSavingItemCategory": "Error with saving item category",
          "menuItemAdded": "Menu item added",
          "menuItemUpdated": "Menu item updated",
          "menuItemDeleted": "Menu item deleted",
          "errorSavingmenuItem": "Error with saving Menu item",
          "menuAdded": "Menu added",
          "shareMenu": "Share menu ",
          "comment": "Comment",
          "commentRequired": "Comment is required",
          "menuDate": "Menu date",
          "menuValidityDate": "Menu validity date",
          "dateRequired": "Date is required",
          "cateringAdded": "Catering added",
          "errorSavingCatering": "Error during catering save",
          "cateringUpdated": "Catering updated",
          "cateringDeleted": "Catering deleted",
          "manageCaterings": "Manage caterings",
          "cateringForm": "Catering form",
          "email": "Email",
          "phone": "Phone",
          "addressCity": "Address city",
          "addressCountry": "Address country",
          "emailRequired": "Email is required",
          "phoneRequired": "Phone is required",
          "addressCountryRequired": "Address country is required",
          "addressCityRequired": "Address city is required",
          "users": "Users",
          "companyName": "Company name",
          "address": "Address",
          "userUpdated": "User updated",
          "userAdded": "User added",
          "userDeleted": "User deleted",
          "errorSavingUser": "Error during user save",
          "manageUsers": "Manage users",
          "passwordAgain": "Password again",
          "usernameRequired": "Username is required",
          "passwordRequired": "Password is required",
          "passwordAgainRequired": "Password again is required",
          "cateringRequired": "Catering required",
          "cateringId": "Catering",
          "roleIdRequired": "Role is required",
          "roleId": "Role",
          "sadmin": "Super admin",
          "catering.admin": "Admin (Catering)",
          "catering.operator": "Operator (Catering)",
          "customer.admin": "Admin (Customer)",
          "customer.operator": "Operator (Customer)",
          "customer.verification": "Verification client (Customer)",
          "customer.user": "User (Customer)",
          "customer.user.public": "Public User (Customer)",
          "userForm": "User form",
          "basicInformation": "Basic information",
          "contactData": "Contact data",
          "manageCustomers": "Manage customers",
          "cardNumber": "Card number",
          "customer": "Customer",
          "myCompany": "My company",
          "orderClient": "Order client"
        }
      }

    },
    lng: getCookie('lang') || "srb",
    fallbackLng: getCookie('lang') || "srb",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
