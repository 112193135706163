import { useTranslation } from "react-i18next"


export default function useOrderDataTaskUtil() {
    const { t } = useTranslation();

    const returnNormCountOfItems = (orderDataArray) => {
        let countData = []
        let allMenuItems = []
        let allMenuItemsStockItems = []
        if (orderDataArray?.length > 0) {
            orderDataArray.forEach(element => {
                allMenuItems = [...allMenuItems, ...element.menuItems]
            });
            if (allMenuItems.length > 0) {
                allMenuItems.forEach(element => {
                    if (element?.menuItemStockItemDTO) {
                        allMenuItemsStockItems = [...allMenuItemsStockItems, ...element.menuItemStockItemDTO]
                    }

                });
                if (allMenuItemsStockItems.length > 0) {
                    allMenuItemsStockItems.forEach(element => {
                        let elementData = {}
                        let indexOfElement = countData?.findIndex(x => element?.stockItemDTO?.id == x.id)
                        if (indexOfElement != -1) {
                            elementData = { ...countData[indexOfElement], amount: countData[indexOfElement].amount + element.amount }
                            countData[indexOfElement] = elementData
                        } else {
                            elementData = {
                                id: element?.stockItemDTO?.id,
                                name: element?.stockItemDTO?.name,
                                unit: t(element?.stockItemDTO?.unit),
                                amount: element.amount
                            }
                            countData.push(elementData)

                        }
                    });
                }
            }

        }
        return [...countData]

    }
    const getCommentByDate = (newOrders, date) => {
        const orderForDate = newOrders.find(order => order.date === date);
        return orderForDate ? orderForDate.comment || null : null;
      };
      
      // Utility to get the shift for a specific date from newOrders
     const getShiftByDate = (newOrders, date) => {
        const orderForDate = newOrders.find(order => order.date === date);
        return orderForDate ? orderForDate.shift || null : null;
      };
      const getFirstOrderDate = (orders) => {
        if (!orders || orders.length === 0) return null;
    
        // Extract dates and sort them in ascending order
        const sortedOrders = [...orders].sort((a, b) => new Date(a.date) - new Date(b.date));
    
        // Return the first (earliest) date
        return sortedOrders[0]?.date || null;
    };
    
    // Utility function to get the last (latest) date from an array of orders
    const getLastOrderDate = (orders) => {
        if (!orders || orders.length === 0) return null;
    
        // Extract dates and sort them in descending order
        const sortedOrders = [...orders].sort((a, b) => new Date(b.date) - new Date(a.date));
    
        // Return the last (latest) date
        return sortedOrders[0]?.date || null;
    }
    const getItemCategoryNamesFromOrder = (order) => {
        // Check if order is valid
        if (!order || !order.menuItemSequences || order.menuItemSequences.length === 0) {
            return [];
        }
    
        // Use a Set to store unique itemCategoryNames
        const categoryNames = new Set();
    
        // Iterate through menuItemSequences to get category names
        order.menuItemSequences.forEach(item => {
            if (item.menuItemDTO?.itemCategoryName) {
                categoryNames.add(item.menuItemDTO.itemCategoryName);
            }
        });
    
        // Convert Set to Array and return
        return Array.from(categoryNames);
    };

    return {
        returnNormCountOfItems: returnNormCountOfItems,
        getCommentByDate: getCommentByDate,
        getShiftByDate: getShiftByDate,
        getFirstOrderDate: getFirstOrderDate,
        getLastOrderDate: getLastOrderDate,
        getItemCategoryNamesFromOrder: getItemCategoryNamesFromOrder
    }
};



