import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import { FileUpload } from 'primereact/fileupload';
import { Message } from 'primereact/message';
import { Image } from 'primereact/image';

const ItemCategoryModalForm = (props) => {
    const { itemCategoryDialog, hideDialog, saveItemCategory, updateItemCategory, itemCategoryToEdit } = props
    const [uploadedImage, setUploadedImage] = useState(null);

    let defaultValues = {
        name: '',
        description: ''
    }
    const { t } = useTranslation();

    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });

    useEffect(() => {
        if (itemCategoryToEdit?.name) {
            setValue('name', itemCategoryToEdit?.name)
        }
        if (itemCategoryToEdit?.description) {
            setValue('description', itemCategoryToEdit?.description)
        }
        if (itemCategoryToEdit?.id) {
            setValue('id', itemCategoryToEdit?.id)
        }
        if (itemCategoryToEdit?.image) {
            setUploadedImage(itemCategoryToEdit.image)
        }
    }, [itemCategoryToEdit])
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const submitFunction = (data) => {
        if(uploadedImage){
            data = {...data, image: uploadedImage}
        }
        if (itemCategoryToEdit) {
            updateItemCategory(data)
        } else {
            saveItemCategory(data)
        }
        setUploadedImage()
        reset()
    }
    const onHideDialog = () => {
        hideDialog()
        setUploadedImage()
        reset()
    }
    const customBase64Uploader = async (event) => {
        // convert file to base64 encoded
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then(r => r.blob()); //blob:url
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            setUploadedImage(base64data.split(',')[1])
        }
    }
    const itemCategoryDialogFooter = (
        <div className='grid text-right'>
            <div className='col-offset-5 col-3'>
                <Button type="button" label={t('cancel')} icon="pi pi-times" className="p-button-text col-6" onClick={onHideDialog} />
            </div>
            <div className='col-3'>
                <Button label={t('save')} icon="pi pi-check" className="p-button-text col-6" type="submit" />
            </div>
        </div>
    );
    return (
        <Dialog visible={itemCategoryDialog} style={{ width: '450px' }} header={t('newItemCategory')} modal className="p-fluid" onHide={onHideDialog}>
            <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                <div className="field">
                    <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>{t('name')}*</label>
                    <Controller name="name" control={control} rules={{ required: t('nameRequired') }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('name')}
                </div>
                <div className="field">
                    <label htmlFor="description" className={classNames({ 'p-error': errors.description })}>{t('description')}*</label>
                    <Controller name="description" control={control} rules={{ required: t('descriptionRequired') }} render={({ field, fieldState }) => (
                        <InputTextarea id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('description')}
                </div>
                <div className='grid col-12 12'>
                    <div className='col-12 '>
                        <div className="field">
                            <label htmlFor="image" className={classNames({ 'p-error': errors.description })}>{t('image')}*</label>
                            <FileUpload maxFileSize={100000} mode="basic" id="image" accept="image/png, image/jpeg" onSelect={customBase64Uploader} />
                        </div>
                    </div>
                    <Message severity="info" text={t('acceptImageFormat100')} className='w-full m-2' />

                    <div className='col-12'>
                        {uploadedImage && <Image src={"data:image/png;base64," + uploadedImage} alt={'uploadedImage'} className="shadow-2" width="100" zoomSrc={"data:image/png;base64," + uploadedImage} preview />}
                    </div>

                </div>
                {itemCategoryDialogFooter}
            </form>
        </Dialog>

    );
};

export default ItemCategoryModalForm;