// src/features/order/orderSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  newOrders: [],       // New orders that are being created or modified
  existingOrders: [],   // Existing orders already fetched from the backend
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    addNewOrderToList: (state, action) => {
      const {
        data,          // The new item data
        edit,          // Whether we're editing an existing order or not
        currentDay,    // The current day the order is for
        userId,        // The user's ID
        customerId,    // The customer's ID
        editId         // ID of the order being edited (if any)
      } = action.payload;

      // Create the menu item wrapper
      let menuItemWrapper = {
        name: data.name,
        price: data.price,
        amount: 1,
        menuItemDTO: {
          ...data,
          image: null,
          imagePreview: data.image
        }
      };

      // Initialize a single order object with basic properties
      let singleItem = {
        cateringId: data.cateringId,
        customerId,       // Get customerId from the payload
        userId            // Set userId from the payload
      };

      // Clone the newOrders array, either newOrders or existingOrders based on `edit`
      let updatedOrders = edit ? [...state.existingOrders] : [...state.newOrders];

      // Check if there are already orders for the current day
      let existingOrderForDate = updatedOrders.find(order => order.date === currentDay);

      if (existingOrderForDate) {
        // Clone the existing order for the current day
        singleItem = { ...existingOrderForDate };

        // Check if an item with the same category already exists
        let existingCategoryIndex = singleItem.menuItemSequences?.findIndex(item =>
          item.menuItemDTO.itemCategoryName === data.itemCategoryName
        );
        if(edit){
          singleItem.menuItems = null
        }
        if (edit && existingCategoryIndex !== -1) {
          singleItem.isEdit = true;
          // If editing and an item with the same category exists, replace it
          singleItem.menuItemSequences[existingCategoryIndex] = menuItemWrapper;
        } else {
          // Check if the item with the same ID already exists in the menuItemSequences
          let existingMenuItemIndex = singleItem.menuItemSequences?.findIndex(item =>
            item.menuItemDTO.id === data.id
          );

          if (existingMenuItemIndex !== -1) {
            // If the item already exists, increase the amount
            singleItem.menuItemSequences[existingMenuItemIndex].amount += 1;
          } else {
            // If the item doesn't exist, add it to the menuItemSequences
            singleItem.menuItemSequences = [
              ...singleItem.menuItemSequences,
              menuItemWrapper
            ];
          }
        }
      } else {
        // If no orders exist for the current day, add the new item as the first entry
        singleItem.menuItemSequences = [menuItemWrapper];
      }

      // Set the date for the order
      singleItem.date = currentDay;

      // Find the index of the order for the current day in the orders array
      let orderIndex = updatedOrders.findIndex(order => order.date === currentDay);
      if (editId) {
        singleItem.id = editId;
      }

      if (orderIndex !== -1) {
        // If an order for the current day exists, update it
        updatedOrders[orderIndex] = singleItem;
      } else {
        // If no order exists for the current day, add the new order
        updatedOrders.push(singleItem);
      }

      // Update the appropriate state (newOrders or existingOrders)
   
        state.newOrders = updatedOrders;
      
    },
    removeOrderItemForDate: (state, action) => {
      const { date, itemId } = action.payload;

      // Search for the order in newOrders only
      const orderForDate = state.newOrders.find(order => order.date === date);

      if (orderForDate) {
        // Clone the newOrders array
        const updatedNewOrders = [...state.newOrders];

        // Find the index of the order for the specified date
        const orderIndex = updatedNewOrders.findIndex(order => order.date === date);

        // Filter out the menu item based on the itemId
        const updatedMenuItemSequences = orderForDate.menuItemSequences.filter(
          sequence => sequence.menuItemDTO?.id !== itemId
        );

        // Update the order with the filtered menuItemSequences
        updatedNewOrders[orderIndex] = {
          ...orderForDate,
          menuItemSequences: updatedMenuItemSequences
        };

        // Update the newOrders in the state
        state.newOrders = updatedNewOrders;
      }
    },
    updateOrderItemAmount: (state, action) => {
      const { date, itemId, amount } = action.payload;

      // Find the order for the specified date in newOrders
      const orderForDate = state.newOrders.find(order => order.date === date);

      if (orderForDate) {
        // Clone the newOrders array
        const updatedNewOrders = [...state.newOrders];

        // Find the index of the order for the specified date
        const orderIndex = updatedNewOrders.findIndex(order => order.date === date);

        // Find the index of the item in the menuItemSequences array
        const itemIndex = orderForDate.menuItemSequences.findIndex(
          sequence => sequence.menuItemDTO?.id === itemId
        );

        if (itemIndex !== -1) {
          // Create a new updated item with the new amount
          const updatedMenuItem = {
            ...orderForDate.menuItemSequences[itemIndex],
            amount: amount
          };

          // Clone the menuItemSequences array
          const updatedMenuItemSequences = [...orderForDate.menuItemSequences];

          // Update the specific item with the new amount
          updatedMenuItemSequences[itemIndex] = updatedMenuItem;

          // Update the order with the updated menuItemSequences
          updatedNewOrders[orderIndex] = {
            ...orderForDate,
            menuItemSequences: updatedMenuItemSequences
          };

          // Update the state with the modified newOrders array
          state.newOrders = updatedNewOrders;
        }
      }
    },
    addShiftOrCommentToOrder: (state, action) => {
      const { date, shift, comment } = action.payload;

      // Find the order for the specified date in newOrders
      const orderForDate = state.newOrders.find(order => order.date === date);

      if (orderForDate) {
        // Clone the newOrders array
        const updatedNewOrders = [...state.newOrders];

        // Find the index of the order for the specified date
        const orderIndex = updatedNewOrders.findIndex(order => order.date === date);

        // Update the order by adding/updating the shift and comment values
        const updatedOrder = {
          ...orderForDate,
          ...(shift && { shift }),  // Add/Update shift if it's provided
          ...(comment && { comment })  // Add/Update comment if it's provided
        };

        // Replace the order at the correct index with the updated order
        updatedNewOrders[orderIndex] = updatedOrder;

        // Update the state with the modified newOrders array
        state.newOrders = updatedNewOrders;
      }
  },
  // -------- CRUD for New Orders --------
  addNewOrder: (state, action) => {
    const newOrder = action.payload;
    state.newOrders.push(newOrder);
  },

  updateNewOrder: (state, action) => {
    const { id, updatedOrder } = action.payload;
    const index = state.newOrders.findIndex(order => order.id === id);
    if (index !== -1) {
      state.newOrders[index] = { ...state.newOrders[index], ...updatedOrder };
    }
  },

  deleteNewOrder: (state, action) => {
    const id = action.payload;
    state.newOrders = state.newOrders.filter(order => order.id !== id);
  },

  // -------- CRUD for Existing Orders --------
  addExistingOrder: (state, action) => {
    const newOrder = action.payload;
    state.existingOrders.push(newOrder);
  },

  updateExistingOrder: (state, action) => {
    const { id, updatedOrder } = action.payload;
    const index = state.existingOrders.findIndex(order => order.id === id);
    if (index !== -1) {
      state.existingOrders[index] = { ...state.existingOrders[index], ...updatedOrder };
    }
  },

  deleteExistingOrder: (state, action) => {
    const id = action.payload;
    state.existingOrders = state.existingOrders.filter(order => order.id !== id);
  },

  // -------- Move Order from New to Existing --------
  moveNewOrderToExisting: (state, action) => {
    const id = action.payload;
    const order = state.newOrders.find(order => order.id === id);
    if (order) {
      state.existingOrders.push(order);
      state.newOrders = state.newOrders.filter(order => order.id !== id);
    }
  },

  // -------- Fetch existing orders from server (initially) --------
  setExistingOrders: (state, action) => {
    state.existingOrders = action.payload;  // Set entire list from the backend
  },
  setNewOrders: (state, action) => {
    state.newOrders = action.payload;  // Set entire list from the backend
  },
  resetOrders: (state) => {
    state.existingOrders = [];  // Set entire list from the backend
    state.newOrders = [];  // Set entire list from the backend
  }
}
});

// Export actions and reducer
export const {
  addNewOrderToList,
  removeOrderItemForDate,
  updateOrderItemAmount,
  addShiftOrCommentToOrder,
  addNewOrder,
  updateNewOrder,
  deleteNewOrder,
  addExistingOrder,
  updateExistingOrder,
  deleteExistingOrder,
  moveNewOrderToExisting,
  setExistingOrders,
  resetOrders
} = orderSlice.actions;

export default orderSlice.reducer;
