import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const MenuItemAttributeForm = (props) => {
    const { menuItemAttributeDialog, hideDialog, saveMenuItemAttribute, updateMenuItemAttribute, menuItemAttributeToEdit } = props

    let defaultValues = {
        name: '',
        description: '',
        isPublic: false,
    }
    const { t } = useTranslation();

    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });

    useEffect(() => {
        if (menuItemAttributeToEdit?.name) {
            setValue('name', menuItemAttributeToEdit?.name)
        }

        if (menuItemAttributeToEdit?.id) {
            setValue('id', menuItemAttributeToEdit?.id)
        }
        if (menuItemAttributeToEdit?.isPublic) {
            setValue('isPublic', menuItemAttributeToEdit?.isPublic)
        }
    }, [menuItemAttributeToEdit])
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const submitFunction = (data) => {
        if (menuItemAttributeToEdit) {
            updateMenuItemAttribute(data)
        } else {
            saveMenuItemAttribute(data)
        }
        reset()
    }
    const onHideDialog = () => {
        hideDialog()
        reset()
    }

    const menuItemAttributeDialogFooter = (
        <div className='grid text-right'>
            <div className='col-offset-5 col-3'>
                <Button type="button" label={t('cancel')} icon="pi pi-times" className="p-button-text col-6" onClick={onHideDialog} />
            </div>
            <div className='col-3'>
                <Button label={t('save')} icon="pi pi-check" className="p-button-text col-6" type="submit" />
            </div>
        </div>
    );
    return (
        <Dialog visible={menuItemAttributeDialog} style={{ width: '450px' }} header={t('newMenuItemAttribute')} modal className="p-fluid" onHide={onHideDialog}>
            <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                <div className="field">
                    <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>{t('name')}*</label>
                    <Controller name="name" control={control} rules={{ required: t('nameRequired') }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('name')}
                </div>
                <div className="field col-12 md:col-6 lg:col-6 xl-col-6">
                        <label htmlFor="isPublic">{t('isPublic')}</label>
                        <Controller name="isPublic" control={control} render={({ field, fieldState }) => (
                            <Checkbox inputId={field.name} onChange={(e) => {
                                field.onChange(e.checked)
                            }} checked={field.value} />
                        )} />
                    </div>
                {menuItemAttributeDialogFooter}
            </form>
        </Dialog>

    );
};

export default MenuItemAttributeForm;