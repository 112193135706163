import { Button } from 'primereact/button';
import { useState } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';

const SingleCaptcha = (props) => {
    const { onSuccess } = props
    const { t } = useTranslation()
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [loading, setLoading] = useState(false); // State for button loading
    const [token, setToken] = useState();

    const handleVerify = async () => {
        setLoading(true); // Show loading spinner
        if (!executeRecaptcha) return;
        const token = await executeRecaptcha('submit_form');
        if (token) {
            onSuccess()
            setToken(token)
            setLoading(false)
        }
        // Send the token to your server for verification
    };

    return <Button
        label={t('notRobot')}
        className='p-button-outlined p-4 p-button-info'
        icon={token ? "pi pi-check" : "pi pi-stop"}
        onClick={handleVerify}
        loading={loading} // Show loading spinner while waiting for token
        disabled={loading && !token} // Disable button until token is available
    />;
}
const CaptchaComponent = (props) => {
    const { onSuccess } = props

    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LeC23AqAAAAALqwhqYKUehKveEBwY_H7Nmtl3HQ">
            <SingleCaptcha onSuccess={onSuccess} />
        </GoogleReCaptchaProvider>
    );
}

export default CaptchaComponent;