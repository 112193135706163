import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import StockService from '../../service/StockService';
import StockModalForm from './StockForm';
import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import { searchCustomerParams, searchStockItemParams, searchStockParams } from '../../service/serviceObjects';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../context';
import StockItemsService from '../../service/StockItemService';
import CustomerService from '../../service/CustomerService';
import FilterStockForm from './FilterStockForm';
import moment from 'moment';
const Stock = () => {
    const authContext = useContext(AuthContext);

    let stock = {
        cateringId: authContext.cateringId
    }
    const [stockDialog, setStockDialog] = useState(false);
    const [deleteStockDialog, setDeleteStockDialog] = useState(false);
    const [deleteStocksDialog, setDeleteStocksDialog] = useState(false);
    const [stocks, setStocks] = useState([]);
    const [stockItems, setStockItems] = useState([]);
    const [selectedStock, setSelectedStock] = useState(null);
    const [stockEdit, setStockEdit] = useState(null)
    const [stockDelete, setStockDelete] = useState(null)
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [customersDropdownList, setCustomersDropdownList] = useState([]);

    const toast = useRef(null);
    const dt = useRef(null);

    const customersService = new CustomerService();
    const stockItemService = new StockItemsService();
    const stockService = new StockService();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const { t } = useTranslation();
    const getAllStockItems = () => {
        let searchObject = { ...searchStockItemParams }
        searchObject.cateringId = authContext.cateringId
        searchObject.pageSize = 10000
        stockItemService.search(searchObject).then(data => {
            setStockItems(data.items)

        })

    }
    const getMyCustomers = () => {
        let searchObject = searchCustomerParams
        searchObject.pageSize = 1000
        searchObject.cateringId = authContext.cateringId
        customersService.search(searchObject).then(data => {
            if (data?.items?.length > 0) {
                let customersForDropdown = data.items.map(x => ({
                    value: x.id, label: x.name
                }))
                setCustomersDropdownList(customersForDropdown)
            }
        })
    }
    useEffect(() => {
        getMyCustomers()
        // getAllStocks()
        getAllStockItems()
    }, []);
    const getAllStocks = (searchData) => {
        setLoadingTable(true)
        let searchObject = searchStockParams
        if (searchData) {
            searchObject = { ...searchStockParams, ...searchData }
        }
        if (searchData.minDate) {
            searchObject.minDate = moment(searchData.minDate).format("YYYY-MM-DD").toString()
        }
        if (searchData.maxDate) {
            searchObject.maxDate = moment(searchData.maxDate).format("YYYY-MM-DD").toString()
        }
        searchObject.cateringId = authContext.cateringId ?? 0
        stockService.search(searchObject).then(data => {
            setFirst(data.pageNumber * data.pageSize);
            setRows(data.pageSize);
            setTotalRecords(data.itemCount)
            setStocks(data.items)
            setLoadingTable(false)
        })

    }
    const openNew = () => {
        setStockDialog(true);
    }

    const hideDialog = () => {
        if (stockEdit) {
            setStockEdit()
        }
        setStockDialog(false);
    }

    const hideDeleteStockDialog = () => {
        setStockDelete();
        setDeleteStockDialog(false);
    }

    const hideDeleteStocksDialog = () => {
        setDeleteStocksDialog(false);
    }
    const saveStock = (data) => {
        if (data) {
            let submitRequest = { ...stock, ...data }
            if (data.stockItemId) {
                submitRequest.stockItemDTO = {
                    id: data.stockItemId
                }
            }
            stockService.save(submitRequest).then((value) => {
                setStockDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('stockAdded'), life: 3000 })
                // getAllStocks()
            }).catch(reason => {
                setStockDialog(false)
                toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorSavingStock'), life: 3000 })
            })
        }
    }
    const updateStock = (data) => {
        if (data) {
            let submitRequest = { ...stock, ...data }
            stockService.update(submitRequest.id, submitRequest).then((value) => {
                setStockDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('stockUpdated'), life: 3000 })
                getAllStocks()
            })
        }
    }
    const editStock = (item) => {
        setShowProgressBar(true)
        stockService.get(item.id).then((data) => {
            setShowProgressBar(false)
            setStockEdit(data)
            setStockDialog(true);
        })
    }

    const confirmDeleteStock = (item) => {
        setStockDelete(item);
        setDeleteStockDialog(true);
    }

    const deleteStock = () => {
        stockService.delete(stockDelete.id).then(data => {
            setStockDelete(null);
            setDeleteStockDialog(false);
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('stockDeleted'), life: 3000 });
            getAllStocks()
        })
    }
    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteStocksDialog(true);
    }

    async function deleteSelectedStock() {
        await selectedStock.forEach(element => {
            stockService.delete(element.id).then(data => {
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('stockDeleted'), life: 3000 });
            })
        });
        setDeleteStocksDialog(false);
        setSelectedStock(null);
        getAllStocks()

    }
    const onPage = (event) => {
        let searchParams = searchStockParams
        searchParams.pageNumber = event.page
        searchParams.pageSize = event.rows
        getAllStocks(searchParams)
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("new")} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label={t("delete")} icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedStock || !selectedStock.length} />
                </div>
            </React.Fragment>
        )
    }

    const stockItemIdBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("name")}</span>
                {rowData?.stockItemDTO?.name}
            </>
        );
    }

    const amountBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("amount")}</span>
                <span className={`status`}>{rowData.amount + " " + t(rowData?.stockItemDTO?.unit)}</span>
            </>
        )
    }
    const dateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("importDate")}</span>
                <span className={`status`}>{t(rowData.importDate)}</span>
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions mt-2 text-center">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editStock(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteStock(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t("manageStocks")}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t("search")} />
            </span>
        </div>
    );

    const deleteStockDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteStockDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteStock} />
        </>
    );
    const deletStocksDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteStocksDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedStock} />
        </>
    );
    const paginationTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("previous")}</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("next")}</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'All', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        }
    };
    return (
        <div className="grid crud-demo">
            <div className="card col-12 p-4">
                <FilterStockForm searchStock={getAllStocks} customersDropdownList={customersDropdownList} />
            </div>
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    <DataTable ref={dt} value={stocks} selection={selectedStock} onSelectionChange={(e) => setSelectedStock(e.value)}
                        dataKey="id" paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate={t('defaultPaginationMessage')} onPage={onPage}
                        globalFilter={globalFilter} emptyMessage="Stock is empty" header={header} responsiveLayout="scroll" loading={loadingTable}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="stockItemId" header={t("stockItemId")} body={stockItemIdBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                        <Column field="amount" header={t("amount")} body={amountBodyTemplate} headerStyle={{ width: '25%', minWidth: '10rem' }}></Column>
                        <Column field="importDate" header={t("importDate")} body={dateBodyTemplate} headerStyle={{ width: '25%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                    </DataTable>

                    <StockModalForm customersDropdownList={customersDropdownList} stockItems={stockItems} stockDialog={stockDialog} saveStock={saveStock} updateStock={updateStock} hideDialog={hideDialog} stockToEdit={stockEdit} />
                    <Dialog visible={deleteStockDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deleteStockDialogFooter} onHide={hideDeleteStockDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {stockDelete && <span>{t("areYouSureYouWantToDelete")} <b>{stockDelete.name}</b>?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={deleteStocksDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deletStocksDialogFooter} onHide={hideDeleteStocksDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {selectedStock && <span>{t("areYouSureYouWantToDelete")}?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Stock, comparisonFn);
