import { PublicClientApplication } from '@azure/msal-browser';


const msalConfig = {
    auth: {
        clientId: '34d8a9b0-ef57-492c-8d63-e2807daf1381',
        authority: 'https://login.microsoftonline.com/ecc4bc3b-ec77-4c59-9d62-53d34d6d44aa',
        redirectUri: 'https://app.gfood.rs/',
    },
    cache: {
        cacheLocation: 'sessionStorage', // Store tokens in sessionStorage
        storeAuthStateInCookie: false, // Do not store auth state in cookies
    },
};

let msalInstance = new PublicClientApplication(msalConfig);
const externalAuthService = {
    login: async (clientId, tenantId) => {
        if(clientId && tenantId){
            let auth = {
                clientId: clientId,
                authority: 'https://login.microsoftonline.com/' + tenantId,
                redirectUri: 'https://app.gfood.rs/',
            }
            msalInstance = new PublicClientApplication({...msalConfig, auth:auth});
        }
        await msalInstance.initialize()
        const loginRequest = {
            scopes: ['openid', 'profile', 'email'],
        };

        return msalInstance.loginPopup(loginRequest)
            .then(response => {
                return response.account;
            }).catch(error=>{
                console.log(error, 'error');
            });
    },

    logout: () => {
        msalInstance.logout();
    },

    getToken: async () => {
        await msalInstance.initialize()
        const tokenRequest = {
            scopes: ['openid', 'profile', 'email'],
        };

        return msalInstance.acquireTokenSilent(tokenRequest)
            .then(response => {
                return response.accessToken;
            })
            .catch(error => {
                console.error('Error acquiring token:', error);
                return null;
            });
    },
};

export default externalAuthService;