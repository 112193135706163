import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../utilities/general';

const MenuItemModalForm = (props) => {
    const { customersDropdownList } = props
    const [uploadedImage, setUploadedImage] = useState(null);
    const [isPublic, setIsPublic] = useState(false);


    const { menuItemDialog, hideDialog, saveMenuItem, updateMenuItem, menuItemToEdit, itemCategories, menuItemAttributes } = props
    let defaultValues = {
        name: '',
        description: '',
        isPublic: '',
        price: 0,
        customerId: 0,
    }

    const { control, formState, formState: { errors }, handleSubmit, reset, setValue, getValues } = useForm({ defaultValues });
    const { t } = useTranslation();


    useEffect(() => {
        if (menuItemToEdit?.name) {
            setValue('name', menuItemToEdit?.name)
        }
        if (menuItemToEdit?.customerId) {
            setValue('customerId', menuItemToEdit?.customerId)
        }
        if (menuItemToEdit?.description) {
            setValue('description', menuItemToEdit?.description)
        }
        if (menuItemToEdit?.id) {
            setValue('id', menuItemToEdit?.id)
        }
        if (menuItemToEdit?.price) {
            setValue('price', menuItemToEdit?.price)
        }
        if (menuItemToEdit?.itemCategoryId) {
            setValue('itemCategoryId', menuItemToEdit.itemCategoryId)
        }
        if (menuItemToEdit?.isVegetarian) {
            setValue('isVegetarian', menuItemToEdit.isVegetarian)
        }
        if (menuItemToEdit?.isVegan) {
            setValue('isVegan', menuItemToEdit.isVegan)
        }
        if (menuItemToEdit?.isLenten) {
            setValue('isLenten', menuItemToEdit.isLenten)
        }
        if (menuItemToEdit?.isPublic) {
            setIsPublic(true)
            setValue('isPublic', menuItemToEdit.isPublic)
        }
        if (menuItemToEdit?.image) {
            setUploadedImage(menuItemToEdit.image)
        }
        if (menuItemToEdit?.menuItemAttributeDTOList?.length > 0) {
            let array = []
            menuItemToEdit?.menuItemAttributeDTOList.forEach(element => {
                array.push(element.id)
            });
            setValue('menuItemAttribute', array)

        }
    }, [menuItemToEdit])
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const submitFunction = (data) => {
        let saveData = { ...data }
        if (uploadedImage) {
            saveData.image = uploadedImage
        }
        if (menuItemToEdit) {
            updateMenuItem(saveData)
        } else {
            saveMenuItem(saveData)
        }
        setUploadedImage()
        reset()
    }
    const onHideDialog = () => {
        hideDialog()
        reset()
        setUploadedImage()
    }
    const menuItemDialogFooter = (
        <div className='grid text-right'>
            <div className='col-offset-5 md:col-offset-8 lg:col-offset-8 xl:col-offset-8 col-3 md:col-2'>
                <Button type="button" label={t('cancel')} icon="pi pi-times" className="p-button-text col-6" onClick={onHideDialog} />
            </div>
            <div className='col-3 md:col-2'>
                <Button label={t('save')} icon="pi pi-check" className="p-button-text col-6" type="submit" />
            </div>
        </div>
    );

    let categories = [];
    if (itemCategories) {
        categories = itemCategories.map(x => ({
            label: x.name,
            value: x.id
        }))
    }
    let attributes = [

    ];
    if (menuItemAttributes) {
        attributes = menuItemAttributes.map(x => ({
            label: x.name,
            value: x.id
        }))
    }
    const customBase64Uploader = async (event) => {
        // convert file to base64 encoded
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then(r => r.blob()); //blob:url
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            setUploadedImage(base64data.split(',')[1])
        }
    }
    const handleValueChange = (value) => {
        // Handle value change here, you may want to perform additional parsing or validation
    };
    return (
        <Dialog visible={menuItemDialog} style={{ width: isMobile() ? '450px' : '760px' }} header={t('newMenuItem')} modal className="p-fluid" onHide={onHideDialog}>
            <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                <div className='grid'>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>{t('name')}*</label>
                        <Controller name="name" control={control} rules={{ required: t('nameRequired') }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('name')}
                    </div>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="itemCategoryId" className={classNames({ 'p-error': errors.name })}>{t('itemCategory')}*</label>
                        <Controller name="itemCategoryId" control={control} rules={{ required: t('itemCategoryRequired') }} render={({ field, fieldState }) => (
                            <Dropdown id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} options={categories} />
                        )} />
                        {getFormErrorMessage('itemCategoryId')}
                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="customerId">{t('customer')}</label>
                        <Controller name="customerId" control={control} render={({ field, fieldState }) => (
                            <Dropdown id={field.name} {...field} showClear options={customersDropdownList} />
                        )} />
                        {getFormErrorMessage('customerId')}
                    </div>
                    <div className='grid col-12 md:col-6'>

                        <div className='col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4'>
                            <div className="field">
                                <label htmlFor="image" className={classNames({ 'p-error': errors.description })}>{t('image')}*</label>
                                <FileUpload maxFileSize={300000} mode="basic" id="image" accept="image/png, image/jpeg" onSelect={customBase64Uploader} />
                            </div>
                        </div>
                        <Message severity="info" text={t('acceptImageFormat')} className='w-full m-2' />

                        <div className='col-12 sm:col-12 md:col-6 lg:col-8 xl:col-8'>
                            {uploadedImage && <Image src={"data:image/png;base64," + uploadedImage} alt={'uploadedImage'} className="shadow-2" width="100" zoomSrc={"data:image/png;base64," + uploadedImage} preview />}
                        </div>

                    </div>
                    <div className="field col-12 md:col-6">
                        <label htmlFor="description" className={classNames({ 'p-error': errors.description })}>{t('description')}*</label>
                        <Controller name="description" control={control} rules={{ required: t('descriptionRequired') }} render={({ field, fieldState }) => (
                            <InputTextarea id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('description')}
                    </div>
                    <div className='col-12 md:col-6 flex justify-content-around'>
                        <div className="field">
                            <label htmlFor="isVegetarian" className={classNames({ 'p-error': errors.isVegetarian })}>{t('isVegetarian')}</label>
                            <Controller name="isVegetarian" control={control} render={({ field, fieldState }) => (
                                <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                // <InputTextarea id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                            )} />
                            {getFormErrorMessage('isVegetarian')}
                        </div>
                        <div className="field">
                            <label htmlFor="isVegan" className={classNames({ 'p-error': errors.isVegan })}>{t('isVegan')}</label>
                            <Controller name="isVegan" control={control} render={({ field, fieldState }) => (
                                <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                // <InputTextarea id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                            )} />
                            {getFormErrorMessage('isVegan')}
                        </div>
                        <div className="field">
                            <label htmlFor="isLenten" className={classNames({ 'p-error': errors.isLenten })}>{t('isLenten')}</label>
                            <Controller name="isLenten" control={control} render={({ field, fieldState }) => (
                                <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                // <InputTextarea id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                            )} />
                            {getFormErrorMessage('isLenten')}
                        </div>

                    </div>
                    <div className="field col-12 md:col-12">
                        <label htmlFor="menuItemAttribute" className={classNames({ 'p-error': errors.name })}>{t('menuItemAttribute')}</label>
                        <Controller name="menuItemAttribute" control={control} render={({ field, fieldState }) => (
                            <MultiSelect id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} display="chip" options={attributes} />
                        )} />
                    </div>
                    {props?.cateringData?.publicMenu && <div className="field col-12 md:col-6 lg:col-6 xl-col-6">
                        <label htmlFor="isPublic">{t('isPublic')}</label>
                        <Controller name="isPublic" control={control} render={({ field, fieldState }) => (
                            <Checkbox inputId={field.name} onChange={(e) => {
                                field.onChange(e.checked)
                                setIsPublic(e.checked)
                            }} checked={field.value} />
                        )} />
                    </div>}
                    <div className="field col-12 md:col-6 lg:col-6 xl-col-6">
                        <label htmlFor="price" className={classNames({ 'p-error': errors.price })}>{t('price')}</label>
                        <Controller name="price" control={control} render={({ field, fieldState }) => (
                            <InputNumber id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} mode="currency" currency="RSD" locale="en-US" onChange={(e) => field.onChange(e.value)} onValueChange={handleValueChange} // Handle value change
                            />

                            //   <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('price')}
                    </div>
                </div>
                {menuItemDialogFooter}
            </form>
        </Dialog>

    );
};

export default MenuItemModalForm;