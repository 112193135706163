import { Button } from 'primereact/button';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';
import { Ripple } from 'primereact/ripple';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../context';
import ItemCategoryService from '../../../service/ItemCategoryService';
import MenuItemService from '../../../service/MenuItemService';
import { searchCustomerParams, searchItemCategoryParams, searchMenuItemAttributeParams, searchMenuItemParams, searchStockItemParams } from '../../../service/serviceObjects';
import MenuItemModalForm from './MenuItemModalForm';
import { InputText } from 'primereact/inputtext';
import RecipeComponent from './RecipeComponent';
import StockItemsService from '../../../service/StockItemService';
import CateringService from '../../../service/CateringService';
import { Rating } from 'primereact/rating';
import MenuItemAttribute from '../../../service/MenuItemAttributeService';
import CustomerService from '../../../service/CustomerService';

const MenuItem = () => {
    const authContext = useContext(AuthContext);
    let menuItem = {
        cateringId: authContext.cateringId || 0,
        itemCategoryId: null,
        name: null,
        description: null
    }
    const [menuItemDialog, setMenuItemDialog] = useState(false);
    const [deleteMenuItemDialog, setDeleteMenuItemDialog] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [menuItemEdit, setMenuItemEdit] = useState(null)
    const [menuItemDelete, setMenuItemDelete] = useState(null)
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [itemCategories, setItemCategories] = useState([])
    const [menuItemAttributes, setMenuItemAttributes] = useState([])
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const [selectedItemCategory, setSelectedItemCategory] = useState(null);
    const [layout, setLayout] = useState('grid');
    const [filterName, setFilterName] = useState();
    const [showRecipeModal, setShowRecipeModal] = useState();
    const [stockItems, setStockItems] = useState([]);
    const [cateringData, setCateringData] = useState(null);
    const [customersDropdownList, setCustomersDropdownList] = useState([]);
    const [customer, setCustomer] = useState();

    const toast = useRef(null);
    const dt = useRef(null);

    const menuItemService = new MenuItemService();
    const itemCategoryService = new ItemCategoryService();
    const stockItemService = new StockItemsService();
    const cateringService = new CateringService();
    const menuItemAttributeService = new MenuItemAttribute();
    const customersService = new CustomerService();

    const { t } = useTranslation();
    const getMyCustomers = () => {
        let searchObject = searchCustomerParams
        searchObject.pageSize = 1000
        searchObject.cateringId = authContext.cateringId
        customersService.search(searchObject).then(data => {
            if (data?.items?.length > 0) {
                let customersForDropdown = data.items.map(x => ({
                    value: x.id, label: x.name
                }))
                setCustomersDropdownList(customersForDropdown)
            }
        })
    }
    const getCateringData = () => {
        cateringService.get(authContext.cateringId).then((data) => {
            setCateringData(data)
        })
    }
    const getAllStockItems = () => {
        let searchObject = { ...searchStockItemParams }
        searchObject.cateringId = authContext.cateringId
        searchObject.pageSize = 10000
        stockItemService.search(searchObject).then(data => {
            setStockItems(data.items)

        })

    }
    useEffect(() => {
        getMyCustomers()
        getCateringData()
        getAllStockItems()
        getAllMenuItems()
        getAllMenuItemAttributes()
        getAllItemCategories((data) => {
            setItemCategories(data.items)
        })
    }, []);
    const getAllItemCategories = (onSuccess) => {
        let searchObject = searchItemCategoryParams
        searchObject.cateringId = authContext.cateringId;
        itemCategoryService.search(searchObject).then(data => {
            if (onSuccess) {
                onSuccess(data)
            }
        })
    }
    const getAllMenuItemAttributes = (searchData) => {
        let searchObject = { ...searchMenuItemAttributeParams }
        searchObject.cateringId = authContext.cateringId ?? 0
        menuItemAttributeService.search(searchObject).then(data => {

            setMenuItemAttributes(data.items)
        })

    }
    const getAllMenuItems = (searchData) => {
        setLoadingTable(true)
        let searchObject = { ...searchMenuItemParams }
        if (searchData) {
            searchObject = { ...searchMenuItemParams, ...searchData }
        }
        searchObject.cateringId = authContext.cateringId;

        menuItemService.search(searchObject).then(data => {
            setFirst(data.pageNumber * data.pageSize);
            setRows(data.pageSize);
            setTotalRecords(data.itemCount)
            setMenuItems(data.items)
            setLoadingTable(false)
        })

    }
    const openNew = () => {
        getAllItemCategories((data) => {
            setItemCategories(data.items)
            setMenuItemDialog(true);
        })
    }

    const hideDialog = () => {
        if (menuItemEdit) {
            setMenuItemEdit()
        }
        setMenuItemDialog(false);
    }
    const hideRecipeDialog = () => {
        setMenuItemEdit()
        setShowRecipeModal(false);
    }

    const hideDeleteMenuItemDialog = () => {
        setMenuItemDelete();
        setDeleteMenuItemDialog(false);
    }

    const saveRecipe = (data) => {
        if (data) {
            menuItemService.saveRecipe(data).then((value) => {
                setShowRecipeModal(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('recipeAdded'), life: 3000 })
                getAllMenuItems()
            }).catch(reason => {
                setShowRecipeModal(false)
                toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorSavingRecipe'), life: 3000 })
            })
        }
    }
    const saveMenuItem = (data) => {
        if (data) {
            let additionalData = {}
            if (data?.menuItemAttribute?.length > 0) {
                let menuItemAttributesDTO = data?.menuItemAttribute.map(element => {
                    return {
                        id: element
                    }
                });
                additionalData = {
                    menuItemAttributeDTOList: menuItemAttributesDTO
                }
            }
            let submitRequest = { ...menuItem, ...data, ...additionalData }
            menuItemService.save(submitRequest).then((value) => {
                setMenuItemDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('menuItemAdded'), life: 3000 })
                getAllMenuItems()
                setMenuItemEdit()
            }).catch(reason => {
                setMenuItemDialog(false)
                toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorSavingMenuItem'), life: 3000 })
            })
        }
    }
    const updateMenuItem = (data) => {
        let additionalData = {}
        if (data?.menuItemAttribute?.length > 0) {
            let menuItemAttributesDTO = data?.menuItemAttribute.map(element => {
                return {
                    id: element
                }
            });
            additionalData = {
                menuItemAttributeDTOList: menuItemAttributesDTO
            }
        }
        if (data) {
            let submitRequest = { ...menuItem, ...data, ...additionalData }
            menuItemService.update(submitRequest.id, submitRequest).then((value) => {
                setMenuItemDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('menuItemUpdated'), life: 3000 })
                getAllMenuItems()
                setMenuItemEdit()
            })
        }
    }
    const editMenuItem = (item, recipe) => {
        setShowProgressBar(true)
        menuItemService.get(item.id).then((data) => {
            let searchObject = searchItemCategoryParams
            searchObject.cateringId = authContext.cateringId;
            itemCategoryService.search(searchObject).then(dataToEdit => {
                setItemCategories(dataToEdit.items)
            })
            setShowProgressBar(false)
            setMenuItemEdit(data)
            if (recipe) {
                setShowRecipeModal(true)
            } else {
                setMenuItemDialog(true);

            }
        })
    }
    const confirmDeleteMenuItem = (item) => {
        setMenuItemDelete(item);
        setDeleteMenuItemDialog(true);
    }

    const deleteMenuItem = () => {
        menuItemService.delete(menuItemDelete.id).then(data => {
            setMenuItemDelete(null);
            setDeleteMenuItemDialog(false);
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('menuItemDeleted'), life: 3000 });
            getAllMenuItems()
        })
    }
    const onSelectItemCategory = (value) => {
        let searchData = {
            itemCategoryId: value
        }
        setSelectedItemCategory(value)
        getAllMenuItems(searchData)
    }

    const onPage = (event) => {
        let searchParams = { ...searchMenuItemParams }
        searchParams.pageNumber = event.page
        searchParams.pageSize = event.rows
        if (selectedItemCategory) {
            searchParams.itemCategoryId = selectedItemCategory
        }
        getAllMenuItems(searchParams)
    }
    const onCustomerChange = (value) => {
        // getTemplateMenu(value)
        setCustomer(value)
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t('new')} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)} />
                </div>
            </React.Fragment>
        )
    }
    let itemCategoryOptions = []
    if (itemCategories) {
        itemCategoryOptions = itemCategories.map(x => ({
            name: x.name, value: x.id, image: x.image
        }))
    }
    const itemCategoryButtonTemplate = (option) => {
        return <div className='w-full'>
            <div className='col-12 text-center'>
                <img src={option.image ? "data:image/png;base64," + option.image : "assets/layout/images/blank_category.png"} alt={option.name} className="w-3rem" />

            </div>
            <div className='col-12 text-center'>
                <span className="font-semibold">{option.name}</span>
            </div>
            {/* <i className="pi pi-tag mr-2" /> */}
        </div>
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            {/* <h5 className="m-0">{t('manageMenuItems')}</h5> */}
            <SelectButton className='category-select-button' optionLabel="name" value={selectedItemCategory} itemTemplate={itemCategoryButtonTemplate} options={itemCategoryOptions} onChange={(e) => onSelectItemCategory(e.value)}></SelectButton>
            <InputText value={filterName} onInput={(e) => setFilterName(e.target.value)} placeholder={t("name")} className='lg:w-15rem md:w-15rem sm:w-full' />
            <Dropdown id="customerId" showClear className='lg:w-15rem md:w-15rem sm:w-full' value={customer} options={customersDropdownList} onChange={(e) => onCustomerChange(e.value)} placeholder={t('selectCustomer')} />
            <Button icon="pi pi-search" label={t('search')} onClick={() => getAllMenuItems({ name: filterName, customerId: customer })} />

        </div>
    );

    const deleteMenuItemDialogFooter = (
        <>
            <Button label={t('no')} icon="pi pi-times" className="p-button-text" onClick={hideDeleteMenuItemDialog} />
            <Button label={t('yes')} icon="pi pi-check" className="p-button-text" onClick={deleteMenuItem} />
        </>
    );

    const paginationTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t('previous')}</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t('next')}</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: t('all'), value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        }
    };
    const renderListItem = (data) => {
        return (
            <div className="col-12">
                <div className="product-list-item">
                    <img src={data.image ? "data:image/png;base64," + data.image : "assets/layout/images/blank_menu_item.jpg"} alt={data.name} className="w-8rem" />
                    <div className="product-list-detail">
                        <div className="product-name">{data.name}</div>
                        <div className="product-description">{data.description}</div>
                        <i className="pi pi-tag product-category-icon"></i><span className="product-category">{data.itemCategoryName}</span>
                    </div>
                    <div className="product-list-action">
                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editMenuItem(data)} />
                        <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2" onClick={() => confirmDeleteMenuItem(data)} />
                        <Button className="p-button-rounded p-button-secondary mr-2 p-1" onClick={() => editMenuItem(data, true)} ><img src='assets/layout/images/recipe.png' style={{ width: '1.7rem', height: '1.7rem', margin: 0 }} /></Button>
                    </div>
                </div>
            </div>
        );
    }
    const gridItemTemplate = (data) => {
        return (
            <div className="col-12 md:col-3 lg:col-3 xl:col-3">
                <div className="card m-3 border-1 surface-border menu-item-wrapper">
                    <div className="flex align-items-center justify-content-between h-2rem">
                        <div className="flex align-items-center">
                            {
                                data?.itemCategoryImage ? <img src={"data:image/png;base64," + data.itemCategoryImage} alt={data.itemCategoryName} className="h-2rem" style={{ objectFit: 'contain' }} />
                                    : <i className="pi pi-tag mr-2" />

                            }
                            <span className="font-semibold">{data.itemCategoryName}</span>
                        </div>
                        <div className='flex'>
                            <span className={`product-badge status`}>{data.isVegetarian ? <img src={"assets/layout/images/vegetarian.jpg"} alt={data.name} className="h-2rem" />
                                : <></>}</span>
                            <span className={`product-badge status`}>{data.isVegan ? <img src={"assets/layout/images/vegan.png"} alt={data.name} className="h-2rem" />
                                : <></>}</span>
                            <span className={`product-badge status`}>{data.isLenten ? <img src={"assets/layout/images/posno.svg"} alt={data.name} className="h-2rem" />
                                : <></>}</span>
                        </div>


                    </div>
                    <div className="text-center">
                        <div className='h-10rem'>
                            <img src={data.image ? "data:image/png;base64," + data.image : "assets/layout/images/blank_menu_item.jpg"} alt={data.name} className="h-8rem my-3 mx-0 w-full" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="text-2xl font-bold">{data.name}</div>
                        <div className="mb-2 h-3rem">{data.description}</div>
                        <div className="flex w-full justify-content-center mb-2">
                            <Rating value={data?.rating} cancel={false} readOnly />
                            <span className='ml-2 text-md font-bold'>{data?.rating && parseFloat(data?.rating) != NaN ? parseFloat(data?.rating).toFixed(1) : 0.0}</span>
                        </div>
                    </div>
                    <div className="flex justify-content-between">
                        <span className="product-price mt-2">{data.price ?? 0} RSD</span>
                        <div className="actions">
                            <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editMenuItem(data)} />
                            <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2" onClick={() => confirmDeleteMenuItem(data)} />
                            <Button className="p-button-rounded p-button-secondary mr-2 p-1" onClick={() => editMenuItem(data, true)} ><img src='assets/layout/images/recipe.png' style={{ width: '1.7rem', height: '1.7rem' }} /></Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const itemTemplate = (data, layout) => {
        if (layout === 'list')
            return renderListItem(data);
        else if (layout === 'grid')
            return gridItemTemplate(data);
    }
    return (
        <div className="grid list-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    <DataView ref={dt} value={menuItems} layout={layout} header={header}
                        itemTemplate={itemTemplate} dataKey="id" paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate={t('defaultPaginationMessage')} onPage={onPage}
                        emptyMessage={t('noItemsFound')} responsiveLayout="scroll" loading={loadingTable} />
                    {menuItemDialog && <MenuItemModalForm menuItemDialog={menuItemDialog} saveMenuItem={saveMenuItem} updateMenuItem={updateMenuItem} hideDialog={hideDialog} menuItemToEdit={menuItemEdit} itemCategories={itemCategories} cateringData={cateringData} menuItemAttributes={menuItemAttributes} customersDropdownList={customersDropdownList} />
                    }
                    <Dialog visible={deleteMenuItemDialog} style={{ width: '450px' }} header={t('confirm')} modal footer={deleteMenuItemDialogFooter} onHide={hideDeleteMenuItemDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {menuItemDelete && <span>Are you sure you want to delete <b>{menuItemDelete.name}</b>?</span>}
                        </div>
                    </Dialog>
                    {menuItemEdit && <RecipeComponent saveRecipe={saveRecipe} stockItems={stockItems} menuItemToEdit={menuItemEdit} showModal={showRecipeModal} onHideModal={hideRecipeDialog} />
                    }
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MenuItem, comparisonFn);
