import moment from 'moment';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Ripple } from 'primereact/ripple';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../context';
import { searchTransactionParams } from '../../service/serviceObjects';
import TransactionService from '../../service/TransactionService';
import TransactionFilter from './TransactionFilter';
const TransactionPreview = () => {
    const authContext = useContext(AuthContext);

    const [deleteTransactionDialog, setDeleteTransactionDialog] = useState(false);
    const [deleteTransactionsDialog, setDeleteTransactionsDialog] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [transactionDelete, setTransactionDelete] = useState(null)
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [currentSearchData, setCurrentSearchData] = useState({});
    const toast = useRef(null);
    const dt = useRef(null);
    const transactionService = new TransactionService();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const { t } = useTranslation();

    useEffect(() => {
        // getAllTransactions()
    }, []);
    const getAllTransactions = (searchData) => {
        setLoadingTable(true)
        let searchObject = {...searchTransactionParams}
        if (searchData) {
            searchObject = { ...searchTransactionParams, ...searchData }
        }
        searchObject.cateringId = authContext.cateringId ?? 0
        searchObject.minDate = moment(searchData.minDate).format("YYYY-MM-DDTHH:mm:ss.SSS").toString()
        searchObject.maxDate = moment(searchData.maxDate).format("YYYY-MM-DDTHH:mm:ss.SSS").toString()

        transactionService.search(searchObject).then(data => {
            setFirst(data.pageNumber * data.pageSize);
            setRows(data.pageSize);
            setTotalRecords(data.itemCount)
            setTransactions(data.items)
            setLoadingTable(false)
        })

    }


    const hideDeleteTransactionDialog = () => {
        setTransactionDelete();
        setDeleteTransactionDialog(false);
    }

    const hideDeleteTransactionsDialog = () => {
        setDeleteTransactionsDialog(false);
    }


    const confirmDeleteItemCagegory = (item) => {
        setTransactionDelete(item);
        setDeleteTransactionDialog(true);
    }

    const deleteTransaction = () => {
        transactionService.delete(transactionDelete.id).then(data => {
            setTransactionDelete(null);
            setDeleteTransactionDialog(false);
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('transactionDeleted'), life: 3000 });
            getAllTransactions()
        })
    }
    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteTransactionsDialog(true);
    }

    async function deleteSelectedTransaction() {
        await selectedTransaction.forEach(element => {
            transactionService.delete(element.id).then(data => {
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('transactionDeleted'), life: 3000 });
            })
        });
        setDeleteTransactionsDialog(false);
        setSelectedTransaction(null);
        getAllTransactions()

    }
    const onPage = (event) => {
        let searchParams = {...currentSearchData}
        searchParams.pageNumber = event.page
        searchParams.pageSize = event.rows
        getAllTransactions(searchParams)
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("delete")} icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedTransaction || !selectedTransaction.length} />
                </div>
            </React.Fragment>
        )
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("name")}</span>
                {rowData.name}
            </>
        );
    }

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("description")}</span>
                <span className={`status`}>{rowData.description}</span>
            </>
        )
    }
    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <img src={rowData.image ? "data:image/png;base64," + rowData.image : "assets/layout/images/blank_category.png"} alt={rowData.name} className="w-3rem" />
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions mt-2 text-center">
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteItemCagegory(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t("manageTransactions")}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t("search")} />
            </span>
        </div>
    );

    const deleteTransactionDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteTransactionDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteTransaction} />
        </>
    );
    const deletTransactionsDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteTransactionsDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedTransaction} />
        </>
    );
    const paginationTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("previous")}</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("next")}</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'All', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        }
    };
    const typeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("type")}</span>
                <span className={`status`}>{t(rowData.type)}</span>
            </>
        )
    }
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                    <div className="card col-12 p-4">
                        <TransactionFilter searchTransactions={getAllTransactions} setCurrentSearchData={setCurrentSearchData} />
                    </div>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    <DataTable ref={dt} value={transactions} selection={selectedTransaction} onSelectionChange={(e) => setSelectedTransaction(e.value)}
                        dataKey="id" paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate={t('defaultPaginationMessage')} onPage={onPage}
                        globalFilter={globalFilter} emptyMessage={t('noItemsFound')} header={header} responsiveLayout="scroll" loading={loadingTable}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="transactionNumber" header={t("transactionNumber")} headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="type" header={t("type")} body={typeTemplate} headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="dateAndTime" header={t("dateAndTime")} headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="userId" header={t("userId")} headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="username" header={t("username")} headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="orderId" header={t("orderId")} headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="cateringName" header={t("cateringName")} headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="customerName" header={t("customerName")} headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                    </DataTable>

                    <Dialog visible={deleteTransactionDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deleteTransactionDialogFooter} onHide={hideDeleteTransactionDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {transactionDelete && <span>{t("areYouSureYouWantToDelete")} <b>{transactionDelete.name}</b>?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={deleteTransactionsDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deletTransactionsDialogFooter} onHide={hideDeleteTransactionsDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {selectedTransaction && <span>{t("areYouSureYouWantToDelete")}?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(TransactionPreview, comparisonFn);
