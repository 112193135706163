import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomerService from '../../service/CustomerService';
import { Chips } from 'primereact/chips';
import { Dropdown } from 'primereact/dropdown';

const ExternalAuthConfiguration = (props) => {
    const { customerId, customer } = props
    const { t } = useTranslation();
    const toast = useRef(null);
    const customerService = new CustomerService();
    useEffect(() => {
        let data = customer?.externalAuthDTO
        if (data?.tenantId) {
            setValue('tenantId', data?.tenantId)
        }
        if (data?.clientSecret) {
            setValue('clientSecret', data?.clientSecret)
        }
        if (data?.clientId) {
            setValue('clientId', data?.clientId)
        }
        if (customer?.domains) {
            setValue('domains', customer?.domains)
        }
    }, [])

    let defaultValues = {
        clientId: '',
        clientSecret: '',
        tenantId: '',
        domains: '',
        type: 'microsoft',

    }
    const typeOptions = [
        {
            label: "Microsoft Entra ID", value: "microsoft"
        }

    ]
    const { control, formState: { errors }, handleSubmit, setValue, getValues } = useForm({ defaultValues, mode: 'all' });
    const submitFunction = (data) => {

        customerService.update(customerId, { ...customer, domains: data.domains, externalAuthDTO: data }).then(() => {
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('customerSettingsUpdated'), life: 3000 })
        }).catch(reason => {
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorCustomerSettingsUpdated'), life: 3000 })
        })

    }
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("save")} icon="pi pi-save" className="p-button-success mr-2" />
                </div>
            </React.Fragment>
        )
    }
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">

                        <Toast ref={toast} />
                        <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                        <div className='col-12 mt-3'>
                            <h4>{t('microsoftAccountSettings')}</h4>
                        </div>
                        <div className='col-12 grid'>
                            <div className="field col-12 md:col-4 lg:col-4">
                                <label htmlFor="type" className={classNames({ 'p-error': errors.type })}>{t('type')}*</label>
                                <Controller name="type" control={control} rules={{ required: t('typeRequired') }} render={({ field, fieldState }) => (
                                    <Dropdown disabled id={field.name} {...field} options={typeOptions} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                {getFormErrorMessage('type')}
                            </div>
                            <div className="field col-12 md:col-4 lg:col-4">
                                <label htmlFor="domains" className={classNames({ 'p-error': errors.domains })}>{t('domains')}*</label>
                                <Controller name="domains" control={control} rules={{ required: t('domainsRequired') }} render={({ field, fieldState }) => (
                                    <Chips id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                {getFormErrorMessage('domains')}
                            </div>
                            <div className="field col-12 md:col-4 lg:col-4">
                                <label htmlFor="clientId" className={classNames({ 'p-error': errors.clientId })}>{t('clientId')}*</label>
                                <Controller name="clientId" control={control} rules={{ required: t('clientIdRequired') }} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                {getFormErrorMessage('clientId')}
                            </div>
                            <div className="field col-12 md:col-4 lg:col-4">
                                <label htmlFor="clientSecret" className={classNames({ 'p-error': errors.clientSecret })}>{t('clientSecret')}*</label>
                                <Controller name="clientSecret" control={control} rules={{ required: t('clientSecretRequired') }} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                {getFormErrorMessage('clientSecret')}
                            </div>
                            <div className="field col-12 md:col-4 lg:col-4">
                                <label htmlFor="tenantId" className={classNames({ 'p-error': errors.tenantId })}>{t('tenantId')}*</label>
                                <Controller name="tenantId" control={control} rules={{ required: t('tenantIdRequired') }} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                {getFormErrorMessage('tenantId')}
                            </div>

                        </div>

                    </form>
                </div>
            </div>

        </div >
    )
}


export default ExternalAuthConfiguration