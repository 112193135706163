import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import MenuItemAttributeService from '../../../service/MenuItemAttributeService';
import MenuItemAttributeForm from './MenuItemAttributeForm';
import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import { searchMenuItemAttributeParams } from '../../../service/serviceObjects';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../context';

const MenuItemAttribute = () => {
    const authContext = useContext(AuthContext);

    let menuItemAttribute = {
        cateringId: authContext.cateringId || 0,
        name: null,
        description: null
    }
    const [menuItemAttributeDialog, setMenuItemAttributeDialog] = useState(false);
    const [deleteMenuItemAttributeDialog, setDeleteMenuItemAttributeDialog] = useState(false);
    const [deleteMenuItemAttributesDialog, setDeleteMenuItemAttributesDialog] = useState(false);
    const [MenuItemAttributes, setMenuItemAttributes] = useState([]);
    const [selectedMenuItemAttribute, setSelectedMenuItemAttribute] = useState(null);
    const [menuItemAttributeEdit, setMenuItemAttributeEdit] = useState(null)
    const [menuItemAttributeDelete, setMenuItemAttributeDelete] = useState(null)
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const menuItemAttributeService = new MenuItemAttributeService();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const { t } = useTranslation();

    useEffect(() => {
        getAllMenuItemAttributes()
    }, []);
    const getAllMenuItemAttributes = (searchData) => {
        setLoadingTable(true)
        let searchObject = searchMenuItemAttributeParams
        if (searchData) {
            searchObject = { ...searchMenuItemAttributeParams, ...searchData }
        }
        searchObject.cateringId = authContext.cateringId ?? 0
        menuItemAttributeService.search(searchObject).then(data => {
            setFirst(data.pageNumber * data.pageSize);
            setRows(data.pageSize);
            setTotalRecords(data.itemCount)
            setMenuItemAttributes(data.items)
            setLoadingTable(false)
        })

    }
    const openNew = () => {
        setMenuItemAttributeDialog(true);
    }

    const hideDialog = () => {
        if (menuItemAttributeEdit) {
            setMenuItemAttributeEdit()
        }
        setMenuItemAttributeDialog(false);
    }

    const hideDeleteMenuItemAttributeDialog = () => {
        setMenuItemAttributeDelete();
        setDeleteMenuItemAttributeDialog(false);
    }

    const hideDeleteMenuItemAttributesDialog = () => {
        setDeleteMenuItemAttributesDialog(false);
    }
    const saveMenuItemAttribute = (data) => {
        if (data) {
            let submitRequest = { ...menuItemAttribute, ...data }
            menuItemAttributeService.save(submitRequest).then((value) => {
                setMenuItemAttributeDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('menuItemAttributeAdded'), life: 3000 })
                getAllMenuItemAttributes()
            }).catch(reason => {
                setMenuItemAttributeDialog(false)
                toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorSavingMenuItemAttribute'), life: 3000 })
            })
        }
    }
    const updateMenuItemAttribute = (data) => {
        if (data) {
            let submitRequest = { ...menuItemAttribute, ...data }
            menuItemAttributeService.update(submitRequest.id, submitRequest).then((value) => {
                setMenuItemAttributeDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('menuItemAttributeUpdated'), life: 3000 })
                getAllMenuItemAttributes()
            })
        }
    }
    const editMenuItemAttribute = (item) => {
        setShowProgressBar(true)
        menuItemAttributeService.get(item.id).then((data) => {
            setShowProgressBar(false)
            setMenuItemAttributeEdit(data)
            setMenuItemAttributeDialog(true);
        })
    }

    const confirmDeleteItemCagegory = (item) => {
        setMenuItemAttributeDelete(item);
        setDeleteMenuItemAttributeDialog(true);
    }

    const deleteMenuItemAttribute = () => {
        menuItemAttributeService.delete(menuItemAttributeDelete.id).then(data => {
            setMenuItemAttributeDelete(null);
            setDeleteMenuItemAttributeDialog(false);
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('menuItemAttributeDeleted'), life: 3000 });
            getAllMenuItemAttributes()
        })
    }

    const confirmDeleteSelected = () => {
        setDeleteMenuItemAttributesDialog(true);
    }

    async function deleteSelectedMenuItemAttribute() {
        await selectedMenuItemAttribute.forEach(element => {
            menuItemAttributeService.delete(element.id).then(data => {
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('menuItemAttributeDeleted'), life: 3000 });
            })
        });
        setDeleteMenuItemAttributesDialog(false);
        setSelectedMenuItemAttribute(null);
        getAllMenuItemAttributes()

    }
    const onPage = (event) => {
        let searchParams = searchMenuItemAttributeParams
        searchParams.pageNumber = event.page
        searchParams.pageSize = event.rows
        getAllMenuItemAttributes(searchParams)
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("new")} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label={t("delete")} icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedMenuItemAttribute || !selectedMenuItemAttribute.length} />
                </div>
            </React.Fragment>
        )
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("name")}</span>
                {rowData.name}
            </>
        );
    }
    const isPublicBodyTemplate = (rowData) => {
        return (
            <>
                <i className={rowData.isPublic ? 'pi pi-check' : 'pi pi-times'}/>
            </>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions mt-2 text-center">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editMenuItemAttribute(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteItemCagegory(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t("manageMenuItemAttributes")}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t("search")} />
            </span>
        </div>
    );

    const deleteMenuItemAttributeDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteMenuItemAttributeDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteMenuItemAttribute} />
        </>
    );
    const deletMenuItemAttributesDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteMenuItemAttributesDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedMenuItemAttribute} />
        </>
    );
    const paginationTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("previous")}</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("next")}</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'All', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        }
    };
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    <DataTable ref={dt} value={MenuItemAttributes} selection={selectedMenuItemAttribute} onSelectionChange={(e) => setSelectedMenuItemAttribute(e.value)}
                        dataKey="id" paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate={t('defaultPaginationMessage')} onPage={onPage}
                        globalFilter={globalFilter} emptyMessage={t('noItemsFound')} header={header} responsiveLayout="scroll" loading={loadingTable}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="name" header={t("name")} body={nameBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                        <Column field="isPublic" header={t("isPublic")} body={isPublicBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                    </DataTable>

                    <MenuItemAttributeForm menuItemAttributeDialog={menuItemAttributeDialog} saveMenuItemAttribute={saveMenuItemAttribute} updateMenuItemAttribute={updateMenuItemAttribute} hideDialog={hideDialog} menuItemAttributeToEdit={menuItemAttributeEdit} />
                    <Dialog visible={deleteMenuItemAttributeDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deleteMenuItemAttributeDialogFooter} onHide={hideDeleteMenuItemAttributeDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {menuItemAttributeDelete && <span>{t("areYouSureYouWantToDelete")} <b>{menuItemAttributeDelete.name}</b>?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={deleteMenuItemAttributesDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deletMenuItemAttributesDialogFooter} onHide={hideDeleteMenuItemAttributesDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {selectedMenuItemAttribute && <span>{t("areYouSureYouWantToDelete")}?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(MenuItemAttribute, comparisonFn);
