import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';

const StockItemModalForm = (props) => {
    const { stockItemDialog, hideDialog, saveStockItem, updateStockItem, stockItemToEdit } = props
    let defaultValues = {
        name: '',
        description: '',
        unit: '',
    }
    const { t } = useTranslation();

    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });

    useEffect(() => {
        if (stockItemToEdit?.name) {
            setValue('name', stockItemToEdit?.name)
        }
        if (stockItemToEdit?.description) {
            setValue('description', stockItemToEdit?.description)
        }
        if (stockItemToEdit?.id) {
            setValue('id', stockItemToEdit?.id)
        }
        if (stockItemToEdit?.unit) {
            setValue('unit', stockItemToEdit?.unit)
        }
    }, [stockItemToEdit])
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const submitFunction = (data) => {
        if (stockItemToEdit) {
            updateStockItem(data)
        } else {
            saveStockItem(data)
        }
        reset()
    }
    const onHideDialog = () => {
        hideDialog()
        reset()
    }
    const unitOptions = [
        {
            label: t("KG"), value:"KG"
        },
        {
            label: t("PSC"), value:"PSC"
        },
        {
            label: t("L"), value:"L"
        },
    ]
    const stockItemDialogFooter = (
        <div className='grid text-right'>
            <div className='col-offset-5 col-3'>
                <Button type="button" label={t('cancel')} icon="pi pi-times" className="p-button-text col-6" onClick={onHideDialog} />
            </div>
            <div className='col-3'>
                <Button label={t('save')} icon="pi pi-check" className="p-button-text col-6" type="submit" />
            </div>
        </div>
    );
    return (
        <Dialog visible={stockItemDialog} style={{ width: '450px' }} header={t('newStockItem')} modal className="p-fluid" onHide={onHideDialog}>
            <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                <div className="field">
                    <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>{t('name')}*</label>
                    <Controller name="name" control={control} rules={{ required: t('nameRequired') }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('name')}
                </div>
                <div className="field">
                    <label htmlFor="description" className={classNames({ 'p-error': errors.description })}>{t('description')}*</label>
                    <Controller name="description" control={control} rules={{ required: t('descriptionRequired') }} render={({ field, fieldState }) => (
                        <InputTextarea id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('description')}
                </div>
                <div className="field">
                        <label htmlFor="unit" className={classNames({ 'p-error': errors.cateringId })}>{t('unit')}*</label>
                        <Controller name="unit" control={control} rules={{ required: t('unitRequired') }} render={({ field, fieldState }) => (
                            <Dropdown id={field.name} {...field} options={unitOptions} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('unit')}
                    </div>
                {stockItemDialogFooter}
            </form>
        </Dialog>

    );
};

export default StockItemModalForm;