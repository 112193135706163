import React, { useContext, useEffect, useRef, useState } from "react";
import MenuService from "../../service/MenuService";
import OrderService from "../../service/OrderService";
import AuthContext from "../../context";
import { useTranslation } from "react-i18next";
import moment from 'moment'
import PublicOrderComponentSkeleton from "./PublicOrderComponentSkeleton";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { searchMenuParams } from "../../service/serviceObjects";
import { calculateOrderPrice, categorizeOrderMenuItems, filterMenuItemSequencesByCategory, getNumberOfMenuItemSequences, groupMenuByCategory, returnAllMenuItemsForOrder, truncateDescriptionString } from "../../utilities/menuItemUtil";
import { isMobile } from "../../utilities/general";
import { Toast } from "primereact/toast";
import { Ripple } from "primereact/ripple";
import { Divider } from "primereact/divider";
import CountdownTimer from "../../components/CountDownTimer";
import { CSSTransition } from "primereact/csstransition";
import { Dialog } from "primereact/dialog";
import { useSelector, useDispatch } from 'react-redux'
import { addNewOrderToList, resetOrders, setExistingOrders } from "../../store/orderSlice";
import { selectExistingOrdersByDate, selectNewOrders, selectNewOrdersByDate } from "../../store/orderSelectors";
import useOrderDataTaskUtil from "../../utilities/useOrderDataTaskUtil";
import { Tooltip } from 'primereact/tooltip';

const PublicOrderComponent = (props) => {
    const { currentDay, currentCateringId, setOrders, orderConfiguration, setDisplayLoader, goToPayment, otherUserId } = props
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [selectedShifts, setSelectedShifts] = useState([]);
    const [selectedItemCategory, setSelectedItemCategory] = useState(0);
    const [menuForCurrentDay, setMenuForCurrentDay] = useState([]);
    const [categorizedMenuForCurrentDay, setCategorizedMenuForCurrentDay] = useState([]);
    const [editCategory, setEditCategory] = useState();
    const [previewItem, setPreviewItem] = useState();
    const [editId, setEditId] = useState();
    const menuService = new MenuService();
    const orderService = new OrderService();
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);
    const messageToast = useRef(null);
    const contentRef = useRef(null);
    const linkRef = useRef(null);

    const orderUtil = useOrderDataTaskUtil()
    let existingOrderArrayForDate = useSelector(selectExistingOrdersByDate(currentDay))
    let orders = useSelector(selectNewOrders)
    let userId = authContext.id
    if (otherUserId) {
        userId = otherUserId
    }
    const dispatch = useDispatch()
    const isEditEnabled = (currentElement) => {
        if (currentElement?.dateActiveFrom && currentElement?.dateActiveTo) {
            return moment().utc().isBetween(moment(currentElement.dateActiveFrom).utc(), moment(currentElement.dateActiveTo).utc())
        }
        return false
    }
    let editEnabled = isEditEnabled(menuForCurrentDay) || authContext?.roleDTO.name.includes('admin')

    const getActiveMenus = (first, last) => {
        let searchObject = {
            ...searchMenuParams,
            minDate: moment(first, "DD/MM/YYYY").format("YYYY-MM-DD").toString(),
            maxDate: moment(last, "DD/MM/YYYY").format("YYYY-MM-DD").toString(),
            customerId: authContext?.customerId,
            cateringId: currentCateringId
        }
        if (authContext?.roleDTO.name.includes('catering') && authContext.cateringId) {
            searchObject.cateringId = authContext.cateringId
        }
        setShowProgressBar(true)
        menuService.search(searchObject).then(data => {
            setTimeout(() => {
                setShowProgressBar(false)
            }, 500);
            setMenuForCurrentDay(data?.items?.[0])
            if (data?.items?.[0]?.menuItemDTOList) {
                setCategorizedMenuForCurrentDay(groupMenuByCategory(data?.items?.[0]?.menuItemDTOList))
            }
        })
    }
    const deleteOrder = (id) => {
        setDisplayLoader(true)
        orderService.delete(id).then(data => {
            getActiveOrders(currentDay, currentDay)
            getActiveMenus(currentDay, currentDay)
            // setMenuForCurrentDay(data.items)
        }).catch(() => {

        }).finally(() => {
            setDisplayLoader(false)

        })
    }
    const cancelWIthRefund = (id) => {
        setDisplayLoader(true)
        orderService.cancel(id, true).then(data => {
            getActiveOrders(currentDay, currentDay)
            getActiveMenus(currentDay, currentDay)
            // setMenuForCurrentDay(data.items)
        }).catch(() => {

        }).finally(() => {
            setDisplayLoader(false)

        })
    }
    const getActiveOrders = (first, last) => {
        let searchObject = {
            minDate: moment(first, "DD/MM/YYYY").format("YYYY-MM-DD").toString(),
            maxDate: moment(last, "DD/MM/YYYY").format("YYYY-MM-DD").toString(),
            userId: userId,
            cateringId: currentCateringId

        }
        setShowProgressBar(true)
        orderService.search(searchObject).then(data => {
            setTimeout(() => {
                setShowProgressBar(false)
            }, 500);
            if (data?.items) {
                let dataFromServer = data.items.map(x => ({
                    ...x, date: moment(x.date, "YYYY-MM-DD").format("DD/MM/YYYY").toString()
                }))
                let selectedShiftData = dataFromServer.map(x => ({
                    date: x.date,
                    value: x.shift
                }))
                dispatch(setExistingOrders(dataFromServer))
                setSelectedShifts(selectedShiftData)
            }
            // setMenuForCurrentDay(data.items)
        })
    }
    useEffect(() => {
        setCategorizedMenuForCurrentDay([])
        setMenuForCurrentDay([])
        getActiveOrders(currentDay, currentDay)
        getActiveMenus(currentDay, currentDay)
        setEditCategory()
        setEditId()
    }, [currentDay, otherUserId])
    useEffect(() => {
        if (otherUserId) {
            dispatch(resetOrders())
        }
    }, [otherUserId])
    const onSelectItemCategory = (value) => {
        setSelectedItemCategory(value)
    }

    const returnOneMenuItem = (singleElement, mini, isEdit) => {
        return <div className="col-12 md:col-6 lg:col-3 mb-5 md:mb-0">
            <div className="p-2">
                <div className="relative mb-3">
                    <img src={singleElement.image ? "data:image/png;base64," + singleElement.image : "assets/layout/images/blank_menu_item.jpg"} className={mini ? "h-6rem w-full" : "h-10rem w-full"} style={{ resize: "horizontal", objectFit: 'cover' }} />
                    {(singleElement.isVegetarian || singleElement.isVegan || singleElement.isLenten) && <span className="bg-gray-50 text-gray-200 font-bold px-2 py-1 absolute" style={{ borderRadius: '1rem', left: '1rem', top: mini ? "0.1rem" : '1rem' }}>
                        <div className={`flex justify-content-left align-items-center ${mini ? "h-2rem min-h-2rem" : "h-3rem min-h-3rem"} `}>
                            <span className={`product-badge status`}>{singleElement.isVegetarian ? <img src={"assets/layout/images/vegetarian.jpg"} alt={singleElement.name} className={mini ? "h-1rem" : "h-2rem"} />
                                : <></>}</span>
                            <span className={`product-badge status`}>{singleElement.isVegan ? <img src={"assets/layout/images/vegan.png"} alt={singleElement.name} className={mini ? "h-1rem" : "h-2rem"} />
                                : <></>}</span>
                            <span className={`product-badge status`}>{singleElement.isLenten ? <img src={"assets/layout/images/posno.svg"} alt={singleElement.name} className={mini ? "h-1rem" : "h-2rem"} />
                                : <></>}</span>
                        </div>
                    </span>}
                    <span className="bg-gray-50 text-gray-200 font-bold px-2 py-1 absolute" style={{ borderRadius: '1rem', right: '1rem', bottom: mini ? "0.5rem" : '1rem' }}>
                        <span className="text-600">{singleElement?.price},00 RSD</span>

                    </span>
                    {editEnabled && <span className="bg-gray-50 text-gray-200 font-bold px-3 py-3 absolute stretch-button" style={{ borderBottomLeftRadius: '1rem', right: '0', top: '0', cursor: 'pointer' }} onClick={() => isEdit ? onEditItem(singleElement) : onAddItem(singleElement)}>
                        <Ripple />
                        <i className="pi pi-plus text-teal-400" />

                    </span>}
                    <span className="bg-gray-50 text-gray-200 font-bold px-3 py-3 absolute stretch-button" style={{ borderTopRightRadius: '1rem', left: '0', bottom: '0', cursor: 'pointer' }} onClick={() => setPreviewItem(singleElement)}>
                        <Ripple />
                        <i className="pi pi-info-circle text-blue-200" />

                    </span>
                </div>
                <div className="text-900 font-medium h-3rem" style={{ fontSize: singleElement?.name?.length > 30 ? '1em' : "1.3em" }}>{truncateDescriptionString(singleElement.name, 60)}</div>
                <div className="my-2 h-2rem" >
                    <span className="mr-3 h-full w-full" style={isMobile() ? { fontSize: '1em' } : { fontSize: "1em" }}>
                        {truncateDescriptionString(singleElement?.description)}
                    </span>
                    {/* <span className="text-500">12 reviews</span> */}
                </div>
            </div>
        </div>
    }
    const returnElementsForDate = (filterOutCategories) => {
        let edit = false
        if (filterOutCategories && categorizedMenuForCurrentDay) {
            edit = true
        }
        if (categorizedMenuForCurrentDay?.length > 0) {
            return <>

                <div className='grid col-12'>

                    {
                        categorizedMenuForCurrentDay.map(x => {
                            if ((!selectedItemCategory || selectedItemCategory == x.categoryName) && !filterOutCategories?.includes(x.categoryName)) {

                                return <div className='grid col-12 w-full' style={{ filter: !editEnabled ? 'opacity(30%) blur(2px)' : 'none' }}>

                                    <div className='col-12'>
                                        <div className="mt-1 font-bold text-left" style={isMobile() ? { fontSize: '7.2vw' } : { fontSize: '1.8vw' }}>{x.categoryName}</div>
                                    </div>
                                    <div className='col-12 w-full'>
                                        <div className="grid -mt-3 -ml-3 -mr-6">

                                            {x.elements.map(y => {

                                                return returnOneMenuItem(y, edit, edit)
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            }

                        })
                    }
                </div>
            </>

        } else {
            return noMenuComponent
        }
    }
    let itemCategoryOptions = [
    ]
    if (categorizedMenuForCurrentDay?.length > 0) {
        itemCategoryOptions = categorizedMenuForCurrentDay.map(x => ({
            value: x.categoryName, name: x.categoryName
        }))
    }
    const itemCategoryButtonTemplate = (option) => {
        return <div className='w-full'>
            <div className='col-12 text-center'>
                <img src={option.image ? "data:image/png;base64," + option.image : "assets/layout/images/blank_category.png"} alt={option.name} className="w-3rem" />

            </div>
            <div className='col-12 text-center'>
                <span className="font-semibold">{option.name}</span>
            </div>
            {/* <i className="pi pi-tag mr-2" /> */}
        </div>
    }
    const onAddItem = (element) => {
        if (getNumberOfMenuItemSequences(orders, currentDay, element.itemCategoryName) >= orderConfiguration.maxNumberOfOrdersForCategory) {
            showErrorMessage(element, orderConfiguration.maxNumberOfOrdersForCategory)
        } else {
            dispatch(addNewOrderToList({
                data: element,
                edit: false,
                currentDay: currentDay,
                userId: userId,
                customerId: authContext?.customerId,
                editId: editId
            }))
            showAddMessage(element)
        }
    }
    const onEditItem = (element) => {
        dispatch(addNewOrderToList({
            data: element,
            edit: true,
            currentDay: currentDay,
            userId: userId,
            customerId: authContext?.customerId,
            editId: editId
        }))
        showAddMessage(element)
    }
    const showAddMessage = (element) => {
        messageToast.current.show({
            sevetiy: "custom-8",
            className: "surface-900",
            contentClassName: "p-4",
            life: 3000,
            content: (
                <div className="flex flex-column bg-gray-900 w-full">
                    <div className="flex flex-row bg-gray-900 w-full">
                        <div className="flex align-items-center">
                            <img src={element.image ? "data:image/png;base64," + element.image : "assets/layout/images/blank_menu_item.jpg"} className="w-7rem" style={{ resize: "horizontal" }} />

                        </div>
                        <p className="my-3 mx-3 line-height-3 text-gray-300">
                            {element.name} {t('isAdded')}
                        </p>
                    </div>
                    <div style={{ height: "6px" }} className="bg-gray-700">
                        <div className="h-full bg-blue-500 animate-width animation-duration-3000"></div>
                    </div>
                </div>
            ),
        });
    };
    const showErrorMessage = (element, maxNumber) => {
        messageToast.current.show({
            sevetiy: "warn",
            className: "surface-900",
            contentClassName: "p-4",
            life: 3000,
            content: (
                <div className="flex flex-column bg-orange-900 w-full">
                    <div className="flex flex-row bg-orange-900 w-full">
                        <i className="pi pi-exclamation-triangle text-4xl text-grey-300 my-2 mx-2" style={{ color: 'gray' }} />

                        <p className="my-2 mx-3 line-height-3 text-gray-300">
                            {element.itemCategoryName} {t('alreadyAdded')}
                        </p>
                    </div>
                    <div className="">
                        <p className="my-2 mx-3 line-height-3 text-gray-300">
                            {t('maxNumberOfOrderPerCategory')} : {maxNumber}
                        </p>
                    </div>
                    <div style={{ height: "6px" }} className="bg-gray-700">
                        <div className="h-full bg-blue-500 animate-width animation-duration-3000"></div>
                    </div>
                </div>
            ),
        });
    };
    const noMenuComponent = (
        <div className="col-12 w-full">
            <div className="shadow-2 flex flex-wrap justify-content-between align-content-center px-3 md:px-8" style={{ border: 1, borderRadius: '8px' }}>
                <div className="flex align-items-start py-6">
                    <div>
                        <span className="inline-flex border-circle justify-content-center align-items-center text-white bg-orange-400 mr-4" style={{ width: '38px', height: '38px' }}>
                            <i className="pi pi-times text-xl"></i>
                        </span>
                    </div>
                    <div>
                        <div className="text-500 text-2xl font-bold mt-2">{t('noMenu')}</div>
                    </div>
                </div>

            </div>
        </div>

    )
    const orderPreviewHeader = (existingOrderForDate) => {
        return <div className="surface-card grid grid-nogutter border-round shadow-2">
            <div className="col-12 flex p-2 surface-100 border-round-top">
                <div className="p-2 flex-auto text-center md:text-left">
                    <span className="text-700 block">{t("orderNumber")}</span>
                    <span className="text-900 font-medium block mt-2">{existingOrderForDate?.transactionNumber ?? existingOrderForDate?.id}</span>
                </div>
                <Divider align="center" layout="vertical" className="h-full  mx-0 lg:mx-3 surface-border" />
                <div className="p-2 flex-auto text-center md:text-left">
                    <span className="text-700 block">{t("orderDate")}</span>
                    <span className="text-900 font-medium block mt-2">{existingOrderForDate?.date}</span>
                </div>
                <Divider align="center" layout="vertical" className="h-full  mx-0 lg:mx-3 surface-border" />
                <div className="p-2 flex-auto text-center md:text-left">
                    <span className="text-700 block">{t('totalAmount')}</span>
                    <span className="text-900 font-medium block mt-2">{calculateOrderPrice(returnAllMenuItemsForOrder([existingOrderForDate]))},00 RSD</span>
                </div>
                <Divider align="center" layout="vertical" className="h-full  mx-0 lg:mx-3 surface-border" />
                <div className="p-2 flex flex-column md:flex-row justify-content-center text-center md:text-left align-items-center">
                    <span className={`${(existingOrderForDate.status == "CREATED" || existingOrderForDate.status == "PAID" || existingOrderForDate.status == "VERIFIED") ? 'bg-green-500' : 'bg-orange-500'} bg-green-500 text-white flex align-items-center justify-content-center border-circle mr-2`} style={{ width: "2rem", height: "2rem" }}>
                        <i className={`${(existingOrderForDate.status == "CREATED" || existingOrderForDate.status == "PAID" || existingOrderForDate.status == "VERIFIED") ? 'pi pi-check' : 'pi pi-clock'}`}></i>
                    </span>
                    <span className={`${(existingOrderForDate.status == "CREATED" || existingOrderForDate.status == "PAID" || existingOrderForDate.status == "VERIFIED") ? 'text-green-500' : 'text-orange-500'}`}>{t(existingOrderForDate.status)}</span>
                </div>
            </div>
        </div>
    }
    const orderPreviewComponent = () => {
        let existingOrderForDate = existingOrderArrayForDate?.[0]
        let otherCategories = orderUtil.getItemCategoryNamesFromOrder(existingOrderForDate) || []
        return <div className="surface-ground px-0 py-3 md:px-6 lg:px-8">
            {orderPreviewHeader(existingOrderForDate)}
            {returnItemsByOrder(existingOrderArrayForDate)}
            {orderConfiguration?.orderPaymentType != "WITH_PAYMENT" && editEnabled && <div className="col-12 p-card p-shadow-3 p-3 mx-1 md:mx-5 lg:mx-5 xl:mx-5 mb-2">
                <div className="flex align-items-center border-bottom-1 surface-border p-2">
                    <i className="pi pi-plus text-2xl mr-3 text-500"></i>
                    <span className="text-xl font-medium text-900">{t("addOtherItems")}</span>
                </div>
                {returnElementsForDate(otherCategories)}
            </div>}

            {editEnabled && <div className="col-12 p-0 flex border-top-1 surface-border">
                {orderConfiguration?.orderPaymentType != "WITH_PAYMENT" && <a tabIndex="0" className="cursor-pointer py-4 flex flex-column md:flex-row md:flex-row text-center justify-content-center align-items-center font-medium text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full" style={{ borderBottomLeftRadius: '6px' }} onClick={() => setEditId(existingOrderForDate.id)}><i className="pi pi-pencil  mr-2 mb-2 md:mb-0"></i>{t('edit')}</a>
                }
                {existingOrderForDate?.status == "PAYMENT_IN_PROGRESS" && <a tabIndex="0" className="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center font-medium text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full" onClick={() => {
                    goToPayment(calculateOrderPrice(returnAllMenuItemsForOrder(existingOrderArrayForDate)), existingOrderArrayForDate)
                }}><i className="pi pi-credit-card  mr-2 mb-2 md:mb-0"></i>{t('goToPayment')}</a>
                }
                <Tooltip position="top" content={t('creditDetails')} target={linkRef} />

                {existingOrderForDate?.status == "PAID" ? <a tabIndex="0" className="cancel-target cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center font-medium text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full" ref={linkRef} onClick={() => cancelWIthRefund(existingOrderForDate?.id)}><i className="pi pi-times  mr-2 mb-2 md:mb-0"></i>{t('cancelWithRefund')}</a>
                    : <a tabIndex="0" className="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center font-medium text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full" onClick={() => deleteOrder(existingOrderForDate?.id)}><i className="pi pi-trash  mr-2 mb-2 md:mb-0"></i>{t('remove')}</a>

                }
            </div>}
        </div>
    }
    const returnItemsByOrder = (existingOrderForDate) => {
        let items
        let categorizedMenuItemsForOrder = categorizeOrderMenuItems(existingOrderForDate?.[0])
        let menuForCategory = categorizedMenuForCurrentDay.filter(z => z.categoryName == editCategory)
        items = categorizedMenuItemsForOrder.map(x => {
            return <div className='grid col-12 w-full'>

                <div className='col-12'>
                    <div className="pt-1">
                        <div className="flex align-items-center justify-content-between flex-row pb-2 pt-2 border-bottom-1 surface-border">
                            <div className="flex align-items-center">
                                <i className="pi pi-inbox text-2xl mr-3 text-500"></i>
                                <span className="text-3xl font-medium text-900">{x.categoryName}</span>
                            </div>
                            <div className="">
                                {editEnabled && orderConfiguration?.orderPaymentType != "WITH_PAYMENT" && <Button label={t("edit")} icon="pi pi-pencil" onClick={() => setEditCategory(x.categoryName)} />}
                            </div>
                        </div>
                    </div>
                </div>


                <div className='col-12 w-full'>
                    <div className="grid">
                        {returnOrderForCategory(x.menuItems)}
                    </div>
                </div>
                <div className="col-12 flex-column p-card p-shadow-3 p-p-3 mx-1 md:mx-5 lg:mx-5 xl:mx-5"
                    ref={contentRef}
                    style={{
                        minHeight: editCategory == x.categoryName ? "auto" : 0,
                        opacity: editCategory == x.categoryName ? 1 : 0,
                        overflow: 'hidden',
                        transition: 'max-height 0.6s ease-in-out, opacity 0.6s ease-in-out'
                    }}
                >
                    {editCategory == x.categoryName && <div className='col-12' >
                        <div className="pt-1">
                            <div className="flex align-items-center justify-content-between flex-row pb-2 pt-2 border-bottom-1 surface-border">
                                <div className="flex align-items-center">
                                    <i className="pi pi-sync text-2xl mr-3 text-500"></i>
                                    <span className="text-xl font-medium text-900">{t("chooseMenuItem")}</span>
                                </div>
                                <div className="">
                                    <Button className="p-button-text" icon="pi pi-times" onClick={() => setEditCategory()} />
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className='col-12 grid'>

                        {(categorizedMenuForCurrentDay?.length > 0 && editCategory == x.categoryName) && categorizedMenuForCurrentDay.find(z => editCategory == z.categoryName)?.elements?.map(y => {
                            return returnOneMenuItem(y, true, true)

                        })}
                    </div>
                </div>
            </div>
        })
        return items
    }
    const returnOrderForCategory = (menuItemSequences) => {
        let items
        if (menuItemSequences.length > 0) {
            items = menuItemSequences.map(x => {
                let element = x.menuItemDTO;

                return <div className="col-12">
                    <div className="p-2 my-1 flex flex-column lg:flex-row justify-content-between align-items-center">
                        <div className="flex flex-column lg:flex-row justify-content-center align-items-center px-2">
                            <img src={element.imagePreview ? "data:image/png;base64," + element.imagePreview : "assets/layout/images/blank_menu_item.jpg"} alt="product" className="h-6rem w-10rem mr-3 flex-shrink-0" style={{ resize: "horizontal", objectFit: 'cover' }} />
                            <div className="flex flex-column my-auto text-center md:text-left">
                                <span className="text-900 font-medium mb-3 mt-3 lg:mt-0">{element.name}</span>

                                <span tabIndex="0" className="p-ripple p-2 w-12rem mx-auto lg:mx-0 border-round font-medium text-center border-1 border-primary text-primary transition-duration-150"><span className="font-light"> {element.price},00 RSD</span>
                                    <Ripple />
                                </span>
                            </div>
                        </div>
                        <div className="bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center" style={{ borderRadius: '2.5rem' }}>

                            {(element.isVegetarian || element.isVegan || element.isLenten) && <span className="bg-gray-50 text-gray-200 font-bold px-" style={{ borderRadius: '1rem', left: '1rem', top: '1rem' }}>
                                <div className='flex justify-content-left align-items-center h-3rem min-h-3rem'>
                                    <span className={`product-badge status`}>{element.isVegetarian ? <img src={"assets/layout/images/vegetarian.jpg"} alt={element.name} className="h-2rem" />
                                        : <></>}</span>
                                    <span className={`product-badge status`}>{element.isVegan ? <img src={"assets/layout/images/vegan.png"} alt={element.name} className="h-2rem" />
                                        : <></>}</span>
                                    <span className={`product-badge status`}>{element.isLenten ? <img src={"assets/layout/images/posno.svg"} alt={element.name} className="h-2rem" />
                                        : <></>}</span>
                                </div>
                            </span>}
                        </div>
                    </div>
                </div>
            })
        }
        return items
    }
    let existingSelectedOrderForDate = existingOrderArrayForDate?.[0];

    return (
        <>
            <Toast key="block8" style={{ width: "30rem" }} ref={messageToast} />
            {previewItem && <Dialog visible={previewItem} appendTo="self" modal breakpoints={{ '960px': '75vw', '640px': '90vw' }} style={{ width: '700px' }} showHeader={false} contentStyle={{ padding: '2rem 2rem 1rem 2rem' }}>
                <div className="grid relative">
                    <div className="col-12 lg:col-6 text-center">
                        <img src={previewItem.image ? "data:image/png;base64," + previewItem.image : "assets/layout/images/blank_menu_item.jpg"} className={"h-20rem max-h-20rem w-full"} style={{ resize: "vertical", objectFit: 'cover' }} />
                    </div>
                    <div className="col-12 lg:col-6 py-0 lg:pl-5">
                        <div className="flex justify-content-end">
                            <Button className="absolute lg:static p-button-text p-button-plain p-button-rounded" icon="pi pi-times pi-2xl" onClick={() => setPreviewItem()} style={{ right: '0rem', top: '0rem' }} />
                        </div>
                        <div className="flex align-items-center justify-content-between mb-3">
                            <span className="text-xl font-medium text-900">{previewItem.name}</span>
                        </div>
                        <div className="flex align-items-center justify-content-between mb-3">
                            <div className="text-xl text-900">{previewItem.price},00 RSD</div>
                            <div className='flex justify-content-left align-items-center h-3rem min-h-3rem'>
                                <span className={`product-badge status`}>{previewItem.isVegetarian ? <img src={"assets/layout/images/vegetarian.jpg"} alt={previewItem.name} className="h-2rem" />
                                    : <></>}</span>
                                <span className={`product-badge status`}>{previewItem.isVegan ? <img src={"assets/layout/images/vegan.png"} alt={previewItem.name} className="h-2rem" />
                                    : <></>}</span>
                                <span className={`product-badge status`}>{previewItem.isLenten ? <img src={"assets/layout/images/posno.svg"} alt={previewItem.name} className="h-2rem" />
                                    : <></>}</span>
                            </div>
                        </div>
                        <p className="p-0 mt-0 mb-3 line-height-3 text-700">
                            {previewItem.description}
                        </p>
                        {previewItem?.menuItemAttributeDTOList?.length > 0 &&
                            <ul className="list-none p-0 m-0">
                                {previewItem?.menuItemAttributeDTOList.map(x => {
                                    if (x.isPublic) {
                                        return <li className="flex align-items-center mb-2">
                                            <i className="pi pi-check-circle text-green-500 mr-2"></i>
                                            <span className="text-900 text-sm">{x.name}</span>
                                        </li>
                                    }
                                })}
                            </ul>


                        }


                    </div>
                </div>
            </Dialog >}
            {
                showProgressBar ? <PublicOrderComponentSkeleton /> :
                    <div className="col-12 grid">
                        <div className="col-12 md:col-10 lg:col-10 xm:col-12 text-center ml-0 md:ml-6 lg:ml-6 xl:ml-6">
                            {categorizedMenuForCurrentDay?.length > 0 && (editEnabled ? <CountdownTimer targetDate={menuForCurrentDay?.dateActiveTo} />
                                : <div className="flex align-items-start p-4 bg-yellow-100 border-round border-1 border-yellow-300">
                                    <i className="pi pi-exclamation-triangle text-yellow-900 text-2xl mr-3"></i>
                                    <div className="mr-3">
                                        <div className="text-yellow-900 font-medium text-xl mb-2 line-height-1">{t("orderTimeExpired")}</div>

                                    </div>
                                </div>
                            )}
                        </div>
                        {<div className="col-12 md:col-10 lg:col-10 xm:col-12 ml-0 md:ml-6 lg:ml-6 xl:ml-6">
                            {editId && existingSelectedOrderForDate && orderPreviewHeader(existingSelectedOrderForDate)}

                        </div>}
                        {(existingSelectedOrderForDate && !editId) && <div className="col-12 md:col-10 lg:col-10 xm:col-12 mx-2 md:mx-5 lg:mx-5 xl:mx-5">

                            {orderPreviewComponent()}
                        </div>}

                        {(!existingSelectedOrderForDate || editId) && <div className='col-12 ml-5' style={{ filter: !editEnabled ? 'blur(2px)' : 'none' }}>
                            <SelectButton disabled={!editEnabled} className='category-select-button' optionLabel="name" value={selectedItemCategory} itemTemplate={itemCategoryButtonTemplate} options={itemCategoryOptions} onChange={(e) => onSelectItemCategory(e.value)}></SelectButton>

                        </div>}
                        {(!existingSelectedOrderForDate || editId) && <div className='col-12'>
                            <div className="surface-section px-4 py-3 md:px-6 lg:px-8 sm:align-items-center">

                                <div className="grid -mt-3 -ml-3 -mr-3">
                                    {returnElementsForDate()}
                                </div>
                            </div>
                        </div>}
                    </div>
            }
        </>
    );
}

export default PublicOrderComponent;