import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const CountdownTimer = ({ targetDate }) => {
    const { t } = useTranslation()
    const calculateTimeLeft = () => {
        const now = new Date();
        const target = new Date(targetDate);
        const difference = target - now;

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer); // Clean up the timer on component unmount
    }, [targetDate]);

    // Render countdown in a readable format
    const renderCountdown = () => {
        return (
            <div className="flex lg:align-items-start flex-column lg:flex-row p-4 bg-blue-100 border-round border-1 border-blue-300 w-full">
                <div className="flex align-items-start">
                    <i className="pi pi-info-circle text-blue-900 text-2xl mr-3 mt-1"></i>
                    <div className="mr-3 flex align-items-center lg:align-items-start flex-column lg:flex-row">
                        <div className="text-blue-900 font-medium mr-0 lg:mr-2 mb-2 lg:mb-0 line-height-3 text-2xl">{t("ordersOpenMessage")}</div>
                        <div className="flex">
                            <span className="text-3xl mr-2">{timeLeft.days || 0}d </span>
                            <span className="text-3xl mr-2">{timeLeft.hours || 0}h </span>
                            <span className="text-3xl mr-2">{timeLeft.minutes || 0}m </span>
                            <span className="text-3xl">{timeLeft.seconds || 0}s</span>
                        </div>
                    </div>
                </div>

            </div>

        );
    };

    return (
        <div>
            <div>{renderCountdown()}</div>
        </div>
    );
};

export default CountdownTimer;