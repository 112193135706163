import React from 'react';

const AppFooter = () => {
    return (
        <div className="layout-footer">
            <div className="grid">
                <div className="col-6">
                    <button type="button" className="p-link logo-container" onClick={() => window.location = "/"}>
                        <img src="assets/layout/images/LOGO.svg" alt="babylon-layout" />
                    </button>
                </div>
                <div className="col-6 footer-icons">
                    <button type="button" className="p-link" onClick={() => window.location = "/"}>
                        <i className="pi pi-home"></i>
                    </button>
                    <a type="button" target="_blank" className="p-link" href='https://www.instagram.com/gfood.app'>
                        <i className="pi pi-instagram"></i>
                    </a>
                </div>
            </div>
        </div >
    );
}

export default AppFooter;
