import { Button } from 'primereact/button';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { adminRole } from '../constants/auth';
import AuthContext from '../context';
import CateringTask from '../pages/Catering/CateringTask';
import OverviewService from '../service/OverviewService';
import UserService from '../service/UserService';
import { searchUserParams } from '../service/serviceObjects';


const Dashboard = () => {
    const [cateringCount, setCateringCount] = useState(0);
    const [customersCount, setCustomersCount] = useState(0);
    const [usersCount, setUsersCount] = useState(0);
    const [ordersCount, setOrdersCount] = useState(0);
    const [adminUsers, setAdminUsers] = useState([]);
    const authContext = useContext(AuthContext);

    const overviewService = new OverviewService();
    const userService = new UserService();
    const { t } = useTranslation();
    let history = useHistory();

    useEffect(() => {

        let searchCountData = {
            customers: true,
            caterings: true,
            users: true,
            orders: true

        }
        overviewService.getCountOfEntities(searchCountData).then(data => {
            if (data.cateringCount) {
                setCateringCount(data.cateringCount)
            }
            if (data.customersCount) {
                setCustomersCount(data.customersCount)
            }
            if (data.usersCount) {
                setUsersCount(data.usersCount)
            }
            if (data.ordersCount) {
                setOrdersCount(data.ordersCount)
            }
        });
        let searchUserObject = { ...searchUserParams }
        searchUserObject.roleName = adminRole
        userService.search(searchUserObject).then(data => {
            setAdminUsers(data.items)
        })
    }, []);

    return (
        <div className="layout-dashboard">
            <div className="grid">
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="overview-box sales">
                        <i className="overview-icon pi pi-palette"></i>
                        <span className="overview-title">{t("caterings")}</span>
                        <i className="overview-arrow pi pi-chevron-circle-up"></i>
                        <div className="overview-numbers">
                            {cateringCount}
                        </div>
                        <div className="overview-subinfo">
                            <Button label={t('view')} className='p-button-rounded p-button-info' icon="pi pi-eye" onClick={() => history.push("/catering")} />
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="overview-box views">
                        <i className="overview-icon pi pi-building"></i>
                        <span className="overview-title">{t("companies")}</span>
                        <i className="overview-arrow pi pi-chevron-circle-up"></i>
                        <div className="overview-numbers">
                            {customersCount}
                        </div>
                        <div className="overview-subinfo">
                        <Button label={t('view')} className='p-button-rounded p-button-info' icon="pi pi-eye" onClick={() => history.push("/customers")} />
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="overview-box users">
                        <i className="overview-icon pi pi-users"></i>
                        <span className="overview-title">Users</span>
                        <i className="overview-arrow pi pi-chevron-circle-up"></i>
                        <div className="overview-numbers">
                            {usersCount}
                        </div>
                        <div className="overview-subinfo">
                        <Button label={t('view')} className='p-button-rounded p-button-info' icon="pi pi-eye" onClick={() => history.push("/users")} />
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="overview-box checkin">
                        <i className="overview-icon pi pi-plus"></i>
                        <span className="overview-title">Orders</span>
                        <i className="overview-arrow pi pi-chevron-circle-up"></i>
                        <div className="overview-numbers">
                            {ordersCount}
                        </div>
                        <div className="overview-subinfo">
                        <Button label={t('view')} className='p-button-rounded p-button-info' icon="pi pi-eye" onClick={() => history.push("/orders")} />
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className='grid'>
                        <div className='col-12 lg:col-8'>
                            <div className="card card-w-title tasks">
                                <h5>{t("caterings")}</h5>
                                <CateringTask />
                            </div>
                        </div>
                        <div className="col-12 lg:col-4">
                            <div className='grid'>
                                <div className='col-12'>
                                    <div className="user-card card">
                                        <div className="user-card-header">
                                            <img src="assets/layout/images/dashboard/bg-header.png" alt="babylon-layout" className="profile-image" />
                                        </div>
                                        <div className="user-card-content">
                                            <img src="assets/layout/images/user-avatar.png" alt="babylon-layout" />
                                            <div className="user-card-name">
                                                <span>{authContext?.name}</span>
                                            </div>

                                            <div className="user-detail">
                                                <ul>
                                                    <li className="clearfix">
                                                        <i className="pi pi-user"></i>
                                                        <span className="project-title">{t("username")}</span>
                                                        <span className="project-detail">{authContext?.username}</span>
                                                    </li>
                                                    <li className="clearfix">
                                                        <i className="pi pi-user"></i>
                                                        <span className="project-title">{t("role")}</span>
                                                        <span className="project-detail">{authContext?.roleDTO.description}</span>
                                                    </li>
                                                    <li className="clearfix">
                                                        <i className="pi pi-envelope"></i>
                                                        <span className="project-title">{t("email")}</span>
                                                        <span className="project-detail">{authContext?.email}</span>
                                                    </li>
                                                    <li className="clearfix">
                                                        <i className="pi pi-phone"></i>
                                                        <span className="project-title">{t("phone")}</span>
                                                        <span className="project-detail">{authContext?.phone}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="card card-w-title team">
                                        <h5>{t("team")}</h5>

                                        <ul>
                                            {
                                                adminUsers?.map(x => {
                                                    return <li>
                                                        <img src="assets/layout/images/user-avatar.png" alt="babylon-layout" />
                                                        <div className="team-box">
                                                            <span className="team-member">{x.name}</span>
                                                            <span className="team-member-role">{x.roleDTO.name}</span>
                                                        </div>
    
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(Dashboard, comparisonFn);
