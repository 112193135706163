import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../context';
import MenuOrder from './MenuOrder';
const CustomUserOrder = (props) => {
    const { history } = props
    const authContext = useContext(AuthContext);

    const [user, setUser] = useState([]);
    const [showOrderClient, setShowOrderClient] = useState();
    let userId
    if (history?.location?.state?.id) {
        userId = history.location.state.id
    } else if (authContext.id) {
        userId = authContext.cateringId
    }
    useEffect(() => {
        if (history?.location?.state?.id) {
            setUser(history.location.state)
            setShowOrderClient(true)
        }
    }, [history?.location?.state?.id])

    return (
        <div className="card grid align-items-center surface-0">

            {showOrderClient && <div className='col-12'>
                <div className='card'>
                    <div className='col-12'>
                        <div className='flex justify-content-center'>
                            <i className='pi pi-user text-xl mr-2 mt-1'></i>
                            <span><h4>{user?.name}</h4></span>
                        </div>
                    </div>
                    <MenuOrder userData={{ id: userId }} />
                </div>
            </div>}

        </div>
    );
}

export default CustomUserOrder;