import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {classNames} from 'primereact/utils';
import React, {useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import AuthService from '../../service/AuthService';
import UserService from '../../service/UserService';
import {Toast} from "primereact/toast";
import {setUserData} from "../../store/userSlice";
import {hideLoader, showLoader} from "../../store/uiSlice";
import {useDispatch} from "react-redux";
import CaptchaComponent from "../../components/auth/CaptchaComponent";

const RegisterPublicUser = (props) => {
    const {onSuccess, cateringId, customerId} = props
    const dispatch = useDispatch()
    const [checkUsernameInProgress, setCheckUsernameInProgress] = useState(false)
    const [captchaOk, setCaptchaOk] = useState(false)

    const authService = new AuthService()
    const userService = new UserService()
    const toast = useRef(null);

    let defaultValues = {
        name: '',
        username: '',
        email: '',
        phone: '',
        password: '',
        passwordAgain: '',
        cateringId: cateringId ?? '',
        customerId: customerId ?? '',
        roleId: '',
        personalNumber: '',
        cardNumber: '',
        location: '',
        notifyUser: ''
    }

    const {t} = useTranslation();
    const {control, formState: {errors}, handleSubmit, reset, setValue, setError, clearErrors, getValues} = useForm({defaultValues, mode: 'all'});

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const submitFunction = (data) => {
        // todo submit succeeds even though password is not entered??
        if (data) {
            dispatch(showLoader())

            // todo password is visible in network?
            let submitRequest = {...data}
            submitRequest.customerId = customerId
            submitRequest.status = "REGISTERED"

            // todo hardcode 5 (confirmed by Coa)
            submitRequest.roleDTO = {
                id: 6
            }

            authService.registerUser(submitRequest).then((value) => {
                dispatch(setUserData({username: data?.username}));
                reset()
                onSuccess()
            }).catch(reason => {
                toast.current.show({severity: 'error', summary: t('error'), detail: t('errorDuringPublicUserRegistration'), life: 3000})
            }).finally(() => {
                dispatch(hideLoader())
            })
        }
    }
    const checkUsername = (event) => {
        clearErrors('username')
        setCheckUsernameInProgress(true)
        userService.isUnique(event.target.value).then(() => {
            setError('username', {type: 'custom', message: t("usernameExists")})
            setCheckUsernameInProgress(false)
        }).catch(() => {
            setCheckUsernameInProgress(false)
        })
    }
    const checkPasswordAgain = (event) => {
        clearErrors('passwordAgain')
        let values = getValues('password')
        if (event.target.value !== values) {
            setError('passwordAgain', {type: 'custom', message: t("passwordNotMatch")})
        }
    }
    const formButtons = (
        <div className='grid text-center'>
            <div className='col-offset-9 col-3'>
                <Button label={t('register')} icon="pi pi-check" className="p-button-text col-6" type="submit"/>
            </div>
        </div>
    );

    return (
        <div className="flex justify-content-center login-page">
            <Toast ref={toast}/>
            <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                <div className='grid'>
                    <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                        <div className="field">
                            <label htmlFor="name" className={classNames({'p-error': errors.name})}>{t('name')}*</label>
                            <Controller name="name" control={control} rules={{required: t('nameRequired')}} render={({field, fieldState}) => (
                                <InputText id={field.name} {...field} className={classNames({'p-invalid': fieldState.invalid})} autoFocus/>
                            )}/>
                            {getFormErrorMessage('name')}
                        </div>
                    </div>
                    <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                        <div className="field">
                            <label htmlFor="username" className={classNames({'p-error': errors.username})}>{t('username')}*</label>
                            <Controller name="username" control={control} rules={{
                                required: t('usernameRequired'), pattern: {
                                    value: /^[a-z0-9@._-]{4,100}$/,
                                    message: t('invalidUsername')
                                }
                            }} render={({field, fieldState}) => (
                                <div className="p-inputgroup">
                                    <InputText id={field.name} {...field} className={classNames({'p-invalid': fieldState.invalid})} onBlur={(event) => checkUsername(event)} tooltip={t("usernameTooltip")}/>
                                    {checkUsernameInProgress && <span className="p-inputgroup-addon"><i className="pi pi-spin pi-spinner" style={{'fontSize': '2em'}}></i></span>}
                                </div>
                            )}/>
                            {getFormErrorMessage('username')}
                        </div>
                    </div>

                    <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                        <div className="field">
                            <label htmlFor="email" className={classNames({'p-error': errors.email})}>{t('email')}*</label>
                            <Controller name="email" control={control} rules={{
                                required: t('emailRequired'),
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: t('invalidEmailFormat')
                                }
                            }} render={({field, fieldState}) => (
                                <InputText id={field.name} {...field} className={classNames({'p-invalid': fieldState.invalid})}/>
                            )}/>
                            {getFormErrorMessage('email')}
                        </div>
                    </div>
                    <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                        <div className="field">
                            <label htmlFor="personalNumber" className={classNames({'p-error': errors.personalNumber})}>{t('personalNumber')}</label>
                            <Controller name="personalNumber" control={control} render={({field, fieldState}) => (
                                <InputText id={field.name} {...field} className={classNames({'p-invalid': fieldState.invalid})}/>
                            )}/>
                            {getFormErrorMessage('personalNumber')}
                        </div>
                    </div>
                    <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                        <div className="field">
                            <label htmlFor="phone" className={classNames({'p-error': errors.phone})}>{t('phone')}</label>
                            <Controller name="phone" control={control} rules={{
                                pattern: {
                                    value: /^\+[1-9]{1}[0-9]{3,14}$/i,
                                    message: t('invalidPhoneFormat')
                                }
                            }} render={({field, fieldState}) => (
                                <InputText id={field.name} {...field} className={classNames({'p-invalid': fieldState.invalid})}/>
                            )}/>
                            {getFormErrorMessage('phone')}
                        </div>
                    </div>
                    <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>

                        <div className="field">
                            <label htmlFor="password" className={classNames({'p-error': errors.password})}>{t('password')}*</label>
                            <Controller name="password" control={control} rules={{required: t('passwordRequired')}} render={({field, fieldState}) => (
                                <Password id={field.name} {...field} toggleMask className={classNames({'p-invalid': fieldState.invalid})}/>
                            )}/>
                            {getFormErrorMessage('password')}
                        </div>
                    </div>
                    <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>

                        <div className="field">
                            <label htmlFor="passwordAgain" className={classNames({'p-error': errors.passwordAgain})}>{t('passwordAgain')}*</label>
                            <Controller name="passwordAgain" control={control} rules={{required: t('passwordAgainRequired')}} render={({field, fieldState}) => (
                                <Password id={field.name} {...field} feedback={false} toggleMask className={classNames({'p-invalid': fieldState.invalid})} onBlur={(event) => checkPasswordAgain(event)}/>
                            )}/>
                            {getFormErrorMessage('passwordAgain')}
                        </div>
                    </div>
                    <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 mt-2'>
                        <CaptchaComponent onSuccess={() => setCaptchaOk(true)}/>
                    </div>
                </div>
                {captchaOk && formButtons}
            </form>
        </div>

    );
};

export default RegisterPublicUser;
