export const paymentData = [
    {
        cateringId:8,
        provider:"RF",
        merchantID:"219671142033FFD",
        terminalID:"2033001D"
    },
    {
        cateringId:6,
        provider:"RF",
        merchantID:"219671142033FFD",
        terminalID:"2033001D"
    },
]