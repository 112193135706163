import axios from 'axios';
import { BLCUrl, defaultHeader } from './serviceUtil';
const resourceName = "/license"

export default class LicenseService {
    save(requestBody) {
        return axios.post(BLCUrl + resourceName, requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    update(id, requestBody) {
        return axios.put(BLCUrl + resourceName + "/" + id, requestBody, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    search(searchData) {
        let searchParams = "?"
        if (searchData?.licenseKey) {
            searchParams += "licenseKey=" + searchData.licenseKey + "&"
        }
        if (searchData?.cateringId) {
            searchParams += "cateringId=" + searchData.cateringId + "&"
        }
        if (searchData?.status) {
            searchParams += "status=" + searchData.status + "&"
        }
            searchParams += "pageNumber=" + searchData.pageNumber
        if (searchData?.pageSize) {
            searchParams += "&pageSize=" + searchData.pageSize
        }
        return axios.get(BLCUrl + resourceName + searchParams, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    get(id) {
        return axios.get(BLCUrl + resourceName + "/" + id, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    generateLicense() {
        return axios.get(BLCUrl + resourceName + "/generate", { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    delete(id) {
        return axios.delete(BLCUrl + resourceName + "/" + id, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
}
