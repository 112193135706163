import { createSelector } from '@reduxjs/toolkit';

// Selector to get the `newOrders` from the state
export const selectNewOrders = (state) => state?.orderSlice?.newOrders ?? [];

// Selector to get `newOrders` by a specific date
export const selectNewOrdersByDate = (date) => 
  createSelector([selectNewOrders], (newOrders) => 
    newOrders.filter(order => order.date === date)
  );
// Selector to get the `newOrders` from the state
export const selectExistingOrders = (state) => state?.orderSlice?.existingOrders ?? [];

// Selector to get `newOrders` by a specific date
export const selectExistingOrdersByDate = (date) => 
  createSelector([selectExistingOrders], (existingOrders) => 
    existingOrders.filter(order => order.date === date)
  );