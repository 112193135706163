import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Ripple } from 'primereact/ripple';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../context';
// import { searchCustomersParams } from '../../service/serviceObjects';
import CustomerService from '../../service/CustomerService';
import { searchCustomerParams } from '../../service/serviceObjects';
import CustomerFormDialog from './CustomerForm';
// import AddCustomersForm from './AddCustomersForm';
const Customers = (props) => {
    const { customSearchData } = props;
    const [newCustomersDialog, setNewCustomersDialog] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loadingTable, setLoadingTable] = useState(false);
    const [customerToEdit, setCustomerToEdit] = useState(null)
    const [deleteCustomersDialog, setDeleteCustomersDialog] = useState(false);
    const [customersToDelete, setCustomersToDelete] = useState(null)
    const history = useHistory();
    const authContext = useContext(AuthContext);

    let cateringId = 0
    if (history?.location?.state?.id) {
        cateringId = history.location.state.id
    } else if (authContext.cateringId) {
        cateringId = authContext.cateringId
    }
    const { t } = useTranslation();
    const toast = useRef(null);
    const dt = useRef(null);
    const customersService = new CustomerService();
    useEffect(() => {
        getAllCustomers()
    }, [])
    const openNew = () => {
        setNewCustomersDialog(true);
    }
    const onPage = (event) => {
        let searchParams = searchCustomerParams
        searchParams.pageNumber = event.page
        searchParams.pageSize = event.rows
        getAllCustomers(searchParams)
    }
    const saveCustomers = (data) => {
        if (data) {
            let submitRequest = { ...data }
            if (cateringId) {
                submitRequest.customerCateringConfigurationDTO = {
                    numberOfShifts: 1,
                    numberOfDaysBeforeMenuIsActive: 7,
                    numberOfDaysBeforeMenuIsNotActive: 2
                }
                submitRequest.cateringDTOS = [{
                    id: cateringId
                }]
            }
            customersService.save(submitRequest).then((value) => {
                setNewCustomersDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('customersAdded'), life: 3000 })
                getAllCustomers()
            }).catch(reason => {
                setNewCustomersDialog(false)
                toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorSavingCustomers'), life: 3000 })
            })
        }
    }
    const updateCustomers = (data) => {
        if (data) {
            let submitRequest = { ...data }
            if (data.roleId) {
                submitRequest.roleDTO = {
                    id: data.roleId
                }
            }
            customersService.update(customerToEdit.id, submitRequest).then((value) => {
                setNewCustomersDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('customersUpdated'), life: 3000 })
                getAllCustomers()
            })
        }
    }
    const getAllCustomers = (searchData) => {
        setLoadingTable(true)
        let searchObject = searchCustomerParams
        if (searchData) {
            searchObject = { ...searchCustomerParams, ...searchData }
        }
        if (customSearchData) {
            searchObject = { ...searchObject, ...customSearchData }
        }
        searchObject.cateringId = cateringId

        customersService.search(searchObject).then(data => {
            setFirst(data.pageNumber * data.pageSize);
            setRows(data.pageSize);
            setTotalRecords(data.itemCount)
            setCustomers(data.items)
            setLoadingTable(false)
        })

    }
    const editCustomers = (item) => {
        setShowProgressBar(true)
        customersService.get(item.id).then((data) => {
            setShowProgressBar(false)
            setCustomerToEdit(data)
            setNewCustomersDialog(true);
        })
    }
    const hideDialog = () => {
        if (customerToEdit) {
            setCustomerToEdit()
        }
        setNewCustomersDialog(false);
    }
    const hideDeleteCustomersDialog = () => {
        setDeleteCustomersDialog(false);
    }
    const confirmDeleteCustomers = (item) => {
        setCustomersToDelete(item);
        setDeleteCustomersDialog(true);
    }
    async function deleteSelectedCustomers() {
        customersService.delete(customersToDelete.id).then(data => {
            setCustomersToDelete(null);
            setDeleteCustomersDialog(false);
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('customersDeleted'), life: 3000 });
            getAllCustomers()
        })
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("new")} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    }
    const paginationTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("previous")}</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("next")}</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ customersSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: t('all'), value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        }
    };
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t("manageCustomers")}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t("search")} />
            </span>
        </div>
    )
    const customersNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("name")}</span>
                {rowData.name}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("email")}</span>
                <span className={`status`}>{rowData.email}</span>
            </>
        )
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions mt-2 text-center">
                <Button icon="pi pi-eye" className="p-button-rounded p-button-info mr-2" onClick={() => history.push({
                    pathname: '/customerOverview',
                    state: { ...rowData }
                })} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editCustomers(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning " onClick={() => confirmDeleteCustomers(rowData)} />
            </div>
        );
    }
    const deleteCustomersDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteCustomersDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCustomers} />
        </>
    )
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    <DataTable ref={dt} value={customers} selection={selectedCustomers} onSelectionChange={(e) => setSelectedCustomers(e.value)}
                        dataKey="id" paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate={t('defaultPaginationMessage')} onPage={onPage}
                        globalFilter={globalFilter} emptyMessage={t('noItemsFound')} header={header} responsiveLayout="scroll" loading={loadingTable}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="name" header={t("name")} body={customersNameBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                        <Column field="email" header={t("email")} body={emailBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                    </DataTable>
                    <CustomerFormDialog customerDialog={newCustomersDialog} saveCustomer={saveCustomers} updateCustomer={updateCustomers} hideDialog={hideDialog} customerToEdit={customerToEdit} />
                    <Dialog visible={deleteCustomersDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deleteCustomersDialogFooter} onHide={hideDeleteCustomersDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {customersToDelete && <span>{t("areYouSureYouWantToDelete")} <b>{customersToDelete.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default Customers;