import React, { useEffect, useState } from 'react'

const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT','SUN'];
export const Number = ({value = 0}) => {
    const result = String(value).padStart(2, "0");
    return (
        <div className='digital'>
          <p>88</p>
          <p>{result}</p>
        </div>
    )
  }
export const Word = ({ value, hidden = false }) => {
    const getStyle = ()=> {
      return {
        visibility:  hidden ? 'hidden' : 'visible'
      }
    }
    return (
      <div className='digital'>
        <p>{value}</p>
        <p style={getStyle()}>{value}</p>
      </div>
    )
  }
export const Clock = ({ h24 = true }) => {

    const [hour, setHour] = useState(0);
    const [minute, setMinute] = useState(0);
    const [second, setSecond] = useState(0);
    const [day, setDay] = useState(0);
    useEffect(() => {

        const update = () => {
            const date = new Date();
            let hour = date.getHours();
            if (!h24) {
                hour = (hour % 12) || 12;
            }
            setHour(hour);
            setMinute(date.getMinutes());
            setSecond(date.getSeconds());
            setDay(date.getDay());
        }

        update();

        const interval = setInterval(() => {
            update();
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className='clock'>
        <div className='calendar'>
            {
                days.map((value, index)=>(<Word key={value} value={value} hidden={index + 1 !== day}/>))
            }
        </div>
        <div className='row'>
            <div className='hour'>
                <Number value={hour}/>
                <Word value={':'} />
                <Number value={minute}/>
                <Word value={':'} />
                <Number value={second}/>
            </div>
        </div>
    </div>
    )

}