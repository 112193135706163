import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const ShareMenuFormModal = (props) => {
    const { shareMenuDialog, hideDialog, shareMenu, updateSharedMenu, sharedMenuToEdit, menuToShare, initialMenuData, disableMenuDate, myCustomers } = props
    let defaultValues = {
        comment: '',
        menuDate: null,
        activeMenuValidityDate: null,
    }

    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });
    const { t } = useTranslation();

    useEffect(() => {
        if (sharedMenuToEdit?.id) {
            setValue('id', sharedMenuToEdit?.id)
        }
        if (sharedMenuToEdit?.comment) {
            setValue('comment', sharedMenuToEdit?.comment)
        }
        if (sharedMenuToEdit?.menuDate) {
            setValue('menuDate', sharedMenuToEdit?.menuDate)
        }
        if (initialMenuData?.menuDate) {
            setValue('menuDate', initialMenuData?.menuDate)
        }

        if (sharedMenuToEdit?.activeMenuValidityDate) {
            setValue('activeMenuValidityDate', sharedMenuToEdit?.activeMenuValidityDate)
        }
    }, [sharedMenuToEdit])
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const submitFunction = (data) => {
        if (sharedMenuToEdit) {
            updateSharedMenu(data)
        } else {
            shareMenu(data)
        }
        reset()
    }
    const onHideDialog = () => {
        hideDialog()
        reset()
    }
    const shareMenuDialogFooter = (
        <div className='grid text-right'>
            <div className='col-offset-5 col-3'>
                <Button type="button" label={t("cancel")} icon="pi pi-times" className="p-button-text col-6" onClick={onHideDialog} />
            </div>
            <div className='col-3'>
                <Button label={t("save")} icon="pi pi-check" className="p-button-text col-6" type="submit" />
            </div>
        </div>
    );
    let customersOptions = []
    if (myCustomers?.length > 0) {
        customersOptions = myCustomers.map(x => ({
            value: x.id, label: x.name
        }))
    }
    return (
        <Dialog visible={shareMenuDialog} style={{ width: '450px' }} header={t("shareMenu") + menuToShare.name} modal className="p-fluid" onHide={onHideDialog}>
            <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                <div className="field">
                    <label htmlFor="customer" className={classNames({ 'p-error': errors.customer })}>{t('customer')}*</label>
                    <Controller name="customer" control={control} rules={{ required: t('customerRequired') }} render={({ field, fieldState }) => (
                        <Dropdown id={field.name} {...field} options={customersOptions} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('customer')}
                </div>
                <div className="field">
                    <label htmlFor="comment" className={classNames({ 'p-error': errors.description })}>{t("comment")}*</label>
                    <Controller name="comment" control={control} rules={{ required: t("commentRequired") }} render={({ field, fieldState }) => (
                        <InputTextarea id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('comment')}
                </div>
                <div className="field">
                    <label htmlFor="menuDate" className={classNames({ 'p-error': errors.name })}>{t("menuDate")}*</label>
                    <Controller name="menuDate" control={control} rules={{ required: t("dateRequired") }} render={({ field, fieldState }) => (
                        <Calendar id={field.name} disabled={disableMenuDate} value={field.value} onChange={(e) => field.onChange(e.value)} showIcon />)} />
                    {getFormErrorMessage('menuDate')}
                </div>
                <div className="field">
                    <label htmlFor="activeMenuValidityDate" className={classNames({ 'p-error': errors.name })}>{t("menuValidityDate")}*</label>
                    <Controller name="activeMenuValidityDate" control={control} rules={{ required: t("dateRequired") }} render={({ field, fieldState }) => (
                        <Calendar id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)} selectionMode="range" readOnlyInput showTime showIcon />)} />
                    {getFormErrorMessage('activeMenuValidityDate')}
                </div>
                {shareMenuDialogFooter}
            </form>
        </Dialog>

    );
};

export default ShareMenuFormModal;