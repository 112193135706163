import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../context';

const FilterStockForm = (props) => {
    const { searchStock, customersDropdownList } = props
    let defaultValues = {
        amount: '',
        stockItemId: '',
        customerId: '',
        minDate: '',
        maxDate: ''
    }
    // const stockItemService = new StockItemService();


    const { t } = useTranslation();
    const authContext = useContext(AuthContext);

    const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });


    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const submitFunction = (data) => {
        searchStock(data)
    }

    useEffect(() => {


    }, [])

    return (
        <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
            <div className='grid'>
                <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="customerId" className={classNames({ 'p-error': errors.customer })}>{t('customer')}</label>
                    <Controller name="customerId" control={control} render={({ field, fieldState }) => (
                        <Dropdown id={field.name} showClear {...field} options={customersDropdownList} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('customer')}
                </div>
                <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="minDate" className={classNames({ 'p-error': errors.minDate })}>{t('minDate')}*</label>
                    <Controller name="minDate" control={control} rules={{ required: t('minDateRequired') }} render={({ field, fieldState }) => (
                        <Calendar id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)} showIcon />)} />
                    {getFormErrorMessage('minDate')}
                </div>
                <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="maxDate" className={classNames({ 'p-error': errors.maxDate })}>{t('maxDate')}*</label>
                    <Controller name="maxDate" control={control} rules={{ required: t('maxDateRequired') }} render={({ field, fieldState }) => (
                        <Calendar id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)} showIcon />)} />
                    {getFormErrorMessage('maxDate')}
                </div>
                <div className='col-12 md:col-12 lg:col-2'>
                    <Button label={t("search")} icon="pi pi-search" className="p-button mt-4" type="submit" />
                </div>
            </div>

        </form>
    );
};

export default FilterStockForm;