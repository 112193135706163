import moment from "moment"
export function isDateInEditRange(date, minDay, minTime, maxDay, maxTime, ignoreMin, daily) {
    let firstDayOfWeek = moment(date, "DD/MM/YYYY").isoWeekday(1).utc().toDate();
    let dateActiveFrom = moment(firstDayOfWeek, "DD/MM/YYYY").isoWeekday(1).subtract(minDay ?? 7, 'day').format("YYYY-MM-DD").toString() + "T" + minTime
    let dateActiveTo = moment(firstDayOfWeek, "DD/MM/YYYY").isoWeekday(1).subtract(maxDay ?? 2, 'day').format("YYYY-MM-DD").toString() + "T" + maxTime
    if (daily) {
        dateActiveTo = moment(date, "DD/MM/YYYY").subtract(1, 'day').format("YYYY-MM-DD").toString() + "T"+ maxTime
    }
    if (ignoreMin) {
        console.log(moment(dateActiveTo).utc());
        return moment().utc().isBefore(moment(dateActiveTo).utc())
    }
    return moment().utc().isBetween(moment(dateActiveFrom).utc(), moment(dateActiveTo).utc())


}