import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Password } from 'primereact/password';
import { ProgressBar } from 'primereact/progressbar';
import { SelectButton } from 'primereact/selectbutton';
import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import enImage from "../../assets/flags/en.jpg";
import srbImage from "../../assets/flags/srb.png";
import { JWT_NAME } from '../../constants/auth';
import i18n from '../../i18n';
import AuthService from '../../service/AuthService';
import externalAuthService from '../../service/ExternalAuthService';
import { createCookie, getCookie } from '../../utilities/general';
import MicrosoftAuthComponent from './MicrosoftAuthComponent';
import { Divider } from 'primereact/divider';
import Carousel from 'primereact/carousel'
import { Ripple } from 'primereact/ripple';
const LoginComponentNew = () => {
    const authService = new AuthService();
    const [showProgressBar, setShowProgressBar] = useState(false)
    const [errorMessage, seErrorMessage] = useState()
    const [langValue, setLangValue] = useState(getCookie('lang') || "srb");
    const [azureLogin, setAzureLogin] = useState(false)
    let defaultValues = {
        username: '',
        password: '',
        rememberMe: '',
    }
    const { control, formState: { errors }, handleSubmit, setError, reset } = useForm({ defaultValues: defaultValues, mode: 'submit' });
    useEffect(() => {
        reset()
    }, [azureLogin])
    const submitFunction = (data) => {
        seErrorMessage()
        setShowProgressBar(true)
        authService.login(data).then(response => {
            createCookie(JWT_NAME, response.accessToken, 1)
            window.location = "/"
        }).catch((error) => {
            if (error?.response?.data === "LICENSE_NOT_VALID") {
                seErrorMessage(error?.response?.data)
            } else {
                setError('username', { type: 'custom', message: 'Bad credentials' });
                setError('password', { type: 'custom', message: 'Bad credentials' });
            }

        }).finally(() => {
            setShowProgressBar(false)

        })
    }
    const submitAzureFunction = (data, customerId) => {
        seErrorMessage()
        setShowProgressBar(true)
        let userData = {
            name: data.name,
            username: data.username,
            email: data.username,
            customerId: customerId ?? ''
        }
        authService.loginExternal(userData).then(response => {
            createCookie(JWT_NAME, response.accessToken, 1)
            window.location = "/"
        }).catch((error) => {
            if (error?.response?.data === "LICENSE_NOT_VALID") {
                seErrorMessage(error?.response?.data)
            } else {
                seErrorMessage("errorWithExternalLogin")

            }

        }).finally(() => {
            setShowProgressBar(false)

        })
    }
    const langOptions = [
        { img: srbImage, value: 'srb' },
        { img: enImage, value: 'en' },
    ];
    const { t } = useTranslation();

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const changeLanguage = (lng) => {
        createCookie('lang', lng)
        setLangValue(lng)
        i18n.changeLanguage(lng);
    }
    const langTemplate = (option) => {
        return <img src={option.img} alt={"langImage"} width="20" height="10" />;

    }
    const fetchUser = async (authData) => {
        const user = await externalAuthService.login(authData.clientId, authData.tenantId);
        if (user) {
            submitAzureFunction(user, authData.customerId)
        }
    };
    return (
        <div className="surface-ground px-4 py-8 md:px-6 lg:px-8">
            <div className="flex flex-wrap shadow-2">
                <div className="w-full lg:w-6 px-0 py-4 lg:p-7 bg-blue-50">
                    <img src="assets/layout/images/LOGO.svg" alt="Image" height="50" className="mb-6" />
                    {/* <Carousel value={[]} itemTemplate={(feature) =>
                            <div className="text-center mb-8">
                                <img src={`/demo/images/blocks/illustration/${feature.image}`} alt="Image" className="mb-6 w-6" />
                                <div className="mx-auto font-medium text-xl mb-4 text-blue-900">{feature.title}</div>
                                <p className="m-0 text-blue-700 line-height-3">{feature.text}</p>
                            </div>} /> */}
                </div>
                <div className="w-full lg:w-6 p-4 lg:p-7 surface-card">
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    {errorMessage && <Message severity="error" text={t(errorMessage)} className='w-full' />}
                    <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                        <div className="flex align-items-center justify-content-between mb-7">
                            <span className="text-2xl font-medium text-900">Login to Bastion</span>
                            <a tabIndex="0" className="font-medium text-blue-500 hover:text-blue-700 cursor-pointer transition-colors transition-duration-150">Sign up</a>
                        </div>
                        <div className="flex justify-content-between">
                            <button className="p-ripple mr-2 w-6 font-medium border-1 surface-border surface-100 py-3 px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center">
                                <i className="pi pi-facebook text-indigo-500 mr-2"></i>
                                <span>Sign in With Facebook</span>
                                <Ripple />
                            </button>
                            <button className="p-ripple ml-2 w-6 font-medium border-1 surface-border surface-100 py-3 px-2 p-component hover:surface-200 active:surface-300 text-900 cursor-pointer transition-colors transition-duration-150 inline-flex align-items-center justify-content-center">
                                <i className="pi pi-google text-red-500 mr-2"></i>
                                <span>Sign in With Google</span>
                                <Ripple />
                            </button>
                        </div>
                        <Divider align="center" className="my-4">
                            <span className="text-600 font-normal text-sm">OR</span>
                        </Divider>
                        <div className="field">
                            <label htmlFor="username" className={classNames({ 'p-error': errors.name })}>{t('username')}*</label>
                            <Controller name="username" control={control} rules={{ required: t('usernameRequired') }} render={({ field, fieldState }) => (
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid }, 'p-3')} />
                            )} />
                            {getFormErrorMessage('username')}
                        </div>

                        <div className="field">
                            <label htmlFor="password" className={classNames({ 'p-error': errors.name })}>{t('password')}*</label>
                            <Controller name="password" control={control} rules={{ required: t('passwordRequired') }} render={({ field, fieldState }) => (
                                <Password id={field.name} {...field} feedback={false} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} />
                            )} />
                            {getFormErrorMessage('password')}
                        </div>
                        <div className="flex align-items-center justify-content-between mb-6">
                            <div className="flex align-items-center">
                                {/* <Checkbox id="rememberme" className="mr-2" checked={checked4} onChange={(e) => rem(e.checked)} /> */}
                                <label htmlFor="rememberme">Remember me</label>
                            </div>
                            <a className="font-medium text-blue-500 hover:text-blue-700 cursor-pointer transition-colors transition-duration-150">Forgot password?</a>
                        </div>

                        <Button label="Sign In" className="w-full py-3 font-medium" />
                    </form>
                </div>
            </div>
        </div>

        //     <MicrosoftAuthComponent errorMessage={errorMessage} showDialog={azureLogin} loginFunction={fetchUser} onBack={() => { setAzureLogin(false) }} />
        // </div>
    )
}

export default LoginComponentNew;
