import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LicenseService from '../../service/LicenseService';
import { searchLicenseParams } from '../../service/serviceObjects';
import moment from 'moment'
import { Message } from 'primereact/message';
const CateringLicenseInfo = (props) => {
    const { cateringId } = props
    const [licenseData, setLicenseData] = useState()
    const [noLicense, setNoLicense] = useState(false)
    const { t } = useTranslation();
    const licenseService = new LicenseService();
    const getLicense = () => {
        licenseService.search({
            ...searchLicenseParams,
            cateringId: cateringId
        }).then(data => {
            if (data?.items?.[0]) {
                setLicenseData(data?.items?.[0])
            } else {
                setNoLicense(true)
            }
        })
    }
    useEffect(() => {
        getLicense()
    }, [])
    const returnClass = (status) => {
        if (status === "VALID") {
            return "text-green-400"
        } else if (status === "EXPIRED") {
            return "text-orange-300"

        } else if (status === "SUSPENDED") {
            return "text-red-300"
        }
    }
    return (
        <div className='grid'>
            <div className='col-12 mt-3'>
                <h4>{t('licenseInfo')}</h4>
            </div>
            {licenseData && <>
                <div className='col-4 p-3 border-bottom-1 border-300 '><i className='pi pi-key text-lg'></i> {t('licenseKey')}</div>
                {licenseData?.licenseKey && <div className='col-8 font-bold p-3 border-bottom-1 border-300 text-400'>{licenseData?.licenseKey}</div>}
                <div className='col-4 p-3 border-bottom-1 border-300'><i className='pi pi-question-circle text-lg'></i> {t('status')}</div>
                {licenseData?.status && <div className={'col-8 font-bold p-3 border-bottom-1 border-300 ' + returnClass(licenseData?.status)} >{t(licenseData?.status)}</div>}
                <div className='col-4 p-3 border-bottom-1 border-300'><i className='pi pi-calendar text-lg'></i> {t('expiryDate')}</div>
                {licenseData?.dateActiveTo && <div className='col-8 font-bold p-3 border-bottom-1 border-300'>{moment(licenseData.dateActiveTo).format("DD/MM/YYYY").toString()}</div>}

            </>}
            {noLicense && <Message severity="warn" text={t('noValidLicense')} className='w-full' />}


        </div>
    )
}


export default CateringLicenseInfo