import { OverlayPanel } from 'primereact/overlaypanel';
import { Badge } from 'primereact/badge';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useRef } from 'react';
import { Button } from 'primereact/button';
import MenuService from '../../../service/MenuService';
import { Controller, useForm } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../context';
import { SelectButton } from 'primereact/selectbutton';

const MenuOverlayPanel = (props) => {
    const { selectedMenuItems, removeAllMenuItemsFromMenu, removeMenuItemFromMenu, onSuccessSaveMenu } = props
    let defaultValues = {
        name: '',
        paymentMethod:'cash'
    }
    const { t } = useTranslation();
    const paymentMethodOptions = [
        { img: 'assets/layout/images/credit-card.png', value: 'card', disabled: true },
        { img: 'assets/layout/images/cash.png', value: 'cash' },
    ];
    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
    const authContext = useContext(AuthContext);

    const op = useRef(null);
    const menuService = new MenuService();
    const saveMenu = (data) => {

    }
    const paymentMethodTemplate = (option) => {
        return <img src={option.img} alt={"langImg"} className='ml-4' width="60" height="auto" /> ;

    }
    const menuOverlayFooter = () => {
        return (
            <React.Fragment>
                <div className="my-2 flex">
                    <Button type='submit' label={t('placeOrder')} icon="pi pi-wallet" className="p-button-success mr-2" />
                    <Button type='button' label={t('removeAll')} icon="pi pi-trash" className="p-button-danger" onClick={removeAllMenuItemsFromMenu} />
                </div>
            </React.Fragment>
        )
    }
    const imageBody = (rowData) => {
        return <img src={"data:image/png;base64," + rowData.image} alt={rowData.name} className="shadow-2" width="100" />
    }
    const priceTemplate = (rowData) => {
        return <span>{parseFloat(rowData.price).toFixed(2)} RSD</span>
    }
    const deleteItemBody = (rowData) => {
        return <Button icon="pi pi-minus" className="p-button-rounded p-button-danger p-button-outlined" aria-label="Cancel" onClick={() => removeMenuItemFromMenu(rowData)} />
    }
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const getAmount = () => {
        let amount = 0;
        if (selectedMenuItems.length > 0) {
            selectedMenuItems.forEach(element => {
                amount = amount + element.price
            });
        }
        return amount
    }
    const footerTemplate = () => {
        return <div className='flex'>
            <span>{t('amountPrice')}</span> : <span>{parseFloat(getAmount()).toFixed(2)} RSD</span>
        </div>
    }
    return (
        <div className="block mt-2 md:mt-0 p-input-icon-left">
            <button className="p-link" onClick={(e) => op.current.toggle(e)}>
                <i className="pi pi-shopping-cart mr-4 p-text-secondary p-overlay-badge" style={{ fontSize: '2rem' }}>{selectedMenuItems?.length > 0 && <Badge value={selectedMenuItems.length} ></Badge>}</i>
            </button>
            <OverlayPanel breakpoints={{ '960px': '75vw', '640px': '97vw' }} ref={op} showCloseIcon id="overlay_panel" style={{ width: '450px' }} className="overlaypanel-demo">
                <h5>{t('orderDetails')}</h5>
                {
                    selectedMenuItems?.length > 0 ? <>
                        <form onSubmit={handleSubmit(saveMenu)} className="p-fluid">
                            {/* <div className="field">
                                <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>{t('name')}*</label>
                                <Controller name="name" control={control} rules={{ required: t('nameRequired') }} render={({ field, fieldState }) => (
                                    <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                {getFormErrorMessage('name')}
                            </div> */}
                            <div className="field offset:col-2 col-8">
                                <label htmlFor="paymentMethod" className={classNames({ 'p-error': errors.paymentMethod })}>{t('paymentMethod')}*</label>
                                <Controller name="paymentMethod" control={control} rules={{ required: t('paymentMethodRequired') }} render={({ field, fieldState }) => (
                                    <SelectButton options={paymentMethodOptions} itemTemplate={paymentMethodTemplate} id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                                )} />
                                {getFormErrorMessage('paymentMethod')}
                            </div>
                            <DataTable header={false} value={selectedMenuItems} footer={footerTemplate}>
                                <Column body={imageBody} />
                                <Column field="name" />
                                <Column field="price" body={priceTemplate} />
                                <Column field="action" body={deleteItemBody} />
                            </DataTable>

                            {menuOverlayFooter()}
                        </form>
                    </>
                        : <h6>{t('noItemsSelected')}</h6>
                }

            </OverlayPanel>
        </div>
    );
};

export default MenuOverlayPanel;