import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UserService from '../../service/UserService';

const ChangePasswordForm = (props) => {
    const { userData } = props
    const userService = new UserService();

    let defaultValues = {
        password: '',
        passwordAgain: ''

    }
    const { t } = useTranslation();

    const { control, formState: { errors }, handleSubmit, setError, clearErrors, getValues } = useForm({ defaultValues });


    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const submitFunction = (data) => {
        data.status = "CONFIRMED"
        userService.confirmAccount(userData.id, data).then((value) => {
            window.location = "/"
        })
    }

    const checkPasswordAgain = (event) => {
        clearErrors('passwordAgain')
        let values = getValues('password')
        if (event.target.value !== values) {
            setError('passwordAgain', { type: 'custom', message: 'Password again doesn not match with passwrod' })

        }
    }
    const formButtons = (
        <div className='grid text-center'>
            <div className='col-offset-9 col-3'>
                <Button label={t('save')} icon="pi pi-check" className="p-button-text col-6" type="submit" />
            </div>
        </div>
    );

    return (
        <div className="flex align-items-center justify-content-center login-page h-screen">
            <div className="surface-card p-4 m-2 shadow-2 border-round w-full lg:w-3">
                <div className="text-center mb-5">
                    <img src="assets/layout/images/LOGO.svg" alt="hyper" height={50} className="mb-3" />
                    <div className="text-900 text-3xl font-medium mb-3">{t("confirmPassword")}</div>
                </div>

                <div>
                    {/* {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>} */}

                    <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                        <div className="field">
                            <label htmlFor="password" className={classNames({ 'p-error': errors.name })}>{t('password')}*</label>
                            <Controller name="password" control={control} rules={{ required: t('passwordRequired') }} render={({ field, fieldState }) => (
                                <Password id={field.name} {...field} feedback={true} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} />
                            )} />
                            {getFormErrorMessage('password')}
                        </div>
                        <div className="field">
                            <label htmlFor="passwordAgain" className={classNames({ 'p-error': errors.passwordAgain })}>{t('passwordAgain')}*</label>
                            <Controller name="passwordAgain" control={control} rules={{ required: t('passwordAgainRequired') }} render={({ field, fieldState }) => (
                                <Password id={field.name} {...field} feedback={false} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} onBlur={(event) => checkPasswordAgain(event)} />
                            )} />
                            {getFormErrorMessage('passwordAgain')}
                        </div>

                        {formButtons}
                    </form>
                </div>

            </div>
        </div>
    )
};

export default ChangePasswordForm;