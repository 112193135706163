import React from 'react';
import UserTable from "../Users/UserTable";

const CustomerUsers = (props) => {
    const { customerId, customerData } = props
    const customSearchData = {
        customerId: customerId
    }
    return (
        <UserTable customSearchData = {customSearchData} customerData={customerData}/>
    );
}

export default CustomerUsers;