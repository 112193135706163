import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from '../context';
import CustomerUsers from '../pages/Customer/CustomerUsers';


const DashboardCustomerAdmin = () => {

    const authContext = useContext(AuthContext);

    const { t } = useTranslation();
    return (
        <div className="layout-dashboard">
            <div className="grid">

                <div className="col-12">
                    <div className='grid'>
                        <div className='col-12 lg:col-8'>
                            <div className="card card-w-title tasks">
                                <h5>{t('users')}</h5>
                                <CustomerUsers customerId={authContext.customerId} />
                            </div>
                        </div>
                        <div className="col-12 lg:col-4">
                            <div className='grid'>
                                <div className='col-12'>
                                    <div className="user-card card">
                                        <div className="user-card-header">
                                            <img src="assets/layout/images/dashboard/bg-header.png" alt="babylon-layout" className="profile-image" />
                                        </div>
                                        <div className="user-card-content">
                                        <img src="assets/layout/images/user-avatar.png" alt="babylon-layout" />

                                            <div className="user-card-name">
                                                <span>{authContext?.name}</span>
                                            </div>

                                            <div className="user-detail">
                                                <ul>
                                                    <li className="clearfix">
                                                        <i className="pi pi-user"></i>
                                                        <span className="project-title">{t('username')}</span>
                                                        <span className="project-detail">{authContext?.username}</span>
                                                    </li>
                                                    <li className="clearfix">
                                                        <i className="pi pi-user"></i>
                                                        <span className="project-title">{t('role')}</span>
                                                        <span className="project-detail">{authContext?.roleDTO.description}</span>
                                                    </li>
                                                    <li className="clearfix">
                                                        <i className="pi pi-envelope"></i>
                                                        <span className="project-title">{t('email')}</span>
                                                        <span className="project-detail">{authContext?.email}</span>
                                                    </li>
                                                    <li className="clearfix">
                                                        <i className="pi pi-phone"></i>
                                                        <span className="project-title">{t('phone')}</span>
                                                        <span className="project-detail">{authContext?.phone}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(DashboardCustomerAdmin, comparisonFn);
