import { configureStore } from '@reduxjs/toolkit';
import orderSlice from './orderSlice';
import uiSlice from './uiSlice';
import userDataSlice from './userSlice';

export const store = configureStore({
  reducer: {
    orderSlice:orderSlice,
    uiSlice:uiSlice,
    userData: userDataSlice
  },
});