import { Skeleton } from 'primereact/skeleton';
import React from 'react';
import { isMobile } from '../../utilities/general';

const PublicOrderComponentSkeleton = () => {
    return (
        <div className="p-4">
            <div className="grid mb-3">
                <div className='col-12 flex'>
                    <Skeleton width="7rem" height='7rem' className="mr-5"></Skeleton>
                    <Skeleton width="7rem" height='7rem' className="mr-5"></Skeleton>
                    {!isMobile() && <Skeleton width="7rem" height='7rem' className="mr-5"></Skeleton>}
                    {!isMobile() && <Skeleton width="7rem" height='7rem' className="mr-5"></Skeleton>}
                </div>
                <div className='col-12 grid mt-5'>

                    <Skeleton width="15rem" height='15rem' className="ml-8 md:ml-0 lg:ml-0 xl:ml-0 mr-5 mt-2 col-3"></Skeleton>
                    <Skeleton width="15rem" height='15rem' className="ml-8 md:ml-0 lg:ml-0 xl:ml-0 mr-5 mt-2 col-3"></Skeleton>
                    <Skeleton width="15rem" height='15rem' className="ml-8 md:ml-0 lg:ml-0 xl:ml-0 mr-5 mt-2 col-3"></Skeleton>
                    {!isMobile() && <Skeleton width="15rem" height='15rem' className="mr-5 mt-2 col-3"></Skeleton>}
                    {!isMobile() && <Skeleton width="15rem" height='15rem' className="mr-5 mt-2 col-3"></Skeleton>}
                    {!isMobile() && <Skeleton width="15rem" height='15rem' className="mr-5 mt-2 col-3"></Skeleton>}
                    {!isMobile() && <Skeleton width="15rem" height='15rem' className="mr-5 mt-2 col-3"></Skeleton>}
                    {!isMobile() && <Skeleton width="15rem" height='15rem' className="mr-5 mt-2 col-3"></Skeleton>}
                </div>
            </div>
        </div>
    );
}

export default PublicOrderComponentSkeleton;