import { Messages } from 'primereact/messages';
import { Password } from 'primereact/password';
import { ProgressBar } from 'primereact/progressbar';
import { classNames } from 'primereact/utils';
import { BlockUI } from 'primereact/blockui';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import OrderService from '../../service/OrderService';
import AuthContext from '../../context';
import moment from 'moment';
import { Stomp } from '@stomp/stompjs';
import useWebSocket from 'react-use-websocket';
const WS_URL = 'wss://catering-ui-test-f2e99de59fcf.herokuapp.com';
const OrderVerification = () => {
    const { t } = useTranslation();
    const orderService = new OrderService();
    const [orderToDisplay, setOrderToDisplay] = useState();
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [hideInput, setHideInput] = useState(false);
    const [connectedToServer, setConnectedToServer] = useState(false);
    let defaultValues = {
        cardNumber: ''
    }
    const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });
    const msgs1 = useRef(null);
    const authContext = useContext(AuthContext);
    let audio = new Audio("assets/sound/verify-sound.mp3");
    const { sendJsonMessage } = useWebSocket(WS_URL, {
        onOpen: () => {
            setConnectedToServer(true)
            sendJsonMessage({ clientId: authContext.cateringId })
            console.log('WebSocket connection established.');
        },
        onClose: () => {
            setConnectedToServer(false)
            console.log('WebSocket connection closed.');
        },
        shouldReconnect: () => true,
        reconnectInterval: 5000,
        reconnectAttempts: 10,
        onMessage: (message) => {
            audio.play()
            if (message.data == "NO_USER_FOUND") {
                setHideInput(true)
                msgs1.current.show({ severity: 'error', detail: (t('cardOrUserIsNotValid')), sticky: true });
            } else {
                const body = JSON.parse(message.data);
                setOrderToDisplay(body)
            }
            finishReading()
        }
    });
    useEffect(() => {
        // Send ping message every 30 seconds
        const pingInterval = setInterval(() => {
          if (sendJsonMessage) {
            sendJsonMessage({ type: 'ping' });
          }
        }, 30000);
    
        return () => {
          clearInterval(pingInterval); // Stop sending ping messages
        };
      }, [sendJsonMessage]);
    const finishReading = (order) => {
        setShowProgressBar()

        setTimeout(() => {
            setHideInput()
            setValue("cardNumber", "")
            setOrderToDisplay()
            msgs1.current.clear();
            // msgs1.current.show({ severity: 'info', detail: (t('scanCardMessage')), sticky: true });
        }, 6000);
    }
    const submitFunction = (data) => {
        msgs1.current.clear();
        setShowProgressBar(true)
        let verifyData = {
            cateringId: authContext.cateringId,
            // userId: 43,
            clientId: authContext.cateringId,
            userCardNumber: data.cardNumber
        }
        orderService.verify(verifyData).then(data => {
        }).catch(() => {
            msgs1.current.show({ severity: 'error', detail: (t('orderIsNotValid')), sticky: true });
            setShowProgressBar()
        })

    }
    const returnMenuByCategory = (data) => {
        return <div className="col-12">
            <div className="flex justify-content-center text-left">
                <img src={data.image ? "data:image/png;base64," + data.image : "assets/layout/images/blank_menu_item.jpg"} alt={data.name} className="w-8rem shadow-2" />
                <div className="product-list-detail ml-2">
                    <div className="product-name font-bold text-xl">{data.name}</div>
                    <div className="product-description mt-1">{data.description}</div>
                    <i className="pi pi-tag product-category-icon mt-1"></i> <span className="product-category">{data.itemCategoryName}</span>
                </div>
            </div>
        </div>

    }
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const returnOrderBorderClass = (state) => {
        switch (state) {
            case "CREATED":
                return "col-12 flex-row justify-content-center border-2 border-green-300 w-30rem p-5"
            case "VERIFIED":
                return "col-12 flex-row justify-content-center border-2 border-orange-300 w-30rem p-5"
            case "MISSING_ITEM":
                return "col-12 flex-row justify-content-center border-2 border-red-300 w-30rem p-5"
        }
    }
    return (
        <BlockUI style={{ minHeight: '400px' }} blocked={!connectedToServer} template={
            <div>
                <i className='pi pi-spin pi-spinner' /> <span>{t('socketServerTimeout')}</span>
            </div>
        }>
            <div className='card card-w-title '>
                <Messages ref={msgs1} />

                {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                {!showProgressBar && orderToDisplay ?
                    <div className='grid text-center flex justify-content-center'>
                        <div className='col-12' style={{ marginBottom: "-2rem", marginLeft: "-16rem" }}>
                            {orderToDisplay.status == "CREATED" && <i className='pi pi-check-circle text-green-300 text-8xl' />}
                            {orderToDisplay.status == "VERIFIED" && <i className='pi pi-exclamation-triangle text-orange-300 text-8xl' />}
                            {orderToDisplay.status == "MISSING_ITEM" && <i className='pi pi-exclamation-triangle text-red-300 text-8xl' />}
                        </div>

                        <div className={returnOrderBorderClass(orderToDisplay.status)} style={{ borderRadius: "11px" }}>
                            {orderToDisplay.status == "CREATED" && <span className='text-3xl text-green-300 my-4'>{t('orderIsValid')}</span>}
                            {orderToDisplay.status == "VERIFIED" && <span className='text-3xl text-orange-300 my-4'>{t('orderAlreadyVerified')}</span>}
                            {orderToDisplay.status == "MISSING_ITEM" && <span className='text-3xl text-red-300 my-4'>{t('orderIsNotValid')}</span>}
                            <div className='flex justify-content-center no-wrap mt-3'>
                                <i className='pi pi-user text-xl mr-2 mt-1'></i>
                                <span><h4>{orderToDisplay?.userFullName}</h4></span>

                            </div>
                            {
                                orderToDisplay?.menuItems?.map(x => {
                                    return returnMenuByCategory(x)
                                })
                            }
                        </div>

                    </div>
                    :
                    <></>
                    // (!hideInput ? <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                    //     <div className='grid'>
                    //         <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                    //             <div className="field">
                    //                 <label htmlFor="cardNumber" className={classNames({ 'p-error': errors.name })}>{t('cardNumber')}*</label>
                    //                 <Controller name="cardNumber" control={control} rules={{ required: t('cardNumberRequired') }} render={({ field, fieldState }) => (
                    //                     <Password id={field.name} feedback={false} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} onBlur={(e) => { e.target.focus() }} />
                    //                 )} />
                    //                 {getFormErrorMessage('cardNumber')}
                    //             </div>
                    //         </div>
                    //     </div>
                    // </form> : <></>)

                }
            </div>
        </BlockUI>

    );
}

export default OrderVerification;
