import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Toolbar } from 'primereact/toolbar';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
const PrintOrderData = (props) => {
    const { orderData, orderTableData, closePrint, customerData, cateringData, minDate, maxDate } = props

    const [displaySingleOrders, setDisplaySingleOrders] = useState(true);
    const [displayAmountOrders, setDisplayAmountOrders] = useState(true);

    const { t } = useTranslation();
    const returnHeaderData = () => {
        return <div className="invoice-header">
            <div className="invoice-no">
                <h1>{t('orderList')}</h1>
                <h3>A/3100</h3>
            </div>
            <div className="invoice-logo">
                <img src="assets/layout/images/LOGO.svg" alt="babylon-layout" />
            </div>
            {cateringData && <div className="">
                {/* <img src="assets/layout/images/logo-white.png" alt="babylon-layout" /> */}
                <span><b>{t('company')}:</b></span>
                <div>{cateringData?.name}</div>
                <div>{cateringData?.addressCountry + ", " + cateringData?.addressCity}</div>
                <div>{cateringData?.phone}</div>
                <div>{cateringData?.email}</div>
            </div>}
        </div>
    }
    const returnClientData = () => {
        return <div className="invoice-table-1 bill-to">
            <div className="invoice-table-header grid grid-nogutter">
                <div className="col-6">
                    <span>{t('customerCapital')}</span>
                </div>
                <div className="col-3">
                    <span>{t('dateCapital')}</span>
                </div>
                <div className="col-3">
                    <span>{t('commentCapital')}</span>
                </div>
            </div>
            <div className="invoice-table-content">
                <div className="invoice-table-row grid grid-nogutter">
                    <div className="col-6">
                        <span>{customerData?.name + ", " + customerData.address}</span>
                    </div>
                    <div className="col-3">
                        <span>{minDate + " - " + maxDate}</span>
                    </div>
                    <div className="col-3">
                        <span>N/A</span>
                    </div>
                </div>
            </div>
        </div>
    }
    const returnSingleOrderData = () => {
        let data = []
        if (orderTableData?.length > 0) {
            data = orderTableData.map(x => {
                return <div className="invoice-table-row grid grid-nogutter">
                    <div className="col-2">
                        <span>{x.date}</span>
                    </div>
                    <div className="col-2">
                        <span>{x.customerName}</span>
                    </div>
                    <div className="col-2">
                        <span>{x.userFullName}</span>
                    </div>
                    <div className="col-2">
                        <span>{x.shift}</span>
                    </div>
                    <div className="col-4">
                        <span>{x.menuItems}</span>
                    </div>
                </div>
            })
        }
        data.push(
            <div className='col-12' style={{backgroundColor:"#ebebef"}}>
                <div className='grid'>
                    <div className='col-3'>
                    <div className="invoice-table-col header-col">
                    TOTAL: <span><b>{orderData.length}</b></span>
                        </div>
                    </div>
                    <div className='col-3'>
                        {t('verified')}: <b>{orderData.filter(x => x.status == "VERIFIED")?.length}</b>
                    </div>
                </div>
            </div>

        )
        return <div className="invoice-table-1 items">
            <div className="invoice-table-header grid grid-nogutter">
                <div className="col-2">
                    <span>{t('date')}</span>
                </div>
                <div className="col-2">
                    <span>{t('customerName')}</span>
                </div>
                <div className="col-2">
                    <span>{t('userFullName')}</span>
                </div>
                <div className="col-2">
                    <span>{t('shift')}</span>
                </div>
                <div className="col-4">
                    <span>{t('menuItems')}</span>
                </div>
            </div>
            <div className="invoice-table-content">
                {data}
            </div>

        </div>

    }
    const returnCountOfItems = () => {
        let countData = []
        let allMenuItemNames = []
        let allMenuItems = []
        if (orderData?.length > 0) {
            orderData.forEach(element => {
                allMenuItems = [...allMenuItems, ...element.menuItems]
            });
            if (allMenuItems?.length > 0) {
                allMenuItems.forEach(element => {
                    if (!allMenuItemNames.includes(element.name)) {
                        allMenuItemNames.push(element.name)
                    }
                });
                if (allMenuItemNames?.length > 0) {
                    countData = allMenuItemNames.map(x => ({
                        name: x,
                        count: allMenuItems.filter(y => y.name === x).length
                    }))
                }
            }
        }
        return <div className="invoice-footer grid grid-nogutter">
            <div className="col-12 md:col-6">
                <div className="invoice-table-2 grid grid-nogutter">
                    <div className="col-6">
                        <div className="invoice-table-col header-col">
                            {
                                allMenuItemNames.map(x => {
                                    return <span>{x}</span>
                                })
                            }
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="invoice-table-col content-col">
                            {
                                allMenuItemNames.map(x => {
                                    return <span>{allMenuItems.filter(y => y.name === x).length}</span>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-6">
                <div className="invoice-table-2 summary grid grid-nogutter">
                    <div className="col-6">
                        <div className="invoice-table-col header-col">
                            <span className="total">TOTAL</span>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="invoice-table-col content-col">
                            <span className="total">{allMenuItems.length}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
    const onFinishPrint = () => {
        if (closePrint) {
            closePrint()
        }
    }
    const leftToolbarTemplate = () => {
        return (
            <div className="flex">
                <Button label={t('print')} icon="pi pi-print" onClick={() => window.print()} style={{ display: 'block', marginBottom: '20px', marginLeft: '6px' }} />
                <div style={{ marginTop: "5px", marginLeft: '20px' }}>
                    <Checkbox inputId="cb1" checked={displaySingleOrders}
                        onChange={e => setDisplaySingleOrders(e.checked)} />
                    <label htmlFor="cb1" className="p-checkbox-label ml-2">Display single orders</label>
                </div>
                <div style={{ marginTop: "5px", marginLeft: '20px' }}>
                    <Checkbox inputId="cb2" checked={displayAmountOrders}
                        onChange={e => setDisplayAmountOrders(e.checked)} />
                    <label htmlFor="cb2" className="p-checkbox-label ml-2">Display amount</label>
                </div>
            </div>
        )
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label={t('finish')} icon="pi pi-check" onClick={() => onFinishPrint()} style={{ display: 'block', marginBottom: '20px', marginLeft: '6px' }} />
            </React.Fragment>
        )
    }
    return (
        <>
            <Toolbar className="mb-4" right={rightToolbarTemplate} left={leftToolbarTemplate}></Toolbar>
            <div id="invoice-content">
                <div className="invoice-wrapper">
                    <div className="invoice-content">
                        {returnHeaderData()}
                        {returnClientData()}
                        {displaySingleOrders && returnSingleOrderData()}
                        {displayAmountOrders && returnCountOfItems()}
                    </div>
                </div>
            </div>
        </>
    )
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(PrintOrderData, comparisonFn);
