import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const CustomerLocationFormDialog = (props) => {
    const { customerLocationDialog, hideDialog, updateCustomerLocation, customerLocationToEdit } = props
    let defaultValues = {
        locationName: '',
        locationAddress: '',

    }
    const { t } = useTranslation();

    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });

    useEffect(() => {
        if (customerLocationToEdit?.locationName) {
            setValue('locationName', customerLocationToEdit?.locationName)
        }
        if (customerLocationToEdit?.id) {
            setValue('id', customerLocationToEdit?.id)
        }
        if (customerLocationToEdit?.locationAddress) {
            setValue('locationAddress', customerLocationToEdit?.locationAddress)
        }
    }, [customerLocationToEdit])
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const submitFunction = (data) => {
        if (customerLocationToEdit) {
            updateCustomerLocation(data, customerLocationToEdit.id)
        } else {
            updateCustomerLocation(data)
        }
        reset()
    }
    const onHideDialog = () => {
        hideDialog()
        reset()
    }
    const customerLocationDialogFooter = (
        <div className='grid text-right'>
            <div className='col-offset-5 col-3'>
                <Button type="button" label={t('cancel')} icon="pi pi-times" className="p-button-text col-6" onClick={onHideDialog} />
            </div>
            <div className='col-3'>
                <Button label={t('save')} icon="pi pi-check" className="p-button-text col-6" type="submit" />
            </div>
        </div>
    );
    return (
        <Dialog visible={customerLocationDialog} style={{ width: '450px' }} header={t('newLocation')} modal className="p-fluid" onHide={onHideDialog}>
            <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                <div className="field">
                    <label htmlFor="locationName" className={classNames({ 'p-error': errors.name })}>{t('name')}*</label>
                    <Controller name="locationName" control={control} rules={{ required: t('nameRequired') }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('locationName')}
                </div>
                <div className="field">
                    <label htmlFor="locationAddress" className={classNames({ 'p-error': errors.description })}>{t('address')}*</label>
                    <Controller name="locationAddress" control={control} rules={{ required: t('addressRequired') }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                    {getFormErrorMessage('locationAddress')}
                </div>
     
                {customerLocationDialogFooter}
            </form>
        </Dialog>

    );
};

export default CustomerLocationFormDialog;