import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

const StockModalForm = (props) => {
    const { stockItems, stockDialog, hideDialog, saveStock, updateStock, stockToEdit, customersDropdownList } = props
    let defaultValues = {
        stockItemId: '',
        customerId: '',
        importDate: '',
        amount: '',
        comment: '',
    }
    const [currentUnit, setCurrentUnit] = useState();
    const { t } = useTranslation();

    const { control, formState: { errors }, handleSubmit, reset, setValue, getValues } = useForm({ defaultValues });

    useEffect(() => {
        if (stockToEdit?.stockItemId) {
            setValue('stockItemId', stockToEdit?.stockItemId)
        }
        if (stockToEdit?.importDate) {
            setValue('importDate', stockToEdit?.importDate)
        }
        if (stockToEdit?.id) {
            setValue('id', stockToEdit?.id)
        }
        if (stockToEdit?.amount) {
            setValue('amount', stockToEdit?.amount)
        }
        if (stockToEdit?.comment) {
            setValue('comment', stockToEdit?.comment)
        }
        if (stockToEdit?.customerId) {
            setValue('customerId', stockToEdit?.customerId)
        }
    }, [stockToEdit])
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const submitFunction = (data) => {
        if (stockToEdit) {
            updateStock(data)
        } else {
            saveStock(data)
        }
        reset()
    }
    const onHideDialog = () => {
        hideDialog()
        reset()
    }
    const onStockItemChange = (event) => {
        console.log(stockItems?.filter(x => x.id === event.target.value)?.[0], 'stockItems?.filter(x => x.id === event.target.value)?.[0]');
        setCurrentUnit(stockItems?.filter(x => x.id === event.target.value)?.[0]?.unit)
    }
    const stockItemOptions = () => {
        let options = []
        if (stockItems?.length > 0) {
            options = stockItems.map(x => ({
                value: x.id, label: x.name
            }))
        }
        return options
    }
    const stockDialogFooter = (
        <div className='grid text-right'>
            <div className='col-offset-5 col-3'>
                <Button type="button" label={t('cancel')} icon="pi pi-times" className="p-button-text col-6" onClick={onHideDialog} />
            </div>
            <div className='col-3'>
                <Button label={t('save')} icon="pi pi-check" className="p-button-text col-6" type="submit" />
            </div>
        </div>
    );
    return (
        <Dialog visible={stockDialog} style={{ width: '450px' }} header={t('newStock')} modal className="p-fluid" onHide={onHideDialog}>
            <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                <div className="field">
                    <label htmlFor="stockItemId" filter className={classNames({ 'p-error': errors.stockItemId })}>{t('stockItemId')}*</label>
                    <Controller name="stockItemId" control={control} rules={{ required: t('stockItemIdRequired') }} render={({ field, fieldState }) => (
                        <Dropdown id={field.name} {...field} options={stockItemOptions()} className={classNames({ 'p-invalid': fieldState.invalid })} onChange={(event) => {
                            field.onChange(event)
                            onStockItemChange(event)
                        }} />
                    )} />
                    {getFormErrorMessage('stockItemId')}
                </div>
                <div className="field">
                    <label htmlFor="customerId" className={classNames({ 'p-error': errors.customerId })}>{t('customer')}*</label>
                    <Controller name="customerId" control={control} render={({ field, fieldState }) => (
                        <Dropdown id={field.name} {...field} options={customersDropdownList} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('customerId')}
                </div>
                <div className="field">
                    <label htmlFor="importDate" className={classNames({ 'p-error': errors.name })}>{t("importDate")}*</label>
                    <Controller name="importDate" control={control} rules={{ required: t("dateRequired") }} render={({ field, fieldState }) => (
                        <Calendar id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)} showIcon />)} />
                    {getFormErrorMessage('importDate')}
                </div>
                <div className="field">

                    <label htmlFor="amount" className={classNames({ 'p-error': errors.amount })}>{t('amount')}*</label>
                    <Controller name="amount" control={control} rules={{ required: t('amountRequired') }} render={({ field, fieldState }) => (
                        <div className="p-inputgroup">
                            <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                            <span className="p-inputgroup-addon">{currentUnit && t(currentUnit)}</span>
                        </div>
                    )} />
                    {getFormErrorMessage('amount')}
                </div>
                <div className="field">
                    <label htmlFor="comment" className={classNames({ 'p-error': errors.comment })}>{t('comment')}</label>
                    <Controller name="comment" control={control} render={({ field, fieldState }) => (
                        <InputTextarea id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('comment')}
                </div>

                {stockDialogFooter}
            </form>
        </Dialog>

    );
};

export default StockModalForm;