import { BlockUI } from 'primereact/blockui';
import { Messages } from 'primereact/messages';
import { Password } from 'primereact/password';
import { ProgressBar } from 'primereact/progressbar';
import { classNames } from 'primereact/utils';
import React, { useContext, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../context';
import OrderService from '../../service/OrderService';
import { sortArray } from '../../utilities/general';
const OrderCardVerification = () => {
    const { t } = useTranslation();
    const orderService = new OrderService();
    const [orderToDisplay, setOrderToDisplay] = useState();
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [hideInput, setHideInput] = useState(false);
    let defaultValues = {
        cardNumber: ''
    }
    const { control, formState: { errors }, handleSubmit, setValue } = useForm({ defaultValues });
    const msgs1 = useRef(null);
    let audio = new Audio("assets/sound/verify-sound.mp3");

    const finishReading = () => {
        setShowProgressBar()
        setValue("cardNumber", "")
        msgs1.current.clear();

    }
    const submitFunction = (data) => {
        msgs1.current.clear();
        setShowProgressBar(true)
        let verifyData = {
            userCardNumber: data.cardNumber
        }
        orderService.verifyWithCard(verifyData).then(data => {
            audio.play()
            if (data) {
                setOrderToDisplay(data)
            } else {
                msgs1.current.show({ severity: 'error', detail: (t('orderIsNotValid')), sticky: true });
            }
            finishReading()

        }).catch(() => {
            msgs1.current.show({ severity: 'error', detail: (t('orderIsNotValid')), sticky: true });
            setShowProgressBar()
            finishReading()

        })

    }
    const returnMenuByCategory = (data) => {
        return <div className="col-12">
            <div className="flex justify-content-start text-left">
                <img src={data.image ? "data:image/png;base64," + data.image : "assets/layout/images/blank_menu_item.jpg"} alt={data.name} className="w-6rem shadow-2" />
                <div className="product-list-detail ml-4">
                    <div className="product-name font-bold text-3xl">{data.name}</div>
                    <div className="product-description mt-1 text-xl">{data.description}</div>
                    <i className="pi pi-tag product-category-icon mt-1 text-xl text-500"></i> <span className="text-500 product-category text-xl">{data.itemCategoryName}</span>
                </div>
            </div>
        </div>

    }
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const returnOrderBorderClass = (state) => {
        switch (state) {
            case "CREATED":
                return "col-12 flex-row justify-content-center border-2 border-green-300 p-10"
            case "VERIFIED":
                return "col-12 flex-row justify-content-center border-2 border-orange-300 p-10"
            case "MISSING_ITEM":
                return "col-12 flex-row justify-content-center border-2 border-red-300 p-10"
        }
    }
    return (

        <div className='card card-w-title '>
            <Messages ref={msgs1} />

            {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
            <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                <div className='grid'>
                    <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                        <div className="field">
                            <label htmlFor="cardNumber" className={classNames({ 'p-error': errors.name })}>{t('cardNumber')}</label>
                            <Controller name="cardNumber" control={control} render={({ field, fieldState }) => (
                                <Password id={field.name} feedback={false} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} onBlur={(e) => { e.target.focus() }} />
                            )} />
                            {getFormErrorMessage('cardNumber')}
                        </div>
                    </div>
                </div>
            </form>
            {orderToDisplay && <div className='grid text-left flex justify-content-center'>
                <div className='col-12' style={{ marginBottom: "-1rem", marginLeft: "2rem" }}>
                    {orderToDisplay.status == "CREATED" && <i className='pi pi-check-circle text-green-300 text-7xl' />}
                    {orderToDisplay.status == "VERIFIED" && <i className='pi pi-exclamation-triangle text-orange-300 text-7xl' />}
                    {orderToDisplay.status == "MISSING_ITEM" && <i className='pi pi-exclamation-triangle text-red-300 text-7xl' />}
                </div>

                <div className={returnOrderBorderClass(orderToDisplay.status)} style={{ borderRadius: "11px" }}>
                    {orderToDisplay.status == "CREATED" && <span className='text-3xl text-green-300 my-4'>{t('orderIsValid')}</span>}
                    {orderToDisplay.status == "VERIFIED" && <span className='text-3xl text-orange-300 my-4'>{t('orderAlreadyVerified')}</span>}
                    {orderToDisplay.status == "MISSING_ITEM" && <span className='text-3xl text-red-300 my-4'>{t('orderIsNotValid')}</span>}
                    <div className='flex justify-content-end no-wrap mt-3'>
                        <i className='pi pi-user text-2xl mr-2 mt-1 text-orange-400'></i>
                        <span className='text-orange-400'><h3>{orderToDisplay?.userFullName} - {orderToDisplay?.customerLocationName}</h3></span>

                    </div>
                    {
                        sortArray(orderToDisplay?.menuItems, 'itemCategoryId')?.map(x => {
                            return returnMenuByCategory(x)
                        })
                    }
                </div>

            </div>}




        </div>

    );
}

export default OrderCardVerification;
