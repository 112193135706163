import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { adminRole, cateringAdmin, cateringOperator, customerAdmin } from '../../constants/auth';
import AuthContext from '../../context';
import RoleService from '../../service/RoleService';
import UserService from '../../service/UserService';
import { searchRoleParams } from '../../service/serviceObjects';
import { Checkbox } from 'primereact/checkbox';

const AddUserForm = (props) => {
    const { saveUser, updateUser, userToEdit, cateringId, customerId, showRoles, showCardField, customerData } = props
    const [cateringList, setCateringList] = useState([])
    const [roles, setRoles] = useState([])
    const [checkUsernameInProgress, setCheckUsernameInProgress] = useState(false)

    const roleService = new RoleService()
    const userService = new UserService()
    let defaultValues = {
        name: '',
        username: '',
        email: '',
        phone: '',
        password: '',
        passwordAgain: '',
        cateringId: cateringId ?? '',
        customerId: customerId ?? '',
        roleId: '',
        personalNumber: '',
        cardNumber: '',
        location: '',
        notifyUser: ''
    }
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);
    const { control, formState: { errors }, handleSubmit, reset, setValue, setError, clearErrors, getValues } = useForm({ defaultValues, mode: 'all' });
    let locations = []
    if (customerData?.locationDTOS?.length > 1) {
        locations = customerData?.locationDTOS.map(element => ({
            label: element.locationName,
            value: element.id
        }))
    }
    const prepareRolesForDropdown = (roles) => {
        let options = []
        if (roles?.length > 0) {
            roles.forEach(element => {
                if (cateringId && element.name.includes('catering')) {
                    options.push({
                        label: t(element.name),
                        value: element.id
                    })
                }
                if (customerId && element.name.includes('customer')) {
                    options.push({
                        label: t(element.name),
                        value: element.id
                    })
                }
                // if (authContext?.roleDTO?.name) {
                //     if (authContext?.roleDTO?.name === adminRole
                //         || ((authContext?.roleDTO?.name === cateringAdmin || authContext?.roleDTO?.name === cateringOperator) && (element.name.includes('catering') || element.name.includes('customer')))
                //         || (authContext?.roleDTO?.name === customerAdmin && element.name.includes('customer'))
                //     ) {
                //         options.push({
                //             label: t(element.name),
                //             value: element.id
                //         })
                //     }
                // }

            });
        }

        return options
    }
    useEffect(() => {
        roleService.search(searchRoleParams).then(data => setRoles(prepareRolesForDropdown(data.items))).catch(error => console.log(error))
    }, [])
    useEffect(() => {
        if (userToEdit?.name) {
            setValue('name', userToEdit?.name)
        }
        if (userToEdit?.username) {
            setValue('username', userToEdit?.username)
        }
        if (userToEdit?.email) {
            setValue('email', userToEdit?.email)
        }
        if (userToEdit?.phone) {
            setValue('phone', userToEdit?.phone)
        }
        if (userToEdit?.id) {
            setValue('id', userToEdit?.id)
        }
        if (userToEdit?.roleDTO?.id) {
            setValue('roleId', userToEdit?.roleDTO?.id)
        }
        if (userToEdit?.cardNumber) {
            setValue('cardNumber', userToEdit?.cardNumber)
        }
        if (userToEdit?.personalNumber) {
            setValue('personalNumber', userToEdit?.personalNumber)
        }
        if (userToEdit?.customerLocationId) {
            setValue('customerLocationId', userToEdit?.customerLocationId)
        }
    }, [userToEdit])
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const submitFunction = (data) => {
        if (userToEdit) {
            updateUser(data)
        } else {
            saveUser(data)
        }
        reset()
    }
    const checkUsername = (event) => {
        clearErrors('username')
        setCheckUsernameInProgress(true)
        userService.isUnique(event.target.value).then(() => {
            setError('username', { type: 'custom', message: t("usernameExists") })
            setCheckUsernameInProgress(false)
        }).catch(() => {
            setCheckUsernameInProgress(false)

        })
    }
    const checkPasswordAgain = (event) => {
        clearErrors('passwordAgain')
        let values = getValues('password')
        if (event.target.value !== values) {
            setError('passwordAgain', { type: 'custom', message: t("passwordNotMatch") })

        }
    }
    const formButtons = (
        <div className='grid text-center'>
            <div className='col-offset-9 col-3'>
                <Button label={t('save')} icon="pi pi-check" className="p-button-text col-6" type="submit" />
            </div>
        </div>
    );

    return (
        <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
            <div className='grid'>
                <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                    <div className="field">
                        <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>{t('name')}*</label>
                        <Controller name="name" control={control} rules={{ required: t('nameRequired') }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} autoFocus />
                        )} />
                        {getFormErrorMessage('name')}
                    </div>
                </div>
                <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                    <div className="field">
                        <label htmlFor="username" className={classNames({ 'p-error': errors.username })}>{t('username')}*</label>
                        <Controller name="username" control={control} rules={{
                            required: t('usernameRequired'), pattern: {
                                value: /^[a-z0-9@._-]{4,100}$/,
                                message: t('invalidUsername')
                            }
                        }} render={({ field, fieldState }) => (
                            <div className="p-inputgroup">
                                <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} onBlur={(event) => checkUsername(event)} tooltip={t("usernameTooltip")} />
                                {checkUsernameInProgress && <span className="p-inputgroup-addon"><i className="pi pi-spin pi-spinner" style={{ 'fontSize': '2em' }}></i></span>}
                            </div>
                        )} />
                        {getFormErrorMessage('username')}
                    </div>
                </div>

                <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                    <div className="field">
                        <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>{t('email')}</label>
                        <Controller name="email" control={control} rules={{
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: t('invalidEmailFormat')
                            }
                        }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('email')}
                    </div>
                    {getValues('email') != '' && !userToEdit && <div className="field mt-3">
                        <label htmlFor="notifyUser">{t('notifyUser')}</label>
                        <Controller name="notifyUser" control={control} render={({ field, fieldState }) => (
                            <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} />
                        )} />
                    </div>}
                </div>
                <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                    <div className="field">
                        <label htmlFor="personalNumber" className={classNames({ 'p-error': errors.personalNumber })}>{t('personalNumber')}</label>
                        <Controller name="personalNumber" control={control} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('personalNumber')}
                    </div>
                </div>
                <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                    <div className="field">
                        <label htmlFor="phone" className={classNames({ 'p-error': errors.phone })}>{t('phone')}</label>
                        <Controller name="phone" control={control} rules={{
                            pattern: {
                                value: /^\+[1-9]{1}[0-9]{3,14}$/i,
                                message: t('invalidPhoneFormat')
                            }
                        }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('phone')}
                    </div>
                </div>
                {!userToEdit && <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>

                    <div className="field">
                        <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>{t('password')}*</label>
                        <Controller name="password" control={control} rules={{ required: t('passwordRequired') }} render={({ field, fieldState }) => (
                            <Password id={field.name} {...field} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('password')}
                    </div>
                </div>}
                {!userToEdit && <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>

                    <div className="field">
                        <label htmlFor="passwordAgain" className={classNames({ 'p-error': errors.passwordAgain })}>{t('passwordAgain')}*</label>
                        <Controller name="passwordAgain" control={control} rules={{ required: t('passwordAgainRequired') }} render={({ field, fieldState }) => (
                            <Password id={field.name} {...field} feedback={false} toggleMask className={classNames({ 'p-invalid': fieldState.invalid })} onBlur={(event) => checkPasswordAgain(event)} />
                        )} />
                        {getFormErrorMessage('passwordAgain')}
                    </div>
                </div>}
                {!cateringId && !customerId && <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                    <div className="field">
                        <label htmlFor="cateringId" className={classNames({ 'p-error': errors.cateringId })}>{t('cateringId')}*</label>
                        <Controller name="cateringId" control={control} rules={{ required: t('cateringRequired') }} render={({ field, fieldState }) => (
                            <Dropdown id={field.name} {...field} options={cateringList} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('cateringId')}
                    </div>
                </div>}
                {!customerId && !cateringId && <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                    <div className="field">
                        <label htmlFor="customerId" className={classNames({ 'p-error': errors.customerId })}>{t('customerId')}*</label>
                        <Controller name="customerId" control={control} rules={{ required: t('customerRequired') }} render={({ field, fieldState }) => (
                            <Dropdown id={field.name} {...field} options={cateringList} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('customerId')}
                    </div>
                </div>}
                {!cateringId && locations.length > 0 && <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                    <div className="field">
                        <label htmlFor="customerLocationId" className={classNames({ 'p-error': errors.customerId })}>{t('location')}*</label>
                        <Controller name="customerLocationId" control={control} rules={{ required: t('locationRequired') }} render={({ field, fieldState }) => (
                            <Dropdown id={field.name} {...field} options={locations} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('customerLocationId')}
                    </div>
                </div>}
                {showRoles && <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                    <div className="field">
                        <label htmlFor="roleId" className={classNames({ 'p-error': errors.cateringId })}>{t('roleId')}*</label>
                        <Controller name="roleId" control={control} rules={{ required: t('roleIdRequired') }} render={({ field, fieldState }) => (
                            <Dropdown id={field.name} {...field} options={roles} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('roleId')}
                    </div>
                </div>}
                {showCardField && <div className='col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6'>
                    <div className="field">
                        <label htmlFor="cardNumber">{t('cardNumber')}</label>
                        <Controller name="cardNumber" control={control} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />

                        )} />
                        {getFormErrorMessage('cardNumber')}
                    </div>
                </div>}
            </div>
            {formButtons}
        </form>
    );
};

export default AddUserForm;