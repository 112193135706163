import React from "react";
import { useTranslation } from "react-i18next";
import { sortArray } from "../../utilities/general";
import { Button } from "primereact/button";
import moment from "moment";
import { Message } from "primereact/message";
const OrderPreviewComponent = (props) => {

    const { orders, onDeleteOrder } = props
    const { t } = useTranslation();
    const prepareDataForPreview = (orders) => {
        let tableData = [];
        tableData = orders.map(x => {
            let allItems = [];
            let totalOrderPrice = 0; // Initialize total order price
            if (x?.menuItemSequences?.length > 0) {
                let arrayItems = sortArray(x.menuItemSequences, 'menuItemDTO.itemCategoryId');
                arrayItems.forEach((element, index) => {
                    const menuItemName = element.name;
                    const menuItemAmount = element.amount;
                    const menuItemPrice = element.price; // Get price of the item
                    const itemTotalPrice = menuItemAmount * menuItemPrice; // Calculate item total price

                    // Add item price to total order price
                    totalOrderPrice += itemTotalPrice;

                    if (menuItemName) {
                        let separator = ", ";
                        if (index === 0) {
                            separator = "";
                        }
                        // Append the menu item name, amount, and price
                        allItems.push({
                            amount: menuItemAmount,
                            name: menuItemName,
                            price: menuItemPrice
                        });
                    }
                });
            }

            return {
                id: x.id,
                date: x.date,
                customerName: x.customerName,
                customerLocationName: x.customerLocationName,
                userFullName: x.userFullName,
                userPersonalNumber: x.userPersonalNumber,
                shift: x.shift,
                status: t(x.status),
                menuItems: allItems,  // Display name, amount, and price
                comment: x.comment,
                price: totalOrderPrice, // Use calculated total price
            };
        });
        return tableData;
    };
    return (
        orders?.length > 0 ? prepareDataForPreview(orders).map(x => {
            return <section className="flex flex-column w-full mt-4 px-8 surface-100 p-4">
                <div className="flex w-full justify-content-between align-items-center mb-4">
                    <span className="font-semibold text-base text-600">
                        {t('order')} / <span className="text-900">{x?.transactionNumber ?? x?.id}</span>
                    </span>
                </div>
                <div className="flex justify-content-between align-items-center w-full mb-4">
                    <p className="font-semibold text-xl mt-0 mb-0 text-900">
                        <span className="pi pi-calendar text-xl text-yellow-600"></span> {moment(x?.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
                    </p>
                    {onDeleteOrder && <Button type="button" icon="pi pi-trash text-xl" className="w-2rem h-2rem p-button-rounded p-button-text surface-200 text-orange-600" onClick={() => {
                        onDeleteOrder(x)
                    }
                    }></Button>}

                </div>
                <table>
                    {x?.userFullName && <tr style={{ height: '2.5rem' }}>
                        <td className="font-medium text-base text-600">{t("name")}</td>
                        <td className="font-medium text-base text-900">{x?.userFullName}</td>
                    </tr>}
                    {x?.customerName && <tr style={{ height: '2.5rem' }}>
                        <td className="font-medium text-base text-600">{t("customer")}</td>
                        <td>
                            <span className="mr-2">{x?.customerName}</span>
                        </td>
                    </tr>}
                    <tr style={{ height: '2.5rem' }}>
                        <td className="font-medium text-base text-600">{t('shift')}</td>
                        <td>
                            <span className="p-tag">{x?.shift}</span>
                        </td>
                    </tr>
                    <tr style={{ height: '2.5rem' }}>
                        <td className="font-medium text-base text-600">{t('menuItems')}</td>
                    </tr>

                </table>
                <div>
                    <ul className="list-none p-0 m-0">
                        <li className="flex flex-column md:flex-row md:align-items-center md:justify-content-between p-3 border-1 mb-3 bg-blue-50 border-yellow-500" style={{ borderRadius: '10px' }}>
                            <div>
                                <div>
                                    <table>
                                        {x.menuItems.map((item, index) => (
                                            <tr key={index} className="text-left">
                                                <span className="inline-flex justify-content-center align-items-center w-2rem h-2rem border-circle border-1 border-yellow-200">

                                                    <i className="pi pi-list text-yellow-700"></i>
                                                </span>
                                                <td>{item.amount}x {item.name}</td>
                                                <td className="ml-3">{item.amount * item.price},00RSD</td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                                {/* <p className="text-yellow-700 mt-2 mb-0">Due <span className="font-medium">10/09/2021</span></p> */}
                            </div>
                            <div className="flex align-items-center justify-content-between md:justify-content-end mt-3 md:mt-0">
                                <span className="bg-yellow-400 text-yellow-900 font-bold text-sm py-1 px-2" style={{ borderRadius: '10px' }}>{x?.status}</span>
                                <div className="text-right ml-3">
                                    <span className="text-yellow-700 font-bold">{x?.price},RSD</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="border-y-1 surface-border py-4 my-4">
                    <p className="font-medium text-lg text-700 mt-0">{t('comment')}</p>
                    <p className="text-base text-900 mt-0 mb-0">{x?.comment}</p>
                </div>

            </section>
        })
            :
            <Message severity="info" text={t('noItemsFound')} className='w-full m-2' />
    );
}

export default OrderPreviewComponent;