import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
const RecipeComponent = (props) => {
    const { showModal, onHideModal, menuItemToEdit, stockItems, saveRecipe } = props
    const [editingRows, setEditingRows] = useState({});
    const [products2, setProducts2] = useState([]);
    useEffect(() => {
        if (menuItemToEdit?.menuItemStockItemDTO?.length > 0) {
            let data = menuItemToEdit?.menuItemStockItemDTO.map((x, index) => ({
                id: index,
                stockItem: x.stockItemDTO.id,
                amount: x.amount,
                unit: x.stockItemDTO.unit

            }))
            setProducts2(data)
        }
    }, [menuItemToEdit])

    const { t } = useTranslation();
    const hideModal = () => {
        onHideModal()
    }
    const saveChanges = () => {
        let recipeArray = products2.map(x => ({
            amount: x.amount,
            stockItemDTO: {
                id: x.stockItem
            }
        }))
        let saveRequest = {
            ...menuItemToEdit,
            menuItemStockItemDTO: recipeArray
        }
        saveRecipe(saveRequest)
    }
    const addEmptyRow = () => {
        let _products2 = [...products2];
        _products2.push({
            id: _products2.length + 1,
            stockItem: null,
            amount: null,
            unit: null
        })
        setProducts2(_products2);
        setTimeout(() => {
            let _editingRows = { ...editingRows, ...{ [`${_products2[_products2.length - 1].id}`]: true } };
            setEditingRows(_editingRows);
        }, 500);

    }
    const onRowEditChange = (e) => {
        setEditingRows(e.data);
    }
    const amountEditor = (options) => {
        return <InputText className="w-full" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />
    }
    const stockItemOptions = () => {
        let options = []
        if (stockItems?.length > 0) {
            options = stockItems.map(x => ({
                value: x.id, label: x.name + " / " + t(x.unit)
            }))
        }
        return options
    }
    const stockItemEditor = (options) => {
        return (
            <Dropdown className="w-full" showClear filter value={options.value} options={stockItemOptions()} optionLabel="label" optionValue="value"
                onChange={(e) => {
                    options.editorCallback(e.value)
                }
                }
            />
        );
    }
    const onRowEditComplete1 = (e) => {
        if (e.newData.amount) {
            let _products2 = [...products2];
            let { newData, index } = e;
            _products2[index] = newData;
            setProducts2(_products2);
            setEditingRows({})
        }

    }
    const removeItem = (rowData) => {
        let _products2 = [...products2];
        _products2 = _products2.filter(x => x.id != rowData.id)
        setProducts2(_products2);
        setEditingRows({})

    }
    const recipeModalDialogFooter = (
        <>
            <Button label={t('cancel')} icon="pi pi-times" className="p-button-text" onClick={hideModal} />
            <Button label={t('save')} icon="pi pi-check" className="p-button-text" onClick={saveChanges} disabled={editingRows && Object.keys(editingRows).length != 0} />
        </>
    );
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions mt-2 text-center">
                <Button icon="pi pi-trash" className="p-button-rounded p-button-outlined p-button-warning" onClick={() => removeItem(rowData)} />
            </div>
        );
    }
    const stockItemTemplate = (rowData) => {
        let returnNameOfItem = stockItems?.filter(x => x.id == rowData.stockItem)?.[0]?.name
        return (
            <>
                <span className="p-column-title">{t("stockItem")}</span>
                <span className={`status`}>{returnNameOfItem}</span>
            </>
        )
    }

    return (
        <div className="card p-fluid">
            <Dialog visible={showModal} style={{ width: '650px' }} header={menuItemToEdit?.name} modal footer={recipeModalDialogFooter} onHide={() => hideModal()}>
                <Button label={t('add')} icon="pi pi-plus" className="p-button-text" onClick={addEmptyRow} />
                <DataTable value={products2} emptyMessage={t('noItemsFound')} editMode="row" dataKey="id" editingRows={editingRows} onRowEditComplete={onRowEditComplete1} onRowEditChange={onRowEditChange} responsiveLayout="scroll">
                    <Column field="stockItem" header={t("stockItem")} body={stockItemTemplate} editor={(options) => stockItemEditor(options)} style={{ width: '50%' }}></Column>
                    <Column field="amount" header={t("amount")} editor={(options) => amountEditor(options)} style={{ width: '30%' }}></Column>
                    <Column rowEditor headerStyle={{ width: '20%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                    <Column body={actionBodyTemplate} headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                </DataTable>

            </Dialog>
        </div>
    );
}

export default RecipeComponent;