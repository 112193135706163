import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import { Password } from 'primereact/password';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import React, { useContext, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Clock } from '../../components/Clock';
import AuthContext from '../../context';
import UserService from '../../service/UserService';
import MenuOrder from './MenuOrder';
const MenuOrderCard = () => {
    const authContext = useContext(AuthContext);
    const [currentUser, setCurrentUser] = useState()
    const [showDialog, setShowDialog] = useState(false)
    const { t } = useTranslation();
    let defaultValues = {
        cardNumber: ''
    }
    const msgs1 = useRef(null);
    const userService = new UserService()
    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
    const submitFunction = (data) => {
        msgs1.current.clear();
        // setShowProgressBar(true)
        userService.getCustomerUserByCard(data.cardNumber, authContext.customerId).then(data => {
            setCurrentUser(data)
            setShowDialog(false)
            reset()
        }).catch(() => {
            reset()
            msgs1.current.show({ severity: 'error', detail: (t('userNotFound')), sticky: true });

        })
    }
    const scanCardModalFooter = (
        <>
            <Button label={t("cancel")} icon="pi pi-times" className="p-button-text" onClick={() => setShowDialog(false)} />
        </>
    );
    const leftInfoToolbarTemplate = (
        <div className='col-12'>
            <div className='flex justify-content-center'>
                <i className='pi pi-user text-xl mr-2 mt-1'></i>
                <span><h4>{currentUser?.name}</h4></span>
            </div>
        </div>
    );
    const rightInfoToolbarTemplate = (
        <>
            <Button label={t("finish")} icon="pi pi-check" className="p-button-info" onClick={() => setCurrentUser()} />
        </>
    );
    const displayBanner = true
    return (
        <>
            <Dialog visible={showDialog} style={{ width: '450px' }} modal footer={scanCardModalFooter} onHide={() => setShowDialog(false)}>

                <div className="grid align-items-center">
                    <div className='col-12'>
                        <Messages ref={msgs1} />
                    </div>
                    <div className='col-12 text-2xl'>
                        <i className="pi pi-tablet mr-3" style={{ fontSize: '2rem' }} />
                        <span>{t("pleaseCanYourCard")}</span>
                    </div>
                    <div className='col-12'>

                        <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                            <div className='grid'>
                                <div className='col-12'>
                                    <div className="field">
                                        <label htmlFor="cardNumber" className={classNames({ 'p-error': errors.name })}>{t('cardNumber')}*</label>
                                        <Controller name="cardNumber" control={control} rules={{ required: t('cardNumberRequired') }} render={({ field, fieldState }) => (
                                            <Password id={field.name} feedback={false} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                                        )} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
            {currentUser ?
                <div className='card'>
                    <Toolbar className="mb-3" left={leftInfoToolbarTemplate} right={rightInfoToolbarTemplate}></Toolbar>

                    <MenuOrder userData={currentUser} />
                </div>
                :
                <div className='grid'>
                    {displayBanner && <div className='col-4'>
                            <img src="assets/layout/images/bannerMobile.png" alt="hyper"  style={{height:'600px'}} />
                    </div>}
                    <div className={displayBanner ? 'col-8' : 'col-12'}>
                        <div className='w-full text-center surface-0 h-full'>

                            <img src="assets/layout/images/LOGO.svg" alt="hyper" height={60} className="" />

                            <Clock />
                            <Button label={t("start")} className="p-button-info p-button-outlined h-10rem w-15rem text-6xl" onClick={() => setShowDialog(true)} />

                        </div>
                    </div>
                </div>
            }

        </>
    );
}

export default MenuOrderCard;