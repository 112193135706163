import React from 'react';
import UserTable from "../Users/UserTable";

const CateringUsers = (props) => {
    const { cateringId } = props
    const customSearchData = {
        cateringId: cateringId
    }
    return (
        <UserTable customSearchData = {customSearchData}/>
    );
}

export default CateringUsers;