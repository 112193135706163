import { SelectButton } from 'primereact/selectbutton';
import { classNames } from 'primereact/utils';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import enImage from "./assets/flags/en.jpg";
import srbImage from "./assets/flags/srb.png";
import { JWT_NAME } from './constants/auth';
import AuthContext from './context';
import i18n from './i18n';
import { createCookie, getCookie } from './utilities/general';
import { useTranslation } from 'react-i18next';
const AppTopbar = (props) => {

    const [value, setValue] = useState(getCookie('lang') || "srb");
    const langOptions = [
        { img: enImage, value: 'en' },
        { img: srbImage, value: 'srb' },
    ];
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const topbarMenuClassName = classNames('topbar-menu fadeInDown', { 'topbar-menu-visible': props.topbarMenuActive });
    const profileItemClassName = classNames('user-profile', { 'active-topmenuitem': props.activeTopbarItem === 'profile' });
    const activeTopbarItemClassName = (name) => {
        return name === props.activeTopbarItem ? 'active-topmenuitem' : null;
    }

    const changeLanguage = (lng) => {
        createCookie('lang', lng)
        setValue(lng)
        i18n.changeLanguage(lng);
    }
    const langTemplate = (option) => {
        return <img src={option.img} alt={"langImg"} width="20" height="10" />;

    }
    return (
        <>
            <div className="layout-topbar">
                <button className="layout-topbar-logo p-link" onClick={() => history.push('/')}>
                    <img id="layout-topbar-logo" src="assets/layout/images/logo-white.png" alt="babylon-layout" />
                </button>

                <button className="layout-menu-button p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-bars"></i>
                </button>

                <button id="topbar-menu-button" className="p-link" onClick={props.onTopbarMenuButtonClick}>
                    <i className="pi pi-ellipsis-v"></i>
                </button>

                <ul className={topbarMenuClassName}>
                    {
                        authContext?.username && (
                            <li className={profileItemClassName}>
                                <button className="p-link" onClick={(e) => props.onTopbarItemClick(e, 'profile')}>
                                    <i className="topbar-icon pi pi-user"></i>
                                    <span className="topbar-item-name">{authContext?.username}</span>
                                </button>

                                <ul className={classNames({ 'fadeInDown': !props.isMobile() })}>
                                    {/* <li role="menuitem">
                                        <button className="p-link">
                                            <i className="pi pi-user"></i>
                                            <span>Profile</span>
                                        </button>
                                    </li> */}
                                    <li role="menuitem">
                                        <button className="p-link" onClick={() => {
                                            createCookie(JWT_NAME, '', -1)
                                            window.location = "/"

                                        }}>
                                            <i className="pi pi-sign-out"></i>
                                            <span>Logout</span>
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        )
                    }
                    <li className={activeTopbarItemClassName('settings')}>
                        <button className="p-link" onClick={(e) => props.onTopbarItemClick(e, 'settings')}>
                            <i className="topbar-icon pi pi-cog"></i>
                            <span className="topbar-item-name">Settings</span>
                        </button>
                        <ul className={classNames({ 'fadeInDown': !props.isMobile() })}>
                            <li role="menuitem flex">
                                <span className="topbar-item-name">{(t('lang'))}</span>
                                <SelectButton value={value} options={langOptions} onChange={(e) => changeLanguage(e.value)} itemTemplate={langTemplate} optionLabel="value" className='text-right' />
                            </li>
                        </ul>
                    </li>

                </ul>
            </div>
        </>

    );
}

export default AppTopbar;
