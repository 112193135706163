import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Ripple } from 'primereact/ripple';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../context';
import MenuItemService from '../../service/MenuItemService';
import { searchItemCategoryParams, searchMenuItemParams } from '../../service/serviceObjects';
import { Image } from 'primereact/image';
import { Rating } from 'primereact/rating';
import MenuOverlayPanel from '../Menu/MenuItem/MenuOverlayPanel';
import MenuService from '../../service/MenuService';
import { sortArray } from '../../utilities/general';
import ItemCategoryService from '../../service/ItemCategoryService';
import { SelectButton } from 'primereact/selectbutton';

const CustomerPublicMenu = (props) => {
    const { history } = props
    const authContext = useContext(AuthContext);
    let cateringId = 0
    if (history?.location?.state?.id) {
        cateringId = history.location.state.id
    }
    let cateringData = history?.location?.state
    let menuItem = {
        cateringId: authContext.cateringId || 0,
        itemCategoryId: null,
        name: null,
        description: null
    }
    const [menuItems, setMenuItems] = useState([]);
    const [originalMenuItems, setOriginalMenuItems] = useState([]);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [itemCategories, setItemCategories] = useState([])
    const [itemCategoriesForMenu, setItemCategoriesForMenu] = useState([])
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const [layout, setLayout] = useState('grid');
    const [filterName, setFilterName] = useState();
    const [selectedMenuItems, setSelectedMenuItems] = useState([])
    const [cateringLogo, setCateringLogo] = useState([]);
    const [selectedItemCategory, setSelectedItemCategory] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);
    const menuService = new MenuService();
    const itemCategoryService = new ItemCategoryService();


    const { t } = useTranslation();

    useEffect(() => {
        getAllMenuItems()
    }, []);
    const onSelectItemCategory = (value) => {
        let searchData = {
            itemCategoryId: value
        }
        setSelectedItemCategory(value)
        let items = originalMenuItems
        if (value && value != '') {
            items = items.filter(x => x.itemCategoryId == value)
        }
        setMenuItems(items)
        // getAllMenuItems(searchData)
    }
    const setCategoriesOptions = (menuItems) => {
        let categories = []
        if (menuItems?.length > 0) {
            menuItems = sortArray(menuItems, 'itemCategoryId')
            let categoryNames = []
            categories = menuItems.map(element => {
                if (!categoryNames.includes(element.itemCategoryName)) {
                    categoryNames.push(element.itemCategoryName)
                    return {
                        name: element.itemCategoryName, value: element.itemCategoryId, image: element.itemCategoryImage
                    }
                }

            }).filter(notUndefined => notUndefined !== undefined);
            setItemCategoriesForMenu(categories)
        }
        return []
    }
    const getAllMenuItems = (searchData) => {
        setLoadingTable(true)
        let searchMenuParams = {
            cateringId: 11,
            name: null,
            description: null,
            pageNumber: 0,
            pageSize: 10
        }
        let searchObject = {
            ...searchMenuParams,
            minDate: "1900-03-25",
            maxDate: "2040-03-25",
            customerId: authContext?.customerId,
            defaultMenu:true
        }
        setShowProgressBar(true)
        menuService.search(searchObject).then(data => {
            let items = []
            if (data?.items?.[0]?.menuItemDTOList?.length > 0) {
                items = data.items[0].menuItemDTOList
            }
            if (data?.items?.[0]?.cateringLogo) {
                setCateringLogo(data.items[0].cateringLogo)
            }
            setFirst(data.pageNumber * data.pageSize);
            setRows(data.pageSize);
            setTotalRecords(items.length)
            setMenuItems(items)
            setOriginalMenuItems(items)
            setLoadingTable(false)
            setShowProgressBar(false)
            setCategoriesOptions(items)
        })

    }

    const addToCart = (data) => {
        let alreadyAddedData = [...selectedMenuItems]
        if (alreadyAddedData.findIndex(x => x.id == data.id) == -1) {
            alreadyAddedData.push(data)
        }
        setSelectedMenuItems(alreadyAddedData)
    }

    const onPage = (event) => {
        let searchParams = { ...searchMenuItemParams }
        searchParams.pageNumber = event.page
        searchParams.pageSize = event.rows
        getAllMenuItems(searchParams)
    }
    let itemCategoryOptions = []
    if (itemCategories) {
        itemCategoryOptions = itemCategories.map(x => ({
            name: x.name, value: x.id, image: x.image
        }))
    }
    const itemCategoryButtonTemplate = (option) => {
        return <div className='grid'>
            <div className='col-12 text-center'>
                <img src={option.image ? "data:image/png;base64," + option.image : "assets/layout/images/blank_category.png"} alt={option.name} className="w-3rem" />

            </div>
            <div className='col-12 text-center'>
                <span className="font-semibold">{option.name}</span>
            </div>
            {/* <i className="pi pi-tag mr-2" /> */}
        </div>
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                {/* <Button icon = "pi pi-shopping-cart"/> */}
                <MenuOverlayPanel selectedMenuItems={selectedMenuItems} />
            </React.Fragment>
        )
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            {/* <h5 className="m-0">{t('manageMenuItems')}</h5> */}
            <InputText value={filterName} onInput={(e) => setFilterName(e.target.value)} placeholder={t("name")} className='lg:w-15rem md:w-15rem sm:w-full' />
            <Button icon="pi pi-search" label={t('search')} onClick={() => getAllMenuItems({ name: filterName })} />

        </div>
    );

    const paginationTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t('previous')}</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t('next')}</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: t('all'), value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        }
    };
    const gridItemTemplate = (data) => {
        return (
            <div className="col-12 md:col-3 lg:col-3 xl:col-3">
                <div className="card m-3 border-1 surface-border menu-item-wrapper p-3">
                    <div className="flex align-items-center justify-content-between h-2rem">
                        <div className="flex align-items-center">
                            {
                                data?.itemCategoryImage ? <img src={"data:image/png;base64," + data.itemCategoryImage} alt={data.itemCategoryName} className="h-2rem" style={{ objectFit: 'contain' }} />
                                    : <i className="pi pi-tag mr-2" />

                            }
                            <span className="font-semibold ml-1">{data.itemCategoryName}</span>
                        </div>
                        <div className='flex'>
                            <span className={`product-badge status`}>{data.isVegetarian ? <img src={"assets/layout/images/vegetarian.jpg"} alt={data.name} className="h-2rem" />
                                : <></>}</span>
                            <span className={`product-badge status`}>{data.isVegan ? <img src={"assets/layout/images/vegan.png"} alt={data.name} className="h-2rem" />
                                : <></>}</span>
                            <span className={`product-badge status`}>{data.isLenten ? <img src={"assets/layout/images/posno.svg"} alt={data.name} className="h-2rem" />
                                : <></>}</span>
                        </div>


                    </div>
                    <div className="text-center">
                        <div className='h-10rem'>
                            <img src={data.image ? "data:image/png;base64," + data.image : "assets/layout/images/blank_menu_item.jpg"} alt={data.name} className="h-8rem my-3 mx-0 w-full" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="text-2xl font-bold">{data.name}</div>
                        <div className="mb-3 h-3rem">{data.description}</div>
                        <div className="flex w-full justify-content-center mb-2">
                            <Rating value={data?.rating} cancel={false} />
                            <span className='ml-2 text-md font-bold'>{data?.rating && parseFloat(data?.rating) != NaN ? parseFloat(data?.rating).toFixed(1) : 0.0}</span>
                        </div>
                    </div>
                    <div className="flex justify-content-between">
                        <span className="product-price mt-3 text-blue-800">{data.price} RSD</span>

                        <div className="actions mt-2">
                            <Button icon="pi pi-plus" onClick={() => addToCart(data)}></Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="grid list-demo">
            <div className="col-12">
                <div className="card">
                    <div className='grid'>
                        <div className='col-12 text-center'>
                            {cateringLogo && <Image style={{ marginRight: '10px' }} src={"data:image/png;base64," + cateringLogo} alt={'uploadedImage'} className="shadow-2" width="150" zoomSrc={"data:image/png;base64," + cateringLogo} preview />}
                            {/* {cateringData?.name && <span> <h3>{cateringData.name}</h3></span>} */}
                        </div>
                    </div>
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    <SelectButton className='category-select-button' optionLabel="name" value={selectedItemCategory} itemTemplate={itemCategoryButtonTemplate} options={itemCategoriesForMenu} onChange={(e) => onSelectItemCategory(e.value)}></SelectButton>

                    <DataView ref={dt} value={menuItems} layout={layout} header={header}
                        itemTemplate={gridItemTemplate} dataKey="id" paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate={t('defaultPaginationMessage')} onPage={onPage}
                        emptyMessage={t('noItemsFound')} responsiveLayout="scroll" loading={loadingTable} />
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(CustomerPublicMenu, comparisonFn);
