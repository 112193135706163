import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";
import { StyleClass } from "primereact/styleclass";
import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../context";
import CustomerService from "../../service/CustomerService";
import { searchUserParams } from "../../service/serviceObjects";
import UserService from "../../service/UserService";
import { cateringAdmin, customerAdmin } from "../../constants/auth";
import { useTranslation } from "react-i18next";
import { isMobile } from "../../utilities/general";
import { useDispatch } from "react-redux";
import { setUserData } from "../../store/userSlice";
import { resetOrders } from "../../store/orderSlice";

const PublicUsersPanel = (props) => {
    const { setOtherUserId, otherUserId } = props
    const authContext = useContext(AuthContext);
    const { t } = useTranslation()
    const [users, setUsers] = useState([])
    const userService = new UserService();
    const dispatch = useDispatch()
    let customerId = 0
    if (authContext.customerId) {
        customerId = authContext.customerId
    }
    const getAllUsers = (searchData) => {
        let searchObject = searchUserParams
        if (searchData) {
            searchObject = { ...searchUserParams, ...searchData }
        }
        searchObject.pageSize = 100000
        searchObject.customerId = customerId
        userService.search(searchObject).then(data => {
            let userList = data.items.filter(x => x.id != authContext?.id)
            setUsers([{
                ...authContext,
                id: authContext?.id,
                name: authContext?.name + "(" + t('me') + ")"
            }, ...userList])
        })

    }
    useEffect(() => {
        getAllUsers()
    }, [])
    const btnRef13 = useRef()
    const btnRef14 = useRef()
    const returnMyEmployees = () => {
        let data = [
        ]
        if (users?.length > 0) {
            data = users.map(x => {
                return <li className="mb-2">
                    <a className="p-ripple flex align-items-center py-2 px-4 cursor-pointer hover:surface-200 transition-colors transition-duration-150" onClick={() => {
                       dispatch(setUserData(x))
                       dispatch(resetOrders())
                       setOtherUserId(x.id) 
                         }}>
                        <img src="assets/layout/images/user-avatar.png" className="mr-3 flex-shrink-0" style={{ width: '50px', height: '50px' }} alt="avatar-m-1" />
                         <div>
                            <span className="text-900 font-medium mb-2">{x.name}</span>
                        </div>
                        <i className="ml-auto pi pi-chevron-right text-700"></i>
                        <Ripple />
                    </a>
                </li>
            })
        }
        return data
    }
    return (
        <>
            <StyleClass nodeRef={btnRef13} selector="#slideover-6" enterClassName="hidden" enterActiveClassName="fadeinright" leaveToClassName="hidden" leaveActiveClassName="fadeoutright" hideOnOutsideClick >
                <Button ref={btnRef13} label={users?.find(x => x.id == otherUserId)?.name ?? t('me')} className="p-button-text px-4 py-3 bg-white bg-white hover:bg-white text-indigo-900" icon="pi pi-user" style={{ borderRadius: '30px' }} />
            </StyleClass>
            <div id="slideover-6" className="hidden surface-overlay h-full fixed top-0 right-0 shadow-2 w-20rem" style={{ zIndex: 10000 }}>
                <div className="flex flex-column h-full">
                    <div className="flex align-items-center justify-content-between p-4">
                        <div>
                            <div className="text-900 text-xl font-medium mb-1">{t('employees')}</div>
                            <p className="text-600 m-0">{users.length} {t('accounts')}</p>
                        </div>
                        <StyleClass nodeRef={btnRef14} selector="#slideover-6" leaveToClassName="hidden" leaveActiveClassName="fadeoutright">
                            <Button ref={btnRef14} icon="pi pi-times" className="p-button-rounded p-button-text p-button-plain" />
                        </StyleClass>
                    </div>
                    <div className="flex-auto overflow-y-auto">
                        <ul className="list-none p-0 m-0">
                            {returnMyEmployees()}
                        </ul>
                    </div>
                </div>
            </div>
        </>

    );
}

export default PublicUsersPanel;