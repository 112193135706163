import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { Message } from 'primereact/message';
import { Checkbox } from 'primereact/checkbox';
import { isMobile } from '../../utilities/general';

const CateringFormDialog = (props) => {
    const [uploadedImage, setUploadedImage] = useState(null);

    const { cateringDialog, hideDialog, saveCatering, updateCatering, cateringToEdit } = props
    let defaultValues = {
        name: '',
        email: '',
        phone: '',
        addressCountry: '',
        addressCity: '',
        addressStreetAndNumber: '',
        id: '',
        description: '',
        publicMenu: '',
    }
    const { t } = useTranslation();

    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues, mode: 'all' });

    useEffect(() => {
        if (cateringToEdit?.name) {
            setValue('name', cateringToEdit?.name)
        }
        if (cateringToEdit?.description) {
            setValue('description', cateringToEdit?.description)
        }
        if (cateringToEdit?.email) {
            setValue('email', cateringToEdit?.email)
        }
        if (cateringToEdit?.phone) {
            setValue('phone', cateringToEdit?.phone)
        }
        if (cateringToEdit?.addressCountry) {
            setValue('addressCountry', cateringToEdit?.addressCountry)
        }
        if (cateringToEdit?.addressCity) {
            setValue('addressCity', cateringToEdit?.addressCity)
        }
        if (cateringToEdit?.addressStreetAndNumber) {
            setValue('addressStreetAndNumber', cateringToEdit?.addressStreetAndNumber)
        }
        if (cateringToEdit?.id) {
            setValue('id', cateringToEdit?.id)
        }
        if (cateringToEdit?.publicMenu) {
            setValue('publicMenu', cateringToEdit?.publicMenu)
        }
        if (cateringToEdit?.profileImage) {
            setUploadedImage(cateringToEdit?.profileImage)
        }
    }, [cateringToEdit])
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const submitFunction = (data) => {
        if (uploadedImage) {
            data.profileImage = uploadedImage
        }
        if (cateringToEdit) {
            updateCatering(data)
        } else {
            saveCatering(data)
        }
        reset()
    }
    const onHideDialog = () => {
        hideDialog()
        reset()
    }
    const cateringDialogFooter = (
        <div className='grid text-right'>
            <div className='col-offset-5 md:col-offset-8 lg:col-offset-8 xl:col-offset-8 col-3 md:col-2'>
                <Button type="button" label={t('cancel')} icon="pi pi-times" className="p-button-text col-6" onClick={onHideDialog} />
            </div>
            <div className='col-3 md:col-2'>
                <Button label={t('save')} icon="pi pi-check" className="p-button-text col-6" type="submit" />
            </div>
        </div>
    );
    const customBase64Uploader = async (event) => {
        // convert file to base64 encoded
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then(r => r.blob()); //blob:url
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64data = reader.result;
            setUploadedImage(base64data.split(',')[1])
        }
    }
    return (
        <Dialog visible={cateringDialog} style={{ width: isMobile() ? '450px' : '800px' }} header={t('cateringForm')} modal className="p-fluid" onHide={onHideDialog}>
            <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                <div className='grid'>
                    <div className="field col-12 md:col-6 lg:col-6 xl-col-6 col-12 md:col-6 lg:col-6 xl-col-6">
                        <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>{t('name')}*</label>
                        <Controller name="name" control={control} rules={{ required: t('nameRequired') }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('name')}
                    </div>
                    <div className="field col-12 md:col-6 lg:col-6 xl-col-6">
                        <label htmlFor="description" className={classNames({ 'p-error': errors.description })}>{t('description')}*</label>
                        <Controller name="description" control={control} rules={{ required: t('descriptionRequired') }} render={({ field, fieldState }) => (
                            <InputTextarea id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('description')}
                    </div>
                    <div className="field col-12 md:col-6 lg:col-6 xl-col-6">
                        <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>{t('email')}*</label>
                        <Controller name="email" control={control} rules={{
                            required: t('emailRequired'), pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: t('invalidEmail')
                            }
                        }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('email')}
                    </div>
                    <div className="field col-12 md:col-6 lg:col-6 xl-col-6">
                        <label htmlFor="phone" className={classNames({ 'p-error': errors.phone })}>{t('phone')}*</label>
                        <Controller name="phone" control={control} rules={{ required: t('phoneRequired') }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('phone')}
                    </div>
                    <div className="field col-12 md:col-6 lg:col-6 xl-col-6">
                        <label htmlFor="addressCountry" className={classNames({ 'p-error': errors.addressCountry })}>{t('addressCountry')}*</label>
                        <Controller name="addressCountry" control={control} rules={{ required: t('addressCountryRequired') }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('addressCountry')}
                    </div>
                    <div className="field col-12 md:col-6 lg:col-6 xl-col-6">
                        <label htmlFor="addressCity" className={classNames({ 'p-error': errors.addressCity })}>{t('addressCity')}*</label>
                        <Controller name="addressCity" control={control} rules={{ required: t('addressCityRequired') }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('addressCity')}
                    </div>
                    <div className="field col-12 md:col-6 lg:col-6 xl-col-6">
                        <label htmlFor="addressStreetAndNumber" className={classNames({ 'p-error': errors.addressStreetAndNumber })}>{t('address')}*</label>
                        <Controller name="addressStreetAndNumber" control={control} rules={{ required: t('addressRequired') }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('addressStreetAndNumber')}
                    </div>
                    <div className="field col-12 md:col-6 lg:col-6 xl-col-6">
                        <label htmlFor="publicMenu">{t('publicMenu')}</label>
                        <Controller name="publicMenu" control={control} render={({ field, fieldState }) => (
                            <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} />
                        )} />
                    </div>
                    <div className='grid col-12 md:col-6 lg:col-6 xl-col-6'>
                        <div className='col-12 sm:col-12 md:col-6 lg:col-4 xl:col-4'>
                            <div className="field">
                                <label htmlFor="image" className={classNames({ 'p-error': errors.image })}>{t('image')}*</label>
                                <FileUpload maxFileSize={300000} mode="basic" id="image" accept="image/png, image/jpeg" onSelect={customBase64Uploader} />
                            </div>
                        </div>
                        <Message severity="info" text={t('acceptImageFormat')} className='w-full m-2' />
                        <div className='col-12 sm:col-12 md:col-6 lg:col-8 xl:col-8'>
                            {uploadedImage && <Image src={"data:image/png;base64," + uploadedImage} alt={'uploadedImage'} className="shadow-2" width="100" zoomSrc={"data:image/png;base64," + uploadedImage} preview />}
                        </div>

                    </div>
                </div>
                {cateringDialogFooter}
            </form>
        </Dialog>

    );
};

export default CateringFormDialog;