import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  
};

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    // Action to set all user data at once
    setUserData(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    // Action to update specific fields in user data
    updateUserField(state, action) {
      const { field, value } = action.payload;
      state[field] = value;
    },
    // Action to clear all user data (e.g., on logout)
    clearUserData(state) {
      return initialState;
    },
  },
});

// Export the actions generated by createSlice
export const { setUserData, updateUserField, clearUserData } = userDataSlice.actions;

// Export the reducer to be added to the store
export default userDataSlice.reducer;
