import { Skeleton } from 'primereact/skeleton';
import React from 'react';

const CateringBasicInfoSkeleton = () => {
    return ( 
        <div className="custom-skeleton p-4">
            <div className="flex mb-3">
                    <Skeleton width="7rem" className="mr-2"></Skeleton>
                    <Skeleton width="7rem" className="mr-2"></Skeleton>
                    <Skeleton width="7rem"></Skeleton>
            </div>
            <Skeleton width="100%" height="150px"></Skeleton>
        </div>
     );
}
 
export default CateringBasicInfoSkeleton;