import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { useTranslation } from 'react-i18next';

const CustomerFormDialog = (props) => {
    const { customerDialog, hideDialog, saveCustomer, updateCustomer, customerToEdit } = props
    let defaultValues = {
        name: '',
        email: '',
        phone: '',
        address: '',
        id: '',
        description: ''
    }
    const { t } = useTranslation();

    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });

    useEffect(() => {
        if (customerToEdit?.name) {
            setValue('name', customerToEdit?.name)
        }
        if (customerToEdit?.description) {
            setValue('description', customerToEdit?.description)
        }
        if (customerToEdit?.email) {
            setValue('email', customerToEdit?.email)
        }
        if (customerToEdit?.phone) {
            setValue('phone', customerToEdit?.phone)
        }
        if (customerToEdit?.address) {
            setValue('address', customerToEdit?.address)
        }
        if (customerToEdit?.id) {
            setValue('id', customerToEdit?.id)
        }
    }, [customerToEdit])
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const submitFunction = (data) => {
        if (customerToEdit) {
            updateCustomer(data)
        } else {
            saveCustomer(data)
        }
        reset()
    }
    const onHideDialog = () => {
        hideDialog()
        reset()
    }
    const customerDialogFooter = (
        <div className='grid text-right'>
            <div className='col-offset-5 col-3'>
                <Button type="button" label={t('cancel')} icon="pi pi-times" className="p-button-text col-6" onClick={onHideDialog} />
            </div>
            <div className='col-3'>
                <Button label={t('save')} icon="pi pi-check" className="p-button-text col-6" type="submit" />
            </div>
        </div>
    );
    return (
        <Dialog visible={customerDialog} style={{ width: '450px' }} header={t('customerForm')} modal className="p-fluid" onHide={onHideDialog}>
            <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                <div className="field">
                    <label htmlFor="name" className={classNames({ 'p-error': errors.name })}>{t('name')}*</label>
                    <Controller name="name" control={control} rules={{ required: t('nameRequired') }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('name')}
                </div>
                <div className="field">
                    <label htmlFor="description" className={classNames({ 'p-error': errors.description })}>{t('description')}*</label>
                    <Controller name="description" control={control} rules={{ required: t('descriptionRequired') }} render={({ field, fieldState }) => (
                        <InputTextarea id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('description')}
                </div>
                <div className="field">
                    <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>{t('email')}*</label>
                    <Controller name="email" control={control} rules={{ required: t('emailRequired'), pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message:t('invalidEmail')
                        } }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('email')}
                </div>
                <div className="field">
                    <label htmlFor="phone" className={classNames({ 'p-error': errors.phone })}>{t('phone')}*</label>
                    <Controller name="phone" control={control} rules={{ required: t('phoneRequired') }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('phone')}
                </div>
                <div className="field">
                    <label htmlFor="address" className={classNames({ 'p-error': errors.address })}>{t('address')}*</label>
                    <Controller name="address" control={control} rules={{ required: t('addressRequired') }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('address')}
                </div>
                {customerDialogFooter}
            </form>
        </Dialog>

    );
};

export default CustomerFormDialog;