import axios from "axios";
import { BLCUrl, defaultHeader } from "./serviceUtil";
const resourceName = "/overview"

export default class OverviewService {
    getCountOfEntities(searchData) {
        let searchParams = "?"
        if (searchData?.caterings) {
            searchParams += "&caterings=true"
        }
        if (searchData?.customers) {
            searchParams += "&customers=true"
        }
        if (searchData?.users) {
            searchParams += "&users=true"
        }
        if (searchData?.orders) {
            searchParams += "&orders=true"
        }
        if (searchData?.menuItems) {
            searchParams += "&menuItems=true"
        }
        if (searchData?.cateringId) {
            searchParams += "&cateringId=" + searchData.cateringId
        }
        return axios.get(BLCUrl + resourceName + "/count" + searchParams, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    getCountOfEntitiesByCustomer(searchData) {
        let searchParams = "?"

        if (searchData?.cateringId) {
            searchParams += "cateringId=" + searchData.cateringId
        }
        if (searchData?.startDate) {
            searchParams += "&minDate=" + searchData.startDate
        }
        if (searchData?.endDate) {
            searchParams += "&maxDate=" + searchData.endDate
        }
        return axios.get(BLCUrl + resourceName + "/count/customer" + searchParams, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
    getCountOfNormsByCatering(searchData) {
        let searchParams = "?"

        if (searchData?.cateringId) {
            searchParams += "cateringId=" + searchData.cateringId
        }
        if (searchData?.startDate) {
            searchParams += "&minDate=" + searchData.startDate
        }
        if (searchData?.endDate) {
            searchParams += "&maxDate=" + searchData.endDate
        }
        return axios.get(BLCUrl + resourceName + "/count/norm" + searchParams, { withCredentials: false, headers: defaultHeader }).then(res => res.data);
    }
}