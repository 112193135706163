import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const TransactionFilter = (props) => {
    const { searchTransactions, setCurrentSearchData } = props
    let defaultValues = {
        customerId: '',
        types: '',
        minDate: '',
        maxDate: '',
        transactionNumber: ''
    }
    const { t, i18n } = useTranslation();
    const { control, formState: { errors }, handleSubmit } = useForm({ defaultValues });

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const submitFunction = (data) => {
        setCurrentSearchData(data)
        searchTransactions(data)
    }
    useEffect(() => {


    }, [])

    // const isButtonDisabled = !customerId || !minDate || !maxDate || !type
    const isButtonDisabled = false

    return (
        <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
            <div className='grid'>

                <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="minDate" className={classNames({ 'p-error': errors.minDate })}>{t('minDate')}*</label>
                    <Controller name="minDate" control={control} rules={{ required: t('minDateRequired') }} render={({ field, fieldState }) => (
                        <Calendar dateFormat="dd/mm/yy" id={field.name} locale={i18n.language} value={field.value} onChange={(e) => field.onChange(e.value)} showIcon showTime />)} />
                    {getFormErrorMessage('minDate')}
                </div>
                <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="maxDate" className={classNames({ 'p-error': errors.maxDate })}>{t('maxDate')}*</label>
                    <Controller name="maxDate" control={control} rules={{ required: t('maxDateRequired') }} render={({ field, fieldState }) => (
                        <Calendar dateFormat="dd/mm/yy" id={field.name} locale={i18n.language} value={field.value} onChange={(e) => field.onChange(e.value)} showIcon showTime />)} />
                    {getFormErrorMessage('maxDate')}
                </div>
                <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="types" >{t('types')}</label>
                    <Controller name="types" control={control} render={({ field, fieldState }) => (
                        <MultiSelect display="chip" showClear id={field.name} {...field}
                            onChange={(event) => {
                                field.onChange(event)
                            }}
                            options={[
                                {
                                    label: t('PAYMENT_IN_PROGRESS'), value: 'PAYMENT_IN_PROGRESS'
                                },
                                {
                                    label: t('PAID_FROM_BANK'), value: 'PAID_FROM_BANK'
                                },
                                {
                                    label: t('PAID_FROM_CREDIT'), value: 'PAID_FROM_CREDIT'
                                },
                                {
                                    label: t('ADD_CREDIT'), value: 'ADD_CREDIT'
                                },
                                {
                                    label: t('CANCELED_WITH_REFUND'), value: 'CANCELED_WITH_REFUND'
                                },
                                {
                                    label: t('CANCELED_FROM_BANK'), value: 'CANCELED_FROM_BANK'
                                },

                            ]} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                </div>
                <div className="field col-12 md:col-12 lg:col-3">
                    <label htmlFor="transactionNumber">{t('transactionNumber')}</label>
                    <Controller name="transactionNumber" control={control} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} />
                    )} />
                </div>
            </div>
            <div className='grid'>
                <div className='col-6 md:col-6 lg:col-2'>
                    <Button disabled={isButtonDisabled} label={t("search")} icon="pi pi-search" className="p-button mt-4 col-3" type="submit" />
                </div>
            </div>
        </form>
    );
};

export default TransactionFilter;