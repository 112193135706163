import React, { useContext } from 'react';
import { adminRole, cateringAdmin, cateringOperator, customerAdmin, customerOperator, customerPublicUser, customerUser } from '../constants/auth';
import AuthContext from '../context';
import Dashboard from './Dashboard';
import DashboardCatering from './DashboardCatering';
import DashboardCustomerAdmin from './DashboardCustomerAdmin';
import DashboardCustomerUser from './DashboardCustomerUser';
import MenuOrderCard from '../pages/Order/MenuOrderCard';
import OrderVerification from '../pages/Order/OrderVerification';
import CustomerPublicMenu from '../pages/PublicView/CustomerPublicMenu';
import OrderCardVerification from '../pages/Order/OrderCardVerification';
const DashboardWrapper = () => {
    const authContext = useContext(AuthContext);
    if (authContext?.roleDTO?.name) {
        switch (authContext?.roleDTO?.name) {
            case adminRole:
                return <Dashboard />
            case cateringAdmin:
                return <DashboardCatering />
            case cateringOperator:
                return <OrderVerification />
            case customerAdmin:
                return <DashboardCustomerAdmin />
            case customerUser:
                return <DashboardCustomerUser />
            case customerOperator:
                return <OrderCardVerification />
            // case customerPublicUser:
            //     return <CustomerPublicMenu />
            default:
                return <></>
        }
    }
    return (
        <></>
    );
}

export default DashboardWrapper;