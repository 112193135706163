import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../context';
import CustomerService from '../../service/CustomerService';
import CateringBasicInfoSkeleton from '../Catering/CateringBasicInfoSkeleton';
import CustomerFormDialog from '../Catering/CustomerForm';
import CustomerBasicInfo from './CustomerBasicInfo';
import CustomerConfiguration from './CustomerConfiguration';
import CustomerUsers from './CustomerUsers';
import { adminRole, cateringAdmin, cateringOperator, customerAdmin, customerOperator } from '../../constants/auth';
import CustomersLocations from '../Catering/CustomerLocations';
import ExternalAuthConfiguration from './ExternalAuthConfiguration';

const CustomerOverview = (props) => {
    const { history } = props
    const authContext = useContext(AuthContext);

    let customerId = 0
    if (history?.location?.state?.id) {
        customerId = history.location.state.id
    } else if (authContext.customerId) {
        customerId = authContext.customerId
    }

    const [updateCustomerDialog, setUpdateCustomerDialog] = useState(false);
    const [customerData, setCustomerData] = useState(null)
    const [deleteCustomerDialog, setDeleteCustomerDialog] = useState(false);

    const customerService = new CustomerService();
    const { t } = useTranslation();
    const toast = useRef(null);
    const historyRouter = useHistory();

    useEffect(() => {
        getCustomerData()
    }, [])
    const getCustomerData = () => {
        customerService.get(customerId).then((data) => {
            setCustomerData(data)
        })
    }
    const hideDialog = () => {
        setUpdateCustomerDialog(false);
    }
    const updateCustomer = (data) => {
        if (data) {
            let submitRequest = { ...data }
            customerService.update(customerId, submitRequest).then((value) => {
                setUpdateCustomerDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('customerUpdated'), life: 3000 })
                getCustomerData()
            })
        }
    }
    const hideDeleteCustomerDialog = () => {
        setDeleteCustomerDialog(false);
    }
    async function deleteSelectedCustomer() {
        customerService.delete(customerId).then(data => {
            setDeleteCustomerDialog(false);
            historyRouter.goBack()
        })
    }
    const leftInfoToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("edit")} icon="pi pi-pencil" className="p-button-success mr-2" onClick={() => setUpdateCustomerDialog(true)} />
                </div>
            </React.Fragment>
        )
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div >
                    <Button label={t("delete")} icon="pi pi-trash" className="p-button-danger mr-2" onClick={() => setDeleteCustomerDialog(true)} />
                </div>
            </React.Fragment>
        )
    }
    const deleteCustomerDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteCustomerDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCustomer} />
        </>
    )
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-1" right={rightToolbarTemplate}></Toolbar>
                    {/* {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>} */}
                    {!customerData ? <CateringBasicInfoSkeleton />
                        :
                        <div className="card">
                            <div className='grid'>
                                <div className='col-12 text-center'>
                                    {customerData?.name && <span> <h3>{customerData.name}</h3></span>}
                                </div>
                            </div>
                            <TabView className="tabview-header-icon">
                                <TabPanel header={t("basicInformation")} headerClassName="w-12rem pr-2 pl-2 align-content-center ">
                                    <div className="grid crud-demo">
                                        <div className="col-12">
                                            <div className="card">
                                                <Toolbar className="mb-4" left={leftInfoToolbarTemplate}></Toolbar>
                                                <CustomerBasicInfo customer={customerData} />
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header={t("locations")} headerClassName="w-12rem pr-2 pl-2  align-content-center">
                                    <CustomersLocations customerData={customerData} getCustomerData={getCustomerData}/>
                                </TabPanel>
                                <TabPanel header={t("users")} headerClassName="w-12rem pr-2 pl-2  align-content-center">
                                    <CustomerUsers customerId={customerId} customerData={customerData}/>
                                </TabPanel>
                                {(authContext?.roleDTO?.name == customerAdmin || authContext?.roleDTO?.name == cateringAdmin || authContext?.roleDTO?.name == cateringOperator || authContext?.roleDTO?.name == adminRole) &&
                                    <TabPanel header={t("settings")} headerClassName="w-12rem pr-2 pl-2  align-content-center">
                                        <CustomerConfiguration customerId={customerId} customer={customerData} />
                                    </TabPanel>}
                                {(authContext?.roleDTO?.name == customerAdmin || authContext?.roleDTO?.name == cateringAdmin || authContext?.roleDTO?.name == cateringOperator || authContext?.roleDTO?.name == adminRole) &&
                                    <TabPanel header={t("externalAuth")} headerClassName="w-12rem pr-2 pl-2  align-content-center">
                                        <ExternalAuthConfiguration customerId={customerId} customer={customerData} />
                                    </TabPanel>}
                            </TabView>
                        </div>
                    }
                    {updateCustomerDialog && <CustomerFormDialog customerDialog={updateCustomerDialog} updateCustomer={updateCustomer} hideDialog={hideDialog} customerToEdit={customerData} />}                    <Dialog visible={deleteCustomerDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deleteCustomerDialogFooter} onHide={hideDeleteCustomerDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {customerData && <span>{t("areYouSureYouWantToDelete")} <b>{customerData.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CustomerOverview, comparisonFn);
