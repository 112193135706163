import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectUserUsername } from "../store/userSelectors";
import UserService from "../service/UserService";

const TimerComponent = () => {
    const [timeLeft, setTimeLeft] = useState(3 * 60); // 3 minutes in seconds
    const { t } = useTranslation()
    const username = useSelector(selectUserUsername);
    const usersService = new UserService();

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);

        return () => clearInterval(timer); // Cleanup interval on unmount
    }, []);
    const resendCode = (values) => {
            usersService.resendCode(username)
                .then(() => setTimeLeft(3 * 60))
                .catch(() => {

                })
                .finally(() => {
                })
        }
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
            .toString()
            .padStart(2, "0")}`;
    };

    return (
        <div className="text-gray-500">
            {timeLeft != 0 && < p > {t('codeIsValid') } {formatTime(timeLeft)} <a className="text-orange-400 cursor-pointer ml-2" onClick={()=>resendCode()}>{t('resendCode')}</a></p>}
            {timeLeft === 0 && <p className="cursor-pointer" style={{ color: "red" }} onClick={()=>resendCode()}>{t('resendCode')}</p>}
        </div >
    );
};

export default TimerComponent;
