import { Button } from 'primereact/button';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Ripple } from 'primereact/ripple';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../context';
import CateringService from '../../service/CateringService';
import { searchCateringParams } from '../../service/serviceObjects';
import { Rating } from 'primereact/rating';
import { useHistory } from 'react-router-dom';

const PublicViewComponent = () => {
    const authContext = useContext(AuthContext);
    const history = useHistory();

    const [caterings, setCaterings] = useState([]);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const [layout, setLayout] = useState('grid');
    const [filterName, setFilterName] = useState();
    const [val1, setVal1] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    const cateringService = new CateringService();

    const { t } = useTranslation();

    useEffect(() => {
        getAllPublicCaterings()
    }, []);

    const getAllPublicCaterings = (searchData) => {
        setLoadingTable(true)
        let searchObject = { ...searchCateringParams }
        if (searchData) {
            searchObject = { ...searchCateringParams, ...searchData }
        }
        searchObject.publicMenu = true;

        cateringService.search(searchObject).then(data => {
            setFirst(data.pageNumber * data.pageSize);
            setRows(data.pageSize);
            setTotalRecords(data.itemCount)
            setCaterings(data.items)
            setLoadingTable(false)
        })

    }

    const onPage = (event) => {
        let searchParams = { ...searchCateringParams }
        searchParams.pageNumber = event.page
        searchParams.pageSize = event.rows
        // getAllPublicCaterings(searchParams)
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
      
            </React.Fragment>
        )
    }


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            {/* <h5 className="m-0">{t('manageMenuItems')}</h5> */}
            {/* <SelectButton optionLabel="name" value={selectedItemCategory} itemTemplate={itemCategoryButtonTemplate} options={itemCategoryOptions} onChange={(e) => onSelectItemCategory(e.value)}></SelectButton> */}
            <InputText value={filterName} onInput={(e) => setFilterName(e.target.value)} placeholder={t("name")} className='lg:w-15rem md:w-15rem sm:w-full' />
            <Button icon="pi pi-search" label={t('search')} onClick={() => getAllPublicCaterings({ name: filterName })} />

        </div>
    );


    const paginationTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t('previous')}</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t('next')}</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: t('all'), value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        }
    };
    const gridItemTemplate = (data) => {
        return (
            <div className="col-12 md:col-3 lg:col-3 xl:col-3 cursor-pointer" onClick={() => history.push({
                pathname: '/exploreMenu',
                state: { ...data }
            })}>
                <div className="card m-3 border-1 surface-border menu-item-wrapper">
                    <div className="flex align-items-center justify-content-between">
                        <div className="flex align-items-center">
                            <i className="pi pi-building mr-2" />
                            <span className="font-semibold">{data.addressCity + ", " + data.addressCountry}</span>
                        </div>
                    </div>
                    <div className="text-center">
                        <div className='h-10rem'>
                            <img src={data.profileImage ? "data:image/png;base64," + data.profileImage : "assets/layout/images/blank_catering.jpg"} alt={data.name} className="h-8rem shadow-2 my-3 mx-0 w-full" style={{ objectFit: 'contain' }} />
                        </div>
                        <div className="flex w-full justify-content-center mb-2">
                            <Rating value={val1} cancel={false} onChange={(e) => setVal1(e.value)} />
                            <span className='ml-2 text-md font-bold'>{val1 && parseFloat(val1) != NaN ? parseFloat(val1).toFixed(1) : 0.0}</span>
                        </div>
                        <div className="text-2xl font-bold">{data.name}</div>
                        <div className="mb-3">{data.description}</div>
                    </div>
                    <div className="flex align-items-center justify-content-center">

                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="grid list-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    <DataView ref={dt} value={caterings} layout={layout} header={header}
                        itemTemplate={gridItemTemplate} dataKey="id" paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate={t('defaultPaginationMessage')} onPage={onPage}
                        emptyMessage={t('noItemsFound')} responsiveLayout="scroll" loading={loadingTable} />
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(PublicViewComponent, comparisonFn);
