import { createSlice } from '@reduxjs/toolkit';

// Initial state for the UI slice
const initialState = {
    displayLoader: false, // Loader flag for showing/hiding the loader
};

// UISlice definition
const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        // Action to show the loader
        showLoader: (state) => {
            state.displayLoader = true;
        },
        // Action to hide the loader
        hideLoader: (state) => {
            state.displayLoader = false;
        },
        // Action to toggle the loader (for flexibility)
        toggleLoader: (state) => {
            state.displayLoader = !state.displayLoader;
        },
    },
});

// Export actions generated by createSlice
export const { showLoader, hideLoader, toggleLoader } = uiSlice.actions;

// Export the reducer to be included in the store
export default uiSlice.reducer;
