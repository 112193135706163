import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import CustomerService from "../../service/CustomerService";
import { useTranslation } from "react-i18next";
import { classNames } from "primereact/utils";
import { useRef, useState } from "react";
import { ProgressBar } from "primereact/progressbar";
import { Message } from "primereact/message";
import RegisterPublicUser from "../../pages/Users/RegisterPublicUser";
import UserService from "../../service/UserService";
import { selectUserUsername } from "../../store/userSelectors";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { Steps } from "primereact/steps";
import { SelectButton } from "primereact/selectbutton";
import { createCookie, getCookie } from "../../utilities/general";
import i18n from "../../i18n";
import enImage from "../../assets/flags/en.jpg";
import srbImage from "../../assets/flags/srb.png";
import TimerComponent from "../TimerComponent";
const RegisterWithCode = () => {
    const [showProgressBar, setShowProgressBar] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [accountConfirmed, setAccountConfirmed] = useState()
    const [currentCustomer, setCurrentCustomer] = useState()
    const [activeIndex, setActiveIndex] = useState(0);
    const [langValue, setLangValue] = useState(getCookie('lang') || "srb");

    const toast = useRef(null);

    const customersService = new CustomerService();
    const usersService = new UserService();
    const username = useSelector(selectUserUsername);
    const { t } = useTranslation();
    const changeLanguage = (lng) => {
        createCookie('lang', lng)
        setLangValue(lng)
        i18n.changeLanguage(lng);
    }
    const langTemplate = (option) => {
        return <img src={option.img} alt={"langImage"} width="20" height="10" />;

    }
    const langOptions = [
        { img: srbImage, value: 'srb' },
        { img: enImage, value: 'en' },
    ];
    let defaultValues = {
        registerKey: '', confirmationCode: ''
    }

    const {
        control, formState: { errors }, handleSubmit, setError, reset
    } = useForm({ defaultValues: defaultValues, mode: 'all' });

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const items = [{
        label: t("company")
    }, {
        label: t("register")
    }, {
        label: t("confirm")
    }];

    const nextStep = () => {
        if (activeIndex !== items.length - 1) {
            setActiveIndex(activeIndex + 1)
        }
    }

    const previousStep = () => {
        if (activeIndex !== 0) {
            setActiveIndex(activeIndex - 1)
        }
    }

    const renderPublicRegistrationSteps = () => {
        switch (activeIndex) {
            case 0:
                return registerKeyCheckStep()
            case 1:
                return publicRegistrationFormStep()
            case 2:
                return accountConfirmationStep()
            default:
                return registerKeyCheckStep()
        }
    }

    const registerKeyCheckStep = () => {
        return <div className="mt-3">
            <p className="text-gray-600">1. {t('registerWithCodeMessage1')}</p>
            <p className="text-gray-600">2. {t('registerWithCodeMessage2')}</p>
            <p className="text-gray-600">3. {t('registerWithCodeMessage3')}</p>
            <p className="text-gray-600">4. {t('registerWithCodeMessage4')}</p>
            <form onSubmit={handleSubmit(checkKey)} className="p-fluid">
                <div className="field my-4 px-8">
                    <label htmlFor="registerKey" className={classNames({ 'p-error': errors.name })}>{t('registerKey')}*</label>
                    <Controller name="registerKey" control={control} rules={{ required: t('registerKeyRequired') }} render={({ field, fieldState }) => (<InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />)} />
                    {getFormErrorMessage('registerKey')}
                </div>

                <div className='flex justify-content-between px-5'>
                    <Button className='loginBtn' label={t('check')} icon="pi pi-check" />
                </div>
            </form>
        </div>
    };

    const publicRegistrationFormStep = () => {
        return <div className="mt-3">
            <RegisterPublicUser customerId={currentCustomer?.id} onSuccess={nextStep} />
        </div>
    };

    const accountConfirmationStep = () => {
        // todo use confirmation component from prime blocks
        return <div className="mt-3">
            <form onSubmit={handleSubmit(confirmPublicAccount)} className="p-fluid">
                <div className="field my-6 px-8">
                    <label htmlFor="confirmationCode" className={classNames({ 'p-error': errors.name })}>{t('confirmationCode')}*</label>
                    <Controller name="confirmationCode" control={control} rules={{ required: t('confirmationCodeRequired') }} render={({ field, fieldState }) => (<InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />)} />
                    {getFormErrorMessage('confirmationCode')}
                    <TimerComponent />
                </div>

                <div className='flex justify-content-between'>
                    <Button className='loginBtn' label={t('confirm')} icon="pi pi-check" />
                </div>
            </form>
        </div>
    };

    const proceedToLoginStep = () => {
        // todo when I login I need to enter card number but it was not mandatory during register?
        return <div className="surface-0 text-700 text-center">
            <img src="assets/layout/images/LOGO.svg" alt="hyper" height={90} className="mb-3" />
            <hr />
            <div className="text-blue-600 font-bold mb-3"><i className="pi pi-user text-5xl text-green-300 my-3"></i>
                <div className="text-900 font-bold text-5xl mb-3 text-green-500">{t('accountCreated')}</div>
                <Button label={t('login')} onClick={() => window.location = "/"} icon="pi pi-sign-in" className="font-bold px-5 py-3 p-button-text white-space-nowrap" />
            </div>
        </div>
    };

    const checkKey = (values) => {
        if (values?.registerKey) {
            setShowProgressBar(true)
            setErrorMessage()
            customersService.getCustomerByRegisterKey(values?.registerKey)
                .then((response) => {
                    if (response) {
                        setCurrentCustomer(response)
                        nextStep()
                    } else {
                        setErrorMessage(t('keyIsNotValid'))
                    }
                })
                .catch(() => {
                    setErrorMessage(t('keyIsNotValid'))
                })
                .finally(() => {
                    setShowProgressBar()
                })
        } else {
            // setDomainInvalid(false)
        }
    };

    const confirmPublicAccount = (values) => {
        if (values?.confirmationCode) {
            setShowProgressBar(true)
            setErrorMessage()
            usersService.confirmPublicAccount(username, values?.confirmationCode)
                .then(() => setAccountConfirmed(true))
                .catch(() => {
                    setErrorMessage(t('confirmationCodeIsNotValid'))
                })
                .finally(() => {
                    setShowProgressBar()
                })
        } else {
            // setDomainInvalid(false)
        }
    };

    return (<div className="surface-card p-4 m-2 shadow-2 border-round w-full lg:w-4">
        <Toast ref={toast}></Toast>
        <div className="text-center mb-5">
            {!accountConfirmed &&
                <div>
                    <img src="assets/layout/images/LOGO.svg" alt="hyper" height={90} className="mb-3" />
                    <div className="text-900 text-3xl font-medium mb-3">{t('registerWithCode')}</div>
                    {currentCustomer && <div className="text-900 text-3xl font-medium mb-3"><i className="pi pi-building text-3xl" /> {currentCustomer?.name}</div>}
                    <div className="steps-demo">
                        <div className="card">
                            {
                                <>
                                    <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={true} />
                                    {renderPublicRegistrationSteps()}
                                </>
                            }
                        </div>
                    </div>

                </div>}
            <div>
                {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                {errorMessage && <Message severity="error" text={t(errorMessage)} className='w-full' />}
                {accountConfirmed && proceedToLoginStep()}
            </div>
        </div>
        <div className='flex justify-content-between px-5 pt-3'>
            <Button className='p-button-text' type="button" label={t('backToLogin')} icon="pi pi-sign-in" onClick={() => window.location = "/"} />
        </div>
        <SelectButton value={langValue} options={langOptions} onChange={(e) => changeLanguage(e.value)} itemTemplate={langTemplate} optionLabel="value" className='text-right my-5' />

    </div>);
}

export default RegisterWithCode;
