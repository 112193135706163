import moment from 'moment';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Ripple } from 'primereact/ripple';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../context';
import CateringService from '../../service/CateringService';
import LicenseService from '../../service/LicenseService';
import { searchLicenseParams } from '../../service/serviceObjects';
import LicenseForm from './LicenseForm';
const LicenseTask = () => {
    const authContext = useContext(AuthContext);

    let license = {
        cateringId: authContext.cateringId || 0,
        name: null,
        description: null
    }
    const [licenseDialog, setLicenseDialog] = useState(false);
    const [deleteLicenseDialog, setDeleteLicenseDialog] = useState(false);
    const [deleteLicensesDialog, setDeleteLicensesDialog] = useState(false);
    const [licenses, setLicenses] = useState([]);
    const [selectedLicense, setSelectedLicense] = useState(null);
    const [licenseEdit, setLicenseEdit] = useState(null)
    const [licenseDelete, setLicenseDelete] = useState(null)
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const [caterings, setCaterings] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);
    const licenseService = new LicenseService();
    const cateringService = new CateringService();

    const { t } = useTranslation();
    const getAllCaterings = () => {
        cateringService.search({
            pageNumber: 0,
            pageSize: 1000
        }).then(data => {
            setCaterings(data.items)

        })
    }
    useEffect(() => {
        getAllLicenses()
        getAllCaterings()
    }, []);
    const getAllLicenses = (searchData) => {
        setLoadingTable(true)
        let searchObject = searchLicenseParams
        if (searchData) {
            searchObject = { ...searchLicenseParams, ...searchData }
        }
        searchObject.cateringId = authContext.cateringId ?? 0
        licenseService.search(searchObject).then(data => {
            setFirst(data.pageNumber * data.pageSize);
            setRows(data.pageSize);
            setTotalRecords(data.itemCount)
            setLicenses(data.items)
            setLoadingTable(false)
        })

    }
    const openNew = () => {
        setLicenseDialog(true);
    }

    const hideDialog = () => {
        if (licenseEdit) {
            setLicenseEdit()
        }
        setLicenseDialog(false);
    }

    const hideDeleteLicenseDialog = () => {
        setLicenseDelete();
        setDeleteLicenseDialog(false);
    }

    const hideDeleteLicensesDialog = () => {
        setDeleteLicensesDialog(false);
    }
    const saveLicense = (data) => {
        if (data) {
            let submitRequest = { ...license, ...data }
            submitRequest.cateringDTO = {
                id: data.catering
            }
            licenseService.save(submitRequest).then((value) => {
                setLicenseDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('licenseAdded'), life: 3000 })
                getAllLicenses()
            }).catch(reason => {
                setLicenseDialog(false)
                toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorSavingLicense'), life: 3000 })
            })
        }
    }
    const updateLicense = (data) => {
        if (data) {
            let submitRequest = { ...license, ...data }
            licenseService.update(submitRequest.id, submitRequest).then((value) => {
                setLicenseDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('licenseUpdated'), life: 3000 })
                getAllLicenses()
            })
        }
    }
    const editLicense = (item) => {
        setShowProgressBar(true)
        licenseService.get(item.id).then((data) => {
            setShowProgressBar(false)
            setLicenseEdit(data)
            setLicenseDialog(true);
        })
    }

    const confirmDeleteLicense = (item) => {
        setLicenseDelete(item);
        setDeleteLicenseDialog(true);
    }

    const deleteLicense = () => {
        licenseService.delete(licenseDelete.id).then(data => {
            setLicenseDelete(null);
            setDeleteLicenseDialog(false);
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('licenseDeleted'), life: 3000 });
            getAllLicenses()
        })
    }

    const confirmDeleteSelected = () => {
        setDeleteLicensesDialog(true);
    }

    async function deleteSelectedLicense() {
        await selectedLicense.forEach(element => {
            licenseService.delete(element.id).then(data => {
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('licenseDeleted'), life: 3000 });
            })
        });
        setDeleteLicensesDialog(false);
        setSelectedLicense(null);
        getAllLicenses()

    }
    const onPage = (event) => {
        let searchParams = searchLicenseParams
        searchParams.pageNumber = event.page
        searchParams.pageSize = event.rows
        getAllLicenses(searchParams)
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("new")} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label={t("delete")} icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedLicense || !selectedLicense.length} />
                </div>
            </React.Fragment>
        )
    }

    const cateringBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("catering")}</span>
                {rowData?.cateringDTO?.name}
            </>
        );
    }

    const licenseKeyBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("licenseKey")}</span>
                <span className={"text-400"}>{rowData.licenseKey}</span>
            </>
        )
    }
    const statusBodyTemplate = (rowData) => {
        const returnClass = (status) => {
            if (status === "VALID") {
                return "text-green-400"
            } else if (status === "EXPIRED") {
                return "text-orange-300"

            } else if (status === "SUSPENDED") {
                return "text-red-300"
            }
        }
        return (
            <>
                <span className="p-column-title">{t("status")}</span>
                <span className={returnClass(rowData.status)}>{t(rowData.status)}</span>
            </>
        )
    }
    const expiryDateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("expiryDate")}</span>
                <span className={`status`}>{moment(rowData.dateActiveTo).format("DD/MM/YYYY").toString()}</span>
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions mt-2 text-center">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editLicense(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteLicense(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t("manageLicenses")}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t("search")} />
            </span>
        </div>
    );

    const deleteLicenseDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteLicenseDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteLicense} />
        </>
    );
    const deletLicensesDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteLicensesDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedLicense} />
        </>
    );
    const paginationTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("previous")}</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("next")}</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'All', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        }
    };
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    <DataTable ref={dt} value={licenses} selection={selectedLicense} onSelectionChange={(e) => setSelectedLicense(e.value)}
                        dataKey="id" paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate={t('defaultPaginationMessage')} onPage={onPage}
                        globalFilter={globalFilter} emptyMessage={t('noItemsFound')} header={header} responsiveLayout="scroll" loading={loadingTable}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="catering" header={t("catering")} body={cateringBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="licenseKey" header={t("licenseKey")} body={licenseKeyBodyTemplate} headerStyle={{ width: '30%', minWidth: '10rem' }}></Column>
                        <Column field="status" header={t("status")} body={statusBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column field="expiryDate" header={t("expiryDate")} body={expiryDateBodyTemplate} headerStyle={{ width: '20%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                    </DataTable>

                    <LicenseForm caterings={caterings} licenseDialog={licenseDialog} saveLicense={saveLicense} updateLicense={updateLicense} hideDialog={hideDialog} licenseToEdit={licenseEdit} />
                    {deleteLicenseDialog && <Dialog visible={deleteLicenseDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deleteLicenseDialogFooter} onHide={hideDeleteLicenseDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {licenseDelete && <span>{t("areYouSureYouWantToDelete")} <b>{licenseDelete.name}</b>?</span>}
                        </div>
                    </Dialog>}
                    <Dialog visible={deleteLicensesDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deletLicensesDialogFooter} onHide={hideDeleteLicensesDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {selectedLicense && <span>{t("areYouSureYouWantToDelete")}?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(LicenseTask, comparisonFn);
