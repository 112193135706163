import moment from "moment";
import { sortArray } from "./general";
export const truncateDescriptionString = (str, maxLength = 50) => {
    return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
};
export const filterMenuItemSequencesByCategory = (order, categoryName) => {
    // Use the filter method to return only items that match the category name
    return order.menuItemSequences.filter(item =>
        item.menuItemDTO.itemCategoryName === categoryName
    );
};
export const returnAllMenuItemsForOrder = (orderData) => {
    let menuItems = []
    orderData?.forEach(element => {
        menuItems = [...menuItems, ...element?.menuItemSequences]
    });
    return menuItems
}
export const calculateOrderPrice = (menuItems, credit) => {
    let price = 0
    if (menuItems?.length > 0) {
        menuItems.forEach(element => {
            price += (element.price * element.amount)
        });
    }
    if(credit){
        return price - credit
    }
    return price
}
export const calculateOrderAmountPrice = (orderItems) => {
    let price = 0
    if (orderItems?.length > 0) {
        orderItems.forEach(element => {
            price += element.amountPrice
        });
    }

    return price
}
export const groupMenuItemsByCategoryId = (menuItems) => {
    let categories = []
    if (menuItems?.length > 0) {
        menuItems = sortArray(menuItems, 'itemCategoryId')
        menuItems.forEach(element => {
            if (!categories.includes(element.itemCategoryId)) {
                categories.push(element.itemCategoryId)
            }
        });
        let groupedItems = categories.map(element => {
            let arrayOfSingleElement = menuItems.filter(x => x.itemCategoryId === element)
            return {
                categoryId: element, elements: arrayOfSingleElement
            }
        });
        return groupedItems;
    }
    return []
}
export const returnMenuForSpecificDate = (menuList, date) => {
    if (date) {
        let filteredMenu = menuList.filter((x) => {
            let currentDate = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD").toString();
            let dateFromMenu = moment(x.menuDate).format("YYYY-MM-DD").toString();
            return currentDate === dateFromMenu

        })
        if (filteredMenu?.length > 0) {
            return filteredMenu[0] || []
        }
    }
}
export const groupMenuByCategory = (menuItems) => {
    let categories = []
    if (menuItems?.length > 0) {
        menuItems = sortArray(menuItems, 'itemCategoryId')
        menuItems.forEach(element => {
            if (!categories.includes(element.itemCategoryName)) {
                categories.push(element.itemCategoryName)
            }
        });
        let groupedItems = categories.map(element => {
            let arrayOfSingleElement = menuItems.filter(x => x.itemCategoryName === element)
            return {
                categoryName: element, elements: arrayOfSingleElement
            }
        });
        return groupedItems;
    }
    return []
}
export const categorizeOrderMenuItems = (order) => {
    // Create a map to store categories and their respective menu items
    let categories = {};

    // Iterate over each menuItemSequence in the order and categorize
    order?.menuItemSequences?.forEach(item => {
        const categoryName = item.menuItemDTO.itemCategoryName;

        // Initialize the category in the map if it doesn't exist
        if (!categories[categoryName]) {
            categories[categoryName] = {
                items: [],
                categoryId: item.menuItemDTO.itemCategoryId // Store categoryId for sorting later
            };
        }

        // Add the item to the corresponding category
        categories[categoryName].items.push({
            id: item.id,
            name: item.name,
            price: item.price,
            amount: item.amount,
            menuItemDTO: item.menuItemDTO
        });
    });

    // Convert the categories object into an array and sort by itemCategoryId
    return Object.entries(categories)
        .sort(([, a], [, b]) => a.categoryId - b.categoryId) // Sort by categoryId
        .map(([categoryName, { items }]) => ({
            categoryName,
            menuItems: items
        }));
};

export const getNumberOfMenuItemSequences = (orders, day, categoryName) => {
    // Find the order for the given day
    const orderForDay = orders?.find(order => order.date === day);
    if (orderForDay) {
        // Filter the sequences for the given category and return the count
        const sequencesForCategory = orderForDay?.menuItemSequences.filter(item =>
            item.menuItemDTO.itemCategoryName === categoryName
        );
        // return sequencesForCategory.length;
        const totalAmount = sequencesForCategory.reduce((sum, item) => sum + item.amount, 0);
        return totalAmount
    }

    return 0; // If no order exists for the day or category
};
export function sortMenuItemSequences(array){
    console.log(array, 'array');
    return [...array].sort((a,b) => a.menuItemDTO.itemCategoryId - b.menuItemDTO.itemCategoryId);
 
 }