import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { TabPanel, TabView } from 'primereact/tabview';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../context';
import CateringService from '../../service/CateringService';
import CateringBasicInfo from './CateringBasicInfo';
import CateringBasicInfoSkeleton from './CateringBasicInfoSkeleton';
import CateringFormDialog from './CateringForm';
import CateringLicenseInfo from './CateringLicenseInfo';
import CateringUsers from './CateringUsers';
import Customers from './Customers';

const CateringOverview = (props) => {
    const { history } = props
    const authContext = useContext(AuthContext);

    let cateringId = 0
    if (history?.location?.state?.id) {
        cateringId = history.location.state.id
    } else if (authContext.cateringId) {
        cateringId = authContext.cateringId
    }
    const [updateCateringDialog, setUpdateCateringDialog] = useState(false);
    const [cateringData, setCateringData] = useState(null)
    const [deleteCateringDialog, setDeleteCateringDialog] = useState(false);

    const cateringService = new CateringService();
    const { t } = useTranslation();
    const toast = useRef(null);
    const historyRouter = useHistory();

    useEffect(() => {
        getCateringData()
    }, [])
    const getCateringData = () => {
        cateringService.get(cateringId).then((data) => {
            setCateringData(data)
        })
    }
    const hideDialog = () => {
        setUpdateCateringDialog(false);
    }
    const updateCatering = (data) => {
        if (data) {
            let submitRequest = { ...data }
            cateringService.update(cateringId, submitRequest).then((value) => {
                setUpdateCateringDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('cateringUpdated'), life: 3000 })
                getCateringData()
            })
        }
    }
    const hideDeleteCateringDialog = () => {
        setDeleteCateringDialog(false);
    }
    async function deleteSelectedCatering() {
        cateringService.delete(cateringId).then(data => {
            setDeleteCateringDialog(false);
            historyRouter.goBack()
        })
    }
    const leftInfoToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("edit")} icon="pi pi-pencil" className="p-button-success mr-2" onClick={() => setUpdateCateringDialog(true)} />
                </div>
            </React.Fragment>
        )
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div >
                    <Button label={t("delete")} icon="pi pi-trash" className="p-button-danger mr-2" onClick={() => setDeleteCateringDialog(true)} />
                </div>
            </React.Fragment>
        )
    }

    const deleteCateringDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteCateringDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCatering} />
        </>
    )
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-1" right={rightToolbarTemplate}></Toolbar>
                    {/* {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>} */}
                    {!cateringData ? <CateringBasicInfoSkeleton />
                        :
                        <div className="card">
                            <div className='grid'>
                                <div className='col-12 text-center'>
                                    {cateringData?.profileImage && <Image style={{ borderRadius: '50%', marginRight: '10px' }} src={"data:image/png;base64," + cateringData.profileImage} alt={'uploadedImage'} width="150" zoomSrc={"data:image/png;base64," + cateringData.profileImage} preview />}
                                    {cateringData?.name && <span> <h3>{cateringData.name}</h3></span>}
                                </div>
                            </div>
                            <TabView className="tabview-header-icon">
                                <TabPanel header={t("basicInformation")} headerClassName="w-12rem pr-2 pl-2 align-content-center ">
                                    <div className="grid crud-demo">
                                        <div className="col-12">
                                            <div className="card">
                                                <Toolbar className="mb-4" left={leftInfoToolbarTemplate}></Toolbar>
                                                <CateringBasicInfo catering={cateringData} />
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel header={t("license")} headerClassName="w-12rem pr-2 pl-2  align-content-center">
                                    <CateringLicenseInfo cateringId={cateringId} />
                                </TabPanel>
                                <TabPanel header={t("customers")} headerClassName="w-12rem pr-2 pl-2  align-content-center">
                                    <Customers cateringId={cateringId} />
                                </TabPanel>
                                <TabPanel header={t("users")} headerClassName="w-12rem pr-2 pl-2  align-content-center">
                                    <CateringUsers cateringId={cateringId} />
                                </TabPanel>
                            </TabView>
                        </div>
                    }
                    {updateCateringDialog && <CateringFormDialog cateringDialog={updateCateringDialog} updateCatering={updateCatering} hideDialog={hideDialog} cateringToEdit={cateringData} />}                    <Dialog visible={deleteCateringDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deleteCateringDialogFooter} onHide={hideDeleteCateringDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {cateringData && <span>{t("areYouSureYouWantToDelete")} <b>{cateringData.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(CateringOverview, comparisonFn);
