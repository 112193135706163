import moment from 'moment/moment';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../context';
import CustomerService from '../../service/CustomerService';
import MenuService from '../../service/MenuService';
import OrderService from '../../service/OrderService';
import { isMobile, sortArray } from '../../utilities/general';
import { searchMenuParams } from '../../service/serviceObjects';

let singleOrder = {
    date: null,
    dateOriginal: null,
    menuItems: [],
    customerId: 0,
    cateringId: 0,
    userId: 0,
    shift: 1,
    status: "CREATED"
    // deliveryTime: "15:00"
}
const MenuOrder = (props) => {
    // initial state
    const { userData } = props
    const toast = useRef(null);
    const menuService = new MenuService();
    const orderService = new OrderService();
    const customerService = new CustomerService();
    const [firstDayOfWeek, setFirstDayOfWeek] = useState();
    const [lastDayOfWeek, setLastDayOfWeek] = useState();
    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [menusForCurrentWeek, setMenusForCurrentWeek] = useState([]);
    const [currentMenuItems, setCurrentMenuItems] = useState([]);
    const [currentMenuItemsDate, setCurrentMenuItemsDate] = useState();
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const [orderConfiguration, setOrderConfiguration] = useState();
    const [selectedShifts, setSelectedShifts] = useState([]);

    const { t } = useTranslation();
    const authContext = useContext(AuthContext);
    const div = useRef();

    let userId = authContext.id
    if (userData?.id) {
        userId = userData.id
    }
    const groupMenuByCategory = (menuItems) => {
        let categories = []
        if (menuItems?.length > 0) {
            menuItems = sortArray(menuItems, 'itemCategoryId')
            menuItems.forEach(element => {
                if (!categories.includes(element.itemCategoryName)) {
                    categories.push(element.itemCategoryName)
                }
            });
            let groupedItems = categories.map(element => {
                let arrayOfSingleElement = menuItems.filter(x => x.itemCategoryName === element)
                return {
                    categoryName: element, elements: arrayOfSingleElement
                }
            });
            return groupedItems;
        }
        return []
    }

    useLayoutEffect(() => {
        if (isMobile()) {
            const divAnimate = div.current.getBoundingClientRect().top;
            let divStyle = div?.current?.style
            const onScroll = () => {
                if (divAnimate < window.scrollY) {
                    div.current.style.position = "fixed";
                    div.current.style.top = 60;
                    div.current.style.right = 0;
                    div.current.style.marginRight = "30px";
                    div.current.style.zIndex = 1000;

                } else {
                    div.current.style = divStyle
                }
            };
            window.addEventListener("scroll", onScroll);
            return () => window.removeEventListener("scroll", onScroll);
        }

    }, []);

    const saveChanges = () => {
        setShowProgressBar(true)
        setDisableSaveButton(true)
        let dataForServer = selectedOrders.map(x => ({
            ...x, date: moment(x.date, "DD/MM/YYYY").format("YYYY-MM-DD").toString(), shift: selectedShiftForDate(x.date)
        }))
        orderService.save(dataForServer).then(data => {
            // setMenusForCurrentWeek()
            setSelectedOrders()
            // getActiveMenus(firstDayOfWeek, lastDayOfWeek)
            getActiveOrders(firstDayOfWeek, lastDayOfWeek)
            setShowProgressBar(false)
            setDisableSaveButton(false)
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('orderSaved'), life: 3000 })
        }).catch(reason => {
            setDisableSaveButton(false)
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringorderSave'), life: 3000 })
        })
    }
    const getActiveMenus = (first, last) => {
        let searchObject = {
            ...searchMenuParams,
            minDate: moment(first, "DD/MM/YYYY").format("YYYY-MM-DD").toString(),
            maxDate: moment(last, "DD/MM/YYYY").format("YYYY-MM-DD").toString(),
            customerId: authContext?.customerId
        }
        if (authContext?.roleDTO.name.includes('catering') && authContext.cateringId) {
            searchObject.cateringId = authContext.cateringId
        }
        setShowProgressBar(true)
        menuService.search(searchObject).then(data => {
            setShowProgressBar(false)
            setMenusForCurrentWeek(data.items)
        })
    }
    const removeOrderForDate = (date, data) => {
        let selectedOrderMenuItemsForCurrentDate = selectedOrders?.filter(x => x.date === date)?.[0]?.menuItems
        let indexOfOrderForDate = selectedOrders.findIndex(x => x.date === date)
        if (selectedOrderMenuItemsForCurrentDate && indexOfOrderForDate != -1) {
            let selectedOrderForCurrentDateForCategory = selectedOrderMenuItemsForCurrentDate.filter(x => x.itemCategoryName !== data.categoryName)
            let newOrders = [...selectedOrders]
            newOrders[indexOfOrderForDate] = { ...newOrders[indexOfOrderForDate], menuItems: selectedOrderForCurrentDateForCategory }
            setSelectedOrders(newOrders)
        }
    }
    const getActiveOrders = (first, last) => {
        let searchObject = {
            minDate: moment(first, "DD/MM/YYYY").format("YYYY-MM-DD").toString(),
            maxDate: moment(last, "DD/MM/YYYY").format("YYYY-MM-DD").toString(),
            userId: userId
        }
        setShowProgressBar(true)
        orderService.search(searchObject).then(data => {
            setShowProgressBar(false)
            if (data?.items) {
                let dataFromServer = data.items.map(x => ({
                    ...x, date: moment(x.date, "YYYY-MM-DD").format("DD/MM/YYYY").toString()
                }))
                let selectedShiftData = dataFromServer.map(x => ({
                    date: x.date,
                    value: x.shift
                }))
                setSelectedShifts(selectedShiftData)
                setSelectedOrders(dataFromServer)
            }
            // setMenusForCurrentWeek(data.items)
        })
    }
    const nextWeek = () => {
        let weekStart = moment(lastDayOfWeek, "DD/MM/YYYY").isoWeekday(7).add(1, 'day');
        returnAllDaysOfWeek(weekStart)
    }
    const previousWeek = () => {
        let weekStart = moment(firstDayOfWeek, "DD/MM/YYYY").isoWeekday(1).subtract(7, 'day');
        returnAllDaysOfWeek(weekStart)
    }
    const returnAllDaysOfWeek = (initialDate) => {
        let days = [];
        for (let i = 0; i <= 6; i++) {
            days.push(moment(initialDate).add(i, 'days').format("DD/MM/YYYY"));
        }
        setDaysOfWeek(days)
        setFirstDayOfWeek(days[0])
        setLastDayOfWeek(days[6])
        getActiveMenus(days[0], days[6])
        getActiveOrders(days[0], days[6])

    }
    const isPreviousWeekEnabled = () => {
        let currentWeekFirstDay = moment().isoWeekday(1);
        let firstDayOfWeekToCheck = moment(firstDayOfWeek, 'DD/MM/YYYY')
        return !currentWeekFirstDay.isSameOrAfter(firstDayOfWeekToCheck)
    }
    useEffect(() => {
        let currentDate = moment();
        let weekStart = currentDate.isoWeekday(1);
        returnAllDaysOfWeek(weekStart)
        if (authContext?.customerId) {
            customerService.getCustomerCateringConfiguration(authContext.customerId).then((data) => {
                setOrderConfiguration(data)
            })
        }

    }, []);
    const returnMenuForSpecificDate = (date) => {
        if (date) {
            let filteredMenu = menusForCurrentWeek.filter((x) => {
                let currentDate = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD").toString();
                let dateFromMenu = moment(x.menuDate).format("YYYY-MM-DD").toString();
                return currentDate === dateFromMenu

            })
            if (filteredMenu?.length > 0) {
                return filteredMenu[0]
            }
        }
    }
    const isEditEnabled = (currentElement) => {
        if(authContext?.roleDTO.name.includes("admin")){
            return true
        }
        if (currentElement?.dateActiveFrom && currentElement?.dateActiveTo) {
            return moment().utc().isBetween(moment(currentElement.dateActiveFrom).utc(), moment(currentElement.dateActiveTo).utc())
        }
        return false
    }
    const returnSingleMenuElement = (date) => {
        let singleElement = returnMenuForSpecificDate(date)
        let groupedElements = []
        let editEnabled = isEditEnabled(singleElement, date)
        if (singleElement?.menuItemDTOList) {
            groupedElements = groupMenuByCategory(singleElement?.menuItemDTOList)
        }
        if (groupedElements?.length > 0) {
            return <>
                {singleElement.cateringLogo && <img src={"data:image/png;base64," + singleElement.cateringLogo} alt={singleElement.name} className="h-2rem my-3 mx-0" />}

                {orderConfiguration?.numberOfShifts > 1 &&
                    <>

                        <h6>{t('shift')}</h6>
                        <SelectButton value={selectedShiftForDate(date)} onChange={(e) => onShiftChange(e.value, date)} options={shiftOptions()} disabled={!editEnabled} />

                    </>}
                <div className='grid'>

                    {
                        groupedElements.map(x => {
                            return <div className='grid col-12'>
                                <div className='col-12'>
                                    <div className="mt-1 font-bold text-left" style={isMobile() ? { fontSize: '5.2vw' } : { fontSize: '1.2vw' }}>{x.categoryName}</div>
                                </div>
                                <div className='col-12 w-full'>
                                    {returnOneMenuItem(x, date, editEnabled)}
                                </div>
                            </div>
                        })
                    }
                </div>
            </>

        }
        return <div className='text-500'>{t('noMenu')}</div>
    }
    const selectItemFromMenu = (item, date) => {
        setShowDialog(true)
        if (item?.elements.length > 0) {
            setCurrentMenuItems(item)
            setCurrentMenuItemsDate(date)
        }
    }
    const selectElementFromCategory = (data) => {
        let singleItem = { ...singleOrder }
        singleItem.cateringId = data.cateringId
        singleItem.customerId = authContext?.customerId
        singleItem.userId = userId
        let alreadyAddedOrders = [...selectedOrders]
        let alreadyAddedOrdersForDate = alreadyAddedOrders.filter(x => x.date === currentMenuItemsDate)[0]
        if (alreadyAddedOrdersForDate) {
            singleItem = { ...alreadyAddedOrdersForDate }
            let indexOfCategory = alreadyAddedOrdersForDate?.menuItems?.findIndex(x => x.itemCategoryName === data.itemCategoryName)
            if (indexOfCategory !== -1) {
                singleItem.menuItems[indexOfCategory] = data
            } else {
                singleItem.menuItems = [...singleItem.menuItems, data]
            }
        } else {
            singleItem.menuItems = [data]
        }
        singleItem.date = currentMenuItemsDate
        let foundIndex = alreadyAddedOrders.findIndex(x => x.date === currentMenuItemsDate);
        if (foundIndex !== -1) {
            alreadyAddedOrders[foundIndex] = singleItem;

        } else {
            alreadyAddedOrders.push(singleItem)
        }
        setSelectedOrders(alreadyAddedOrders)
        setShowDialog(false)
        // singleItem.userId = authContext?.div
    }
    const returnMenuByCategory = (data) => {
        return <div className="col-12 md:col-3 lg:col-3">
            <div className="card m-3 border-1 surface-border menu-item-wrapper">
                <div className='flex justify-content-end h-3rem min-h-3rem'>
                    <span className={`product-badge status`}>{data.isVegetarian ? <img src={"assets/layout/images/vegetarian.jpg"} alt={data.name} className="h-3rem" />
                        : <></>}</span>
                    <span className={`product-badge status`}>{data.isVegan ? <img src={"assets/layout/images/vegan.png"} alt={data.name} className="h-3rem" />
                        : <></>}</span>
                    <span className={`product-badge status`}>{data.isLenten ? <img src={"assets/layout/images/posno.svg"} alt={data.name} className="h-3rem" />
                        : <></>}</span>
                </div>
                <div className="text-center">
                    <img src={data.image ? "data:image/png;base64," + data.image : "assets/layout/images/blank_menu_item.jpg"} alt={data.name} className="h-8rem my-3 mx-0 w-auto" />
                    <div className="text-2xl font-bold">{data.name}</div>
                    <div className="mb-3 h-3rem">{data.description}</div>
                </div>
                {orderConfiguration?.allowPricing && <span className="product-price mt-2">{data.price ?? 0} RSD</span>}

                <div className="text-right">
                    <div className="actions mt-2">
                        <Button icon="pi pi-plus" onClick={() => {
                            selectElementFromCategory(data)
                        }} />
                    </div>
                </div>
            </div>
        </div>
    }
    const shiftOptions = () => {
        let options = []
        if (orderConfiguration?.numberOfShifts > 1) {
            for (let index = 0; index < orderConfiguration?.numberOfShifts; index++) {
                options.push({
                    label: index + 1, value: index + 1
                })
            }
        }
        return options;
    }
    const onShiftChange = (value, date) => {
        let alreadySelectedData = [...selectedShifts]
        let indexOfShift = alreadySelectedData.findIndex(x => x.date === date)
        if (indexOfShift === -1) {
            alreadySelectedData.push(
                {
                    date: date,
                    value: value
                }
            )
        } else {
            alreadySelectedData[indexOfShift] = {
                date: date,
                value: value
            }
        }
        setSelectedShifts(alreadySelectedData)
    }
    const selectedShiftForDate = (date) => {
        let indexOfShift = selectedShifts.findIndex(x => x.date === date)
        if (indexOfShift !== -1) {
            return parseInt(selectedShifts[indexOfShift].value)
        }

        return null
    }
    const returnOneMenuItem = (data, date, editEnabled) => {
        let selectedOrderForCurrentDateForCategory
        let selectedOrderMenuItemsForCurrentDate = selectedOrders?.filter(x => x.date === date)?.[0]?.menuItems
        if (selectedOrderMenuItemsForCurrentDate) {
            selectedOrderForCurrentDateForCategory = selectedOrderMenuItemsForCurrentDate.filter(x => x.itemCategoryName === data.categoryName)?.[0]
        }
        if (selectedOrderForCurrentDateForCategory) {
            return <div className="col-12 flex flex-column border-bottom-1 border-300 mb-2 menu-item-wrapper">
                <div className="surface-borders surface-0">
                    <div className='flex h-3rem min-h-3rem'>
                        <span className={`product-badge status`}>{selectedOrderForCurrentDateForCategory.isVegetarian ? <img src={"assets/layout/images/vegetarian.jpg"} alt={selectedOrderForCurrentDateForCategory.name} className="h-2rem" />
                            : <></>}</span>
                        <span className={`product-badge status`}>{selectedOrderForCurrentDateForCategory.isVegan ? <img src={"assets/layout/images/vegan.png"} alt={selectedOrderForCurrentDateForCategory.name} className="h-2rem" />
                            : <></>}</span>
                        <span className={`product-badge status`}>{selectedOrderForCurrentDateForCategory.isLenten ? <img src={"assets/layout/images/posno.svg"} alt={selectedOrderForCurrentDateForCategory.name} className="h-2rem" />
                            : <></>}</span>
                    </div>
                    <div className="text-center">
                        <img src={selectedOrderForCurrentDateForCategory.image ? "data:image/png;base64," + selectedOrderForCurrentDateForCategory.image : "assets/layout/images/blank_menu_item.jpg"} alt={selectedOrderForCurrentDateForCategory.name} className="h-5rem my-1 mx-0" />
                        <div className="font-bold mt-1" style={isMobile() ? { fontSize: '5vw' } : { fontSize: '1vw' }}>{selectedOrderForCurrentDateForCategory.name}</div>
                        <div className="mb-3 h-3rem">{selectedOrderForCurrentDateForCategory.description}</div>
                        {orderConfiguration?.allowPricing && <span className="product-price mt-2">{data.price ?? 0} RSD</span>}

                    </div>
                    {editEnabled && <div className="text-right">
                        <div className="actions mt-2">
                            <Button icon="pi pi-pencil" onClick={() => {
                                selectItemFromMenu(data, date)
                            }} />
                            <Button icon="pi pi-trash" className="p-button-danger ml-2" onClick={() => removeOrderForDate(date, data, selectedOrderForCurrentDateForCategory)} />

                        </div>
                    </div>}
                </div>
            </div>
        }
        return <div className="col-12 h-16rem flex flex-column justify-content-center border-bottom-1 border-300">
            <div className="surface-border">

                <div className="text-center">
                    <Button icon="pi pi-plus" disabled={!editEnabled} className='p-button-info max-w-full w-8rem h-8rem p-button-outlined' onClick={() => {
                        selectItemFromMenu(data, date)
                    }} />
                    {/* <div className="font-bold mt-2" style={isMobile() ? { fontSize: '6vw' } : { fontSize: '1vw' }}>{data.categoryName}</div> */}
                </div>
            </div>
        </div>
    }
    return (
        <div className="App grid">
            {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px', width: "100%" }}></ProgressBar>}
            <Toast ref={toast} />
            <div className='col-12'>
                <div className='grid card'>
                    <div className='col-3 text-center'>
                        <Button label={isMobile() ? null : t("previousWeek")} icon="pi pi-arrow-left" iconPos="left" onClick={previousWeek} disabled={!isPreviousWeekEnabled()} />
                    </div>
                    <div className='col-4 md:col-5 lg:col-5 text-center'>
                        {isMobile() ? <h6>{firstDayOfWeek} - {lastDayOfWeek}</h6> : <h4>{firstDayOfWeek} - {lastDayOfWeek}</h4>}
                    </div>
                    <div className='col-3 text-center'>
                        <Button label={isMobile() ? null : t("nextWeek")} icon="pi pi-arrow-right" iconPos="right" onClick={nextWeek} />

                    </div>
                    <div className='col-1 text-center' ref={div}>
                        <Button disabled={disableSaveButton} label={isMobile() ? null : t('save')} loading={disableSaveButton} icon="pi pi-save" className="p-button-info mr-2" onClick={saveChanges} />

                    </div>

                </div>
            </div>
            {!showProgressBar && <div className='col-12'>
                <div className='grid'>
                    {
                        daysOfWeek.map(x => {
                            let day = moment(x, "DD/MM/YYYY").format('dddd').substring(0, 3)
                            let isWeekend = day === "Sat" || day === "Sun"
                            let className = "card text-center p-1"
                            if (isWeekend) {
                                className += " surface-100 "
                            }
                            if (isMobile()) {
                                className += "m-3 p-3"
                            }
                            return <div className={className} style={isMobile() ? { width: "100%" } : { width: 100 / 7 + "%" }}>
                                {t(day) + " " + x}
                                <br />
                                <hr />
                                {returnSingleMenuElement(x)}
                            </div>
                        })
                    }
                </div>
            </div>}
            <Dialog visible={showDialog} style={{ width: isMobile() ? "80%" : "80%" }} header={currentMenuItems?.categoryName} modal onHide={() => {
                setShowDialog(false)
                setCurrentMenuItems()
                setCurrentMenuItemsDate()
            }}>
                <div className='grid'>
                    {
                        currentMenuItems?.elements?.map(x => {
                            return returnMenuByCategory(x)
                        })
                    }
                </div>
            </Dialog>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(MenuOrder, comparisonFn);
