import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useHistory, useLocation, Route } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { addLocale } from 'primereact/api';

import moment from 'moment';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import 'primereact/resources/primereact.min.css';
import { Ripple } from "primereact/ripple";
import { SelectButton } from 'primereact/selectbutton';
import { StyleClass } from "primereact/styleclass";
import { useTranslation } from 'react-i18next';
import './App.scss';
import enImage from "./assets/flags/en.jpg";
import srbImage from "./assets/flags/srb.png";
import DashboardWrapper from './components/DashboardWrapper';
import { JWT_NAME, cateringAdmin, customerAdmin, customerOperator, customerUser } from './constants/auth';
import AuthContext from './context';
import i18n from './i18n';
import Customers from './pages/Catering/Customers';
import CustomerOverview from './pages/Customer/CustomerOverview';
import LicenseTask from './pages/License/LicenseTask';
import CustomUserOrder from './pages/Order/CustomUserOrder';
import MenuOrder from './pages/Order/MenuOrder';
import OrdersDataTask from './pages/Order/OrdersDataTask';
import CateringService from './service/CateringService';
import CustomerService from './service/CustomerService';
import { searchCateringParams } from './service/serviceObjects';
import { createCookie, getCookie } from './utilities/general';
import { useCustomMenu } from './utilities/menu';
import PublicOrderComponent from './pages/PublicView/PublicOrderComponent';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import PublicCheckoutPage from './pages/PublicView/PublicCheckoutPage';
import OrderService from './service/OrderService';
import { Toast } from 'primereact/toast';
import { Badge } from 'primereact/badge';
import PaymentService from './service/PaymentService';
import { paymentData } from './constants/payment';
import { getNumberOfMenuItemSequences } from './utilities/menuItemUtil';
import { BlockUI } from 'primereact/blockui';
import PublicUsersPanel from './pages/PublicView/PublicUsersPanel';
import { useDispatch, useSelector } from 'react-redux';
import { selectNewOrders } from './store/orderSelectors';
import { hideLoader, showLoader } from './store/uiSlice';
import useOrderDataTaskUtil from './utilities/useOrderDataTaskUtil';
import { setUserData } from './store/userSlice';
import { selectCreditAmount, selectUserCardNumber } from './store/userSelectors';
import QRCode from "react-qr-code";

const AppPublic = () => {
    const authContext = useContext(AuthContext);
    let currentDate = moment();

    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);

    const [value, setValue] = useState(getCookie('lang') || "srb");
    const [activeCatering, setActiveCatering] = useState(0);
    const [cateringId, setCateringId] = useState(0);
    const [currentDay, setCurrentDay] = useState(moment(currentDate).format("DD/MM/YYYY"));
    const [myCaterings, setMyCaterings] = useState([]);
    const [orderConfiguration, setOrderConfiguration] = useState();

    const [firstDayOfWeek, setFirstDayOfWeek] = useState();
    const [lastDayOfWeek, setLastDayOfWeek] = useState();
    const [showOtherDay, setShowOtherDay] = useState();
    const [daysToDisplay, setDaysToDisplay] = useState([]);
    const [displayLoader, setDisplayLoader] = useState(false);
    const [displayQR, setDisplayQR] = useState(false);
    const [otherUserId, setOtherUserId] = useState();
    const [activeIndex, setActiveIndex] = useState(0);  // Expand the "Current Week" by default
    const [disabledDates, setDisabledDates] = useState([]);
    const newOrders = useSelector(selectNewOrders)
    let orders = useSelector(selectNewOrders)
    const creditAmount = useSelector(selectCreditAmount);
    const userCardNumber = useSelector(selectUserCardNumber);

    const isLoading = useSelector((state) => state.uiSlice.displayLoader);
    let orderUtil = useOrderDataTaskUtil()
    const langOptions = [
        { img: enImage, value: 'en' },
        { img: srbImage, value: 'srb' },
    ];
    const copyTooltipRef = useRef();
    const location = useLocation();

    const { returnMenuList } = useCustomMenu()
    const history = useHistory();
    let topbarItemClick = false;
    const btnRef10 = useRef(null);
    const btnRef11 = useRef(null);
    const btnRef12 = useRef(null);
    const btnRef8 = useRef(null);
    const { t } = useTranslation();
    const cateringService = new CateringService();
    const orderService = new OrderService();
    const toast = useRef(null);
    const btnRef1 = useRef(null);
    const btnRef2 = useRef(null);
    const dispatch = useDispatch()
    const customerService = new CustomerService();
    const paymentService = new PaymentService();
    const changeLanguage = (lng) => {
        createCookie('lang', lng)
        setValue(lng)
        i18n.changeLanguage(lng);
    }

    const langTemplate = (option) => {
        return <img src={option.img} alt={"langImg"} width="20" height="10" />;

    }
    const setDays = (initialDate) => {
        let days = [];
        let today = moment();
        let startOfWeek = moment(today).startOf('week').add(1, 'days'); // Monday as the start of the week

        // Set start day based on current day
        if (today.day() === 0) {
            startOfWeek = today; // If it's Sunday, start from today
        }

        // Collect days from the start day for the next 7 days
        for (let i = 0; i <= 13; i++) {
            days.push(moment(startOfWeek).add(i, 'days').format("DD/MM/YYYY"));
        }

        setDaysToDisplay(days);
    }
    useEffect(() => {
        // Calculate the start and end of the current week
        const startOfCurrentWeek = moment().startOf('week').add(1, 'days'); // Start on Monday
        const endOfCurrentWeek = moment(startOfCurrentWeek).add(6, 'days'); // End on Sunday

        // Calculate the start and end of the next week
        const startOfNextWeek = moment(startOfCurrentWeek).add(7, 'days');
        const endOfNextWeek = moment(startOfNextWeek).add(6, 'days');

        // Generate a list of dates for both weeks
        let disabledDays = [];
        for (let i = 0; i <= 6; i++) {
            disabledDays.push(startOfCurrentWeek.clone().add(i, 'days').toDate()); // Current week
            disabledDays.push(startOfNextWeek.clone().add(i, 'days').toDate());   // Next week
        }
        if (authContext) {
            dispatch(setUserData(authContext))
        }
        // Set the list of disabled dates
        setDisabledDates(disabledDays);
    }, []);
    useEffect(() => {
        addLocale('en', {
            firstDayOfWeek: 1, // Monday
            dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Today',
            clear: 'Clear'
        });
        addLocale('srb', {
            firstDayOfWeek: 1, // Monday
            dayNames: ['Nedelja', 'Ponedeljak', 'Utorak', 'Sreda', 'Četvrtak', 'Petak', 'Subota'],
            dayNamesShort: ['Ned', 'Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub'],
            dayNamesMin: ['Ned', 'Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub'],
            monthNames: ['Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun', 'Jul', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'],
            monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'],
            today: 'Danas',
            clear: 'Obriši'
        });
    }, [])
    useEffect(() => {
        if (authContext?.roleDTO?.name == customerOperator) {
            setStaticMenuDesktopInactive(true)
        }
    }, [authContext?.roleDTO?.name])
    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    const showErrorMessage = (element, maxNumber) => {
        toast.current.show({
            sevetiy: "warn",
            className: "surface-900",
            contentClassName: "p-4",
            life: 3000,
            content: (
                <div className="flex flex-column bg-orange-900 w-full">
                    <div className="flex flex-row bg-orange-900 w-full">
                        <i className="pi pi-exclamation-triangle text-4xl text-grey-300 my-2 mx-2" style={{ color: 'gray' }} />

                        <p className="my-2 mx-3 line-height-3 text-gray-300">
                            {element.itemCategoryName} {t('alreadyAdded')}
                        </p>
                    </div>
                    <div className="">
                        <p className="my-2 mx-3 line-height-3 text-gray-300">
                            {t('maxNumberOfOrderPerCategory')} : {maxNumber}
                        </p>
                    </div>
                    <div style={{ height: "6px" }} className="bg-gray-700">
                        <div className="h-full bg-blue-500 animate-width animation-duration-3000"></div>
                    </div>
                </div>
            ),
        });
    };


    const getMyCaterings = (searchData) => {
        cateringService.getByCustomerId(authContext?.customerId).then(data => {
            setMyCaterings(data)
            setCateringId(data?.[0]?.id)
            setActiveCatering(data?.[0]?.id)
        })

    }
    useEffect(() => {
        if (authContext?.customerId) {
            customerService.getCustomerCateringConfiguration(authContext.customerId).then((data) => {
                setOrderConfiguration(data)
            })
        }
        setDays(currentDate)
        getMyCaterings()
    }, []);


    const onTopbarItemClick = (event, item) => {
        topbarItemClick = true;

        if (activeTopbarItem === item) {
            setActiveTopbarItem(null);
        }
        else {
            setActiveTopbarItem(item);
        }

        event.preventDefault();
    };




    const isDesktop = () => {
        return window.innerWidth > 896;
    };

    const isMobile = () => {
        return window.innerWidth <= 896;
    };
    const goToPayment = (totalAmount, customOrderData) => {
        let ordersData = orders
        if (customOrderData) {
            ordersData = customOrderData
        }
        dispatch(showLoader())

        let transactionNumber = Date.now()
        let dataForServer = ordersData.map(x => ({
            ...x, date: moment(x.date, "DD/MM/YYYY").format("YYYY-MM-DD").toString(), status: "PAYMENT_IN_PROGRESS", transactionNumber: transactionNumber
        }))
        orderService.save(dataForServer).then(data => {
            // setMenusForCurrentWeek()
            const returnPaymentData = paymentData.find(x => x.cateringId == cateringId)
            paymentService.handlePayment(
                {
                    merchantID: returnPaymentData.merchantID,
                    terminalID: returnPaymentData.terminalID,
                    orderID: transactionNumber,
                    amount: totalAmount * 100,
                    currency: 941,
                    description: "Porudzbina broj " + transactionNumber
                }
            ).then(response => {
                // setOrders([])
                window.location.href = response
            }).catch(error => {
                dispatch(hideLoader())
                toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringorderSave'), life: 3000 })
            }).finally(() => {
            })

        }).catch(reason => {
            toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorDuringorderSave'), life: 3000 })
            dispatch(hideLoader())

        })
    }
    const returnMyCaterings = () => {
        let myCateringComponent = []
        if (myCaterings?.length > 0) {
            myCaterings.forEach(element => {
                myCateringComponent.push(
                    <li className="mr-2">
                        <a className={classNames('p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:bg-teal-400 hover:text-white transition-colors transition-duration-150', { 'bg-white hover:bg-white text-indigo-900': activeCatering === element.id, 'text-800': activeCatering !== element.id })} onClick={() => setActiveCatering(element.id)} style={{ borderRadius: '30px' }}>
                            <img src={"data:image/png;base64," + element.profileImage} height={15} className='mr-2' />
                            {!isMobile() && <span className="font-medium">{element.name}</span>
                            }
                            <Ripple />
                        </a>
                    </li >
                )
            });
        }
        return myCateringComponent
    }
    const returnDaysToDisplay = () => {
        let currentWeekItems = [];
        let nextWeekItems = [];
        let otherDaysItems = [];
        let weekInserted = false;  // To track when to insert "Current Week" and "Next Week"

        // Define the start and end of the current and next week
        const startOfCurrentWeek = moment().startOf('week').add(1, 'days'); // Current week starts on Monday
        const startOfNextWeek = moment(startOfCurrentWeek).add(7, 'days');
        const endOfNextWeek = moment(startOfNextWeek).add(6, 'days');

        daysToDisplay.forEach((x, index) => {
            let day = moment(x, "DD/MM/YYYY");
            let dayOfWeek = day.format('dddd');
            let dayNumber = day.day();

            // Check if the day belongs to the current week
            if (day.isSameOrAfter(startOfCurrentWeek) && day.isBefore(startOfNextWeek)) {
                if (!weekInserted) {
                    weekInserted = true;
                }
                currentWeekItems.push(
                    <li key={x} className="flex overflow-hidden">
                        <div
                            className="surface-200 flex flex-column align-items-center justify-content-center z-1"
                            style={{ width: "2px" }}
                        >
                            {currentDay === x ? (
                                <div
                                    className="bg-primary border-2 border-circle border-primary"
                                    style={{
                                        width: ".75rem",
                                        height: ".75rem",
                                    }}
                                ></div>
                            ) : null}
                        </div>
                        <a
                            ref={btnRef2}
                            className={
                                "p-ripple flex-1 cursor-pointer py-2 px-3 hover:surface-hover transition-colors transition-duration-150 text-700 font-medium line-height-3 " +
                                (currentDay === x ? "text-primary" : "")
                            }
                            onClick={() => setCurrentDay(x)}
                        >
                            <span className="font-medium">{t(dayOfWeek) + " " + x}</span>
                            <Ripple />
                        </a>
                    </li>
                );
            }
            // Check if the day belongs to the next week
            else if (day.isSameOrAfter(startOfNextWeek) && day.isSameOrBefore(endOfNextWeek)) {
                nextWeekItems.push(
                    <li key={x} className="flex overflow-hidden">
                        <div
                            className="surface-200 flex flex-column align-items-center justify-content-center z-1"
                            style={{ width: "2px" }}
                        >
                            {currentDay === x ? (
                                <div
                                    className="bg-primary border-2 border-circle border-primary"
                                    style={{
                                        width: ".75rem",
                                        height: ".75rem",
                                    }}
                                ></div>
                            ) : null}
                        </div>
                        <a
                            ref={btnRef2}
                            className={
                                "p-ripple flex-1 cursor-pointer py-2 px-3 hover:surface-hover transition-colors transition-duration-150 text-700 font-medium line-height-3 " +
                                (currentDay === x ? "text-primary" : "")
                            }
                            onClick={() => setCurrentDay(x)}
                        >
                            <span className="font-medium">{t(dayOfWeek) + " " + x}</span>
                            <Ripple />
                        </a>
                    </li>
                );
            }
            // If the day is not part of the current or next week, add it to "Other Days"
            else {
                otherDaysItems.push(
                    <li key={x} className="flex overflow-hidden">
                        <div
                            className="surface-200 flex flex-column align-items-center justify-content-center z-1"
                            style={{ width: "2px" }}
                        >
                            {currentDay === x ? (
                                <div
                                    className="bg-primary border-2 border-circle border-primary"
                                    style={{
                                        width: ".75rem",
                                        height: ".75rem",
                                    }}
                                ></div>
                            ) : null}
                        </div>
                        <a
                            ref={btnRef2}
                            className={
                                "p-ripple flex-1 cursor-pointer py-2 px-3 hover:surface-hover transition-colors transition-duration-150 text-700 font-medium line-height-3 " +
                                (currentDay === x ? "text-primary" : "")
                            }
                            onClick={() => setCurrentDay(x)}
                        >
                            <span className="font-medium">{t(dayOfWeek) + " " + x}</span>
                            <Ripple />
                        </a>
                    </li>
                );
            }
        });

        return (
            <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <AccordionTab header={t('currentWeek')} >
                    {currentWeekItems.length > 0 ? currentWeekItems : <li>No days in current week.</li>}
                </AccordionTab>
                <AccordionTab header={t('nextWeek')}>
                    {nextWeekItems.length > 0 ? nextWeekItems : <li>No days in next week.</li>}
                </AccordionTab>
                {otherDaysItems.length > 0 && (
                    <AccordionTab header={t('otherDays')}>
                        {otherDaysItems}
                    </AccordionTab>
                )}
            </Accordion>
        );
    };

    const end = (
        <div className='layout-topbar'>
            <ul className={classNames('topbar-menu fadeInDown', { 'topbar-menu-visible': topbarMenuActive })}>
                {
                    authContext?.username && (
                        <li className={classNames('user-profile', { 'active-topmenuitem': activeTopbarItem === 'profile' })}>
                            <button className="p-link text-100" onClick={(e) => onTopbarItemClick(e, 'profile')}>
                                <i className="topbar-icon pi pi-user"></i>
                                <span className="topbar-item-name text-100">{authContext?.username}</span>
                            </button>

                            <ul className={classNames({ 'fadeInDown': !isMobile() })}>
                                {creditAmount > 0 && <li role="menuitem" style={{ margin: '4px', color: "rgb(106 106 106)", borderTop: 1, borderTopColor: "#ccc" }}>

                                    <i className="pi pi-ticket mr-1 ml-2"></i>
                                    <span className='mr-1 ml-2'>{t('credit')}</span>
                                    <span><b>{creditAmount}</b></span>

                                </li>}
                                <li role="menuitem">
                                    <button className="p-link" onClick={() => {
                                        setDisplayQR(true)

                                    }}>
                                        <i className="pi pi-qrcode"></i>
                                        <span>{t('showQR')}</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link" onClick={() => {
                                        createCookie(JWT_NAME, '', -1)
                                        window.location = "/"

                                    }}>
                                        <i className="pi pi-sign-out"></i>
                                        <span>{t('logout')}</span>
                                    </button>
                                </li>

                            </ul>
                        </li>
                    )
                }
                <li className={"settings" === activeTopbarItem ? 'active-topmenuitem' : null}>
                    <button className="p-link" onClick={(e) => onTopbarItemClick(e, 'settings')}>
                        <i className="topbar-icon pi pi-cog"></i>
                        <span className="topbar-item-name">{t('settings')}</span>
                    </button>
                    <ul className={classNames({ 'fadeInDown': !isMobile() })}>
                        <li role="menuitem flex">
                            <span className="topbar-item-name">{(t('lang'))}</span>
                            <SelectButton value={value} options={langOptions} onChange={(e) => changeLanguage(e.value)} itemTemplate={langTemplate} optionLabel="value" className='text-right' />
                        </li>
                    </ul>
                </li>

            </ul>
        </div>
    )
    const returnCatering = (data) => {
        return <div className="card m-3 border-1 surface-border w-20rem">
            <div className="text-center">
                <img src={data.image} alt={data.name} className="h-8rem my-3 mx-0" />
                <div className="text-2xl font-bold">{data.name}</div>
                <div className="mb-3">{data.description}</div>
            </div>
        </div>
    }
    const addOtherDay = (value) => {
        setCurrentDay(moment(value).format("DD/MM/YYYY"))
        setShowOtherDay()
        let valueToAdd = moment(value).format("DD/MM/YYYY");
        let alreadyAddedDays = [...daysToDisplay]
        if (!alreadyAddedDays.includes(valueToAdd)) {
            alreadyAddedDays.push(valueToAdd)
            setDaysToDisplay(alreadyAddedDays)
        }
    }
    const dateComponent = (
        <div className="surface-card select-none p-3">
            <div className="p-3 flex align-items-center text-900">
                <i className="pi pi-book mr-2"></i>
                <span className="font-medium">{t('chooseDay')}</span>
            </div>
            <ul className="list-none pl-3 m-0">
                {returnDaysToDisplay()}

            </ul>
            <div className="p-3 flex align-items-center text-900" onClick={() => setShowOtherDay(true)}>
                <i className="pi pi-calendar mr-2"></i>
                <span className="font-medium">{t('chooseOtherDay')}</span>

            </div>
            <Dialog visible={showOtherDay} onHide={() => setShowOtherDay(false)}>
                <Calendar
                    disabledDates={disabledDates}
                    // value={date1}
                    onChange={(e) => {
                        setActiveIndex(2)
                        addOtherDay(e.value)
                    }}
                    dateFormat="dd/mm/yy"
                    inline
                />
            </Dialog>
        </div>
    )
    const homeComponent = (
        <>
            <Toast ref={toast} />
            <Dialog visible={displayQR} style={{ width: "50rem" }} onHide={() => setDisplayQR(false)}>
                <div style={{ height: "auto", margin: "0 auto", width: "100%" }}>
                    <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={userCardNumber}
                        viewBox={`0 0 256 256`}
                    />
                </div>
            </Dialog>

            <div className="p-0 md:p-5 lg:p-5 xl:p-5 flex flex-column flex-auto ">
                <div className="border-2 surface-border border-round surface-section flex-auto grid">
                    <div className='col-12'>

                        <ul className="bg-gray-200 p-2 m-0 mt-5 sm:mx-0 md:mx-7 lg:mx-7 xl:mx-7 list-none flex justify-content-center align-items-center overflow-x-auto select-none" style={{ borderRadius: '30px' }}>
                            {returnMyCaterings()}

                            {/* <li className="mr-2">
                        <a className={classNames('p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:bg-teal-400 hover:text-white transition-colors transition-duration-150', { 'bg-white hover:bg-white text-indigo-900': activeCatering === 99, 'text-800': activeCatering !== 99 })} onClick={() => setActiveCatering(99)} style={{ borderRadius: '30px' }}>
                            <i className="pi pi-search mr-2"></i>
                            <span className="font-medium">{t('explore')}</span>
                            <Ripple />
                        </a>
                    </li> */}
                            {orders?.length > 0 && <li className={`ml-3 ${activeCatering == 101 ? "" : "shake-button"}`}>
                                <a className={classNames('p-ripple cursor-pointer px-4 py-3 flex align-items-center bg-teal-400 text-white transition-colors transition-duration-150')} onClick={() => setActiveCatering(101)} style={{ borderRadius: '30px' }}>
                                    <i className="pi pi-shopping-cart mr-2"></i>
                                    {!isMobile() && <span className="font-medium mr-2">{t('checkout')}</span>
                                    }
                                    {orders?.length > 0 && <Badge value={orders?.length}></Badge>}
                                    <Ripple />
                                </a>
                            </li>}
                            <li className="ml-3">
                                {(authContext?.roleDTO?.name == customerAdmin || authContext?.roleDTO?.name == cateringAdmin) && <PublicUsersPanel setOtherUserId={setOtherUserId} otherUserId={otherUserId} />}

                            </li>

                        </ul>
                    </div>
                    {activeCatering == 101 && <div className='col-12'>
                        <PublicCheckoutPage
                            orders={orders}
                            // setOrders={setOrders}
                            orderConfiguration={orderConfiguration}
                            showErrorMessage={showErrorMessage}
                            goToPayment={goToPayment} />
                    </div>}
                    {activeCatering != 99 && activeCatering != 101 &&
                        !isMobile() && <div className='col-3'>
                            {dateComponent}
                        </div>}
                    {activeCatering != 99 && activeCatering != 101 &&
                        isMobile() && <StyleClass
                            nodeRef={btnRef1}
                            selector="#slideover-1"
                            enterClassName="hidden"
                            enterActiveClassName="fadeinleft"
                            leaveToClassName="hidden"
                            leaveActiveClassName="fadeoutleft"
                            hideOnOutsideClick
                        >
                            <Button ref={btnRef1} label={currentDay} icon="pi pi-calendar" className='fixed top-20 left-0 h-5rem border' style={{ zIndex: 10000 }} />
                            <div id="slideover-1" className="hidden surface-overlay absolute top-0 left-0 z-index-1000 shadow-2 w-22rem h-full" style={{ zIndex: 10000 }}>
                                <div className="flex flex-column h-full p-4">
                                    <StyleClass
                                        nodeRef={btnRef2}
                                        selector="#slideover-1"
                                        leaveToClassName="hidden"
                                        leaveActiveClassName="fadeoutleft"
                                    >
                                        {dateComponent}
                                    </StyleClass>
                                    <StyleClass
                                        nodeRef={btnRef2}
                                        selector="#slideover-1"
                                        leaveToClassName="hidden"
                                        leaveActiveClassName="fadeoutleft"
                                    >
                                        <Button
                                            ref={btnRef2}
                                            icon="pi pi-times"
                                            className="p-button-rounded p-button-text p-button-plain"
                                        />
                                    </StyleClass>
                                </div>
                            </div>
                        </StyleClass>


                    }

                    {activeCatering != 99 && activeCatering != 101 && <div className='col-12 md:col-9 lg:col-9 xl:col-9'>

                        <PublicOrderComponent
                            currentDay={currentDay}
                            currentCateringId={activeCatering}
                            orders={orders}
                            // setOrders={setOrders}
                            orderConfiguration={orderConfiguration}
                            setDisplayLoader={setDisplayLoader}
                            otherUserId={otherUserId}
                            goToPayment={goToPayment} />

                    </div>}
                </div>
            </div>
            <div className="surface-section px-4 py-6 md:px-6 lg:px-8 text-center">
                <img src="assets/layout/images/LOGO.svg" alt="Image" height="50" />
                <div className="font-medium text-900 mt-4 mb-3">&copy; 2024 bidtech.rs</div>
                <p className="text-600 line-height-3 mt-0 mb-4">Explore food world with us.</p>
                <div className="flex align-items-center justify-content-center">
                    <a className="cursor-pointer text-700 mr-5" onClick={() => window.location = "/"}>
                        <i className="pi pi-home"></i>
                    </a>
                    <a className="cursor-pointer text-700" href='https://www.instagram.com/gfood.app'>
                        <i className="pi pi-instagram"></i>
                    </a>
                </div>
            </div>
        </>
    )
    const routers = [
        { path: '/', component: () => homeComponent, exact: true, meta: { breadcrumb: [{ parent: 'Dashboard', label: t('dashboard') }] } },
        { path: '/orderClient', component: MenuOrder, meta: { breadcrumb: [{ parent: 'Menu', label: t('orderClient') }] } },
        { path: '/orders', component: OrdersDataTask, meta: { breadcrumb: [{ parent: 'Menu', label: t('orders') }] } },
        { path: '/customers', component: Customers, meta: { breadcrumb: [{ parent: 'Catering', label: t('customers') }] } },
        { path: '/license', component: LicenseTask, meta: { breadcrumb: [{ parent: 'License', label: t('license') }] } },
        { path: '/customerOverview', component: CustomerOverview, meta: { breadcrumb: [{ parent: 'Catering', label: t('customers') }] } },
        { path: '/customerOrder', component: CustomUserOrder, meta: { breadcrumb: [{ parent: 'Catering', label: t('customers') }] } },
    ];
    return (

        <BlockUI blocked={isLoading} template={<i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem' }}></i>}>
            <div className="min-h-screen flex flex-column public-menu layout-wrapper">
                {authContext.roleDTO.name == customerUser && <div style={{ height: '250px', backgroundImage: 'linear-gradient(to right, #ff7e5f, #feb47b)', }}>
                    <div className="py-3 px-5 flex align-items-center justify-content-between relative lg:static" style={{ minHeight: '80px' }}>
                        <img src="assets/layout/images/logo-white.png" alt="Image" height="50" className="mr-0 lg:mr-6" />

                        {isMobile() && <StyleClass nodeRef={btnRef10} selector="@next" enterClassName="hidden" leaveToClassName="hidden" hideOnOutsideClick>
                            <a ref={btnRef10} className="p-ripple cursor-pointer block lg:hidden text-indigo-50" >
                                <i className="pi pi-bars text-4xl"></i>
                                <Ripple />
                            </a>
                        </StyleClass>}
                        <div className="align-items-center flex-grow-1 justify-content-end hidden lg:flex absolute lg:static w-full  left-0 top-100 z-1 mb-3 px-5" style={{ backgroundColor: isMobile() ? "#fff" : 'transparent', height: isMobile() ? "15rem" : "auto" }}>
                            {isMobile() && <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                {creditAmount > 0 && <li className=" py-3 flex justify-content-end">

                                    <i className="pi pi-ticket mr-2 text-xl"></i>
                                    <span className='mr-2'>{t('credit')}</span>
                                    <span>{creditAmount}</span>
                                </li>}
                                <li className="border-top-1 py-1 flex justify-content-end">
                                    <Button className="p-button-text align-items-right" onClick={() => {
                                        setDisplayQR(true)

                                    }}>
                                        <i className="pi pi-qrcode mr-2 text-xl"></i>
                                        <span>{t('showQR')}</span>
                                    </Button>
                                </li>

                                <li className="border-top-1 border-100 lg:border-top-none py-1 pb-2 flex justify-content-end">
                                    <Button className="p-button-text align-items-right" onClick={() => {
                                        createCookie(JWT_NAME, '', -1)
                                        window.location = "/"

                                    }}>
                                        <i className="pi pi-sign-out mr-2 text-xl"></i>
                                        <span>{t('logout')}</span>
                                    </Button>
                                </li>
                                <li role="menuitem flex py-3 mb-2">
                                    <SelectButton value={value} options={langOptions} onChange={(e) => changeLanguage(e.value)} itemTemplate={langTemplate} optionLabel="value" className='text-right' />
                                </li>

                            </ul>}
                            {!isMobile() && <ul className="list-none p-0 m-0 flex lg:align-items-end select-none flex-column lg:flex-row">{end}</ul>}
                        </div>
                    </div>

                </div>}
                <div className="p-5 flex flex-column flex-auto " style={{ marginTop: authContext?.roleDTO?.name == customerUser ? '-12rem' : "-2rem" }}>
                    {homeComponent
                    }

                    {/* {
                        routers.map((router, index) => {
                            if (router.exact) {
                                return <Route key={`router${index}`} path={router.path} exact component={router.component} />
                            }

                            return <Route key={`router${index}`} path={router.path} component={router.component} />
                        })
                    } */}
                </div>

            </div>
        </BlockUI>

    );
}

export default AppPublic;
