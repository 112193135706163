import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import StockItemService from '../../../service/StockItemService';
import StockItemModalForm from './StockItemForm';
import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import { searchStockItemParams } from '../../../service/serviceObjects';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../context';

const StockItem = () => {
    const authContext = useContext(AuthContext);

    let stockItem = {
        cateringId: authContext.cateringId || 0,
        name: null,
        description: null
    }
    const [stockItemDialog, setStockItemDialog] = useState(false);
    const [deleteStockItemDialog, setDeleteStockItemDialog] = useState(false);
    const [deleteStockItemsDialog, setDeleteStockItemsDialog] = useState(false);
    const [itemCategories, setStockItems] = useState([]);
    const [selectedStockItem, setSelectedStockItem] = useState(null);
    const [stockItemEdit, setStockItemEdit] = useState(null)
    const [stockItemDelete, setStockItemDelete] = useState(null)
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const stockItemService = new StockItemService();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const { t } = useTranslation();

    useEffect(() => {
        getAllStockItems()
    }, []);
    const getAllStockItems = (searchData) => {
        setLoadingTable(true)
        let searchObject = {...searchStockItemParams}
        if (searchData) {
            searchObject = { ...searchStockItemParams, ...searchData }
        }
        searchObject.cateringId = authContext.cateringId ?? 0
        stockItemService.search(searchObject).then(data => {
            setFirst(data.pageNumber * data.pageSize);
            setRows(data.pageSize);
            setTotalRecords(data.itemCount)
            setStockItems(data.items)
            setLoadingTable(false)
        })

    }
    const openNew = () => {
        setStockItemDialog(true);
    }

    const hideDialog = () => {
        if (stockItemEdit) {
            setStockItemEdit()
        }
        setStockItemDialog(false);
    }

    const hideDeleteStockItemDialog = () => {
        setStockItemDelete();
        setDeleteStockItemDialog(false);
    }

    const hideDeleteStockItemsDialog = () => {
        setDeleteStockItemsDialog(false);
    }
    const saveStockItem = (data) => {
        if (data) {
            let submitRequest = { ...stockItem, ...data }
            stockItemService.save(submitRequest).then((value) => {
                setStockItemDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('stockItemAdded'), life: 3000 })
                getAllStockItems()
            }).catch(reason => {
                setStockItemDialog(false)
                toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorSavingStockItem'), life: 3000 })
            })
        }
    }
    const updateStockItem = (data) => {
        if (data) {
            let submitRequest = { ...stockItem, ...data }
            stockItemService.update(submitRequest.id, submitRequest).then((value) => {
                setStockItemDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('stockItemUpdated'), life: 3000 })
                getAllStockItems()
            })
        }
    }
    const editStockItem = (item) => {
        setShowProgressBar(true)
        stockItemService.get(item.id).then((data) => {
            setShowProgressBar(false)
            setStockItemEdit(data)
            setStockItemDialog(true);
        })
    }

    const confirmDeleteStockItem = (item) => {
        setStockItemDelete(item);
        setDeleteStockItemDialog(true);
    }

    const deleteStockItem = () => {
        stockItemService.delete(stockItemDelete.id).then(data => {
            setStockItemDelete(null);
            setDeleteStockItemDialog(false);
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('stockItemDeleted'), life: 3000 });
            getAllStockItems()
        })
    }
    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteStockItemsDialog(true);
    }

    async function deleteSelectedStockItem() {
        await selectedStockItem.forEach(element => {
            stockItemService.delete(element.id).then(data => {
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('stockItemDeleted'), life: 3000 });
            })
        });
        setDeleteStockItemsDialog(false);
        setSelectedStockItem(null);
        getAllStockItems()

    }
    const onPage = (event) => {
        let searchParams = searchStockItemParams
        searchParams.pageNumber = event.page
        searchParams.pageSize = event.rows
        getAllStockItems(searchParams)
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("new")} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label={t("delete")} icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedStockItem || !selectedStockItem.length} />
                </div>
            </React.Fragment>
        )
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("name")}</span>
                {rowData.name}
            </>
        );
    }

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("description")}</span>
                <span className={`status`}>{rowData.description}</span>
            </>
        )
    }
    const unitBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("unit")}</span>
                <span className={`status`}>{t(rowData.unit)}</span>
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions mt-2 text-center">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editStockItem(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteStockItem(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t("manageStockItems")}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t("search")} />
            </span>
        </div>
    );

    const deleteStockItemDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteStockItemDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteStockItem} />
        </>
    );
    const deletStockItemsDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteStockItemsDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedStockItem} />
        </>
    );
    const paginationTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("previous")}</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("next")}</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'All', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        }
    };
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    <DataTable ref={dt} value={itemCategories} selection={selectedStockItem} onSelectionChange={(e) => setSelectedStockItem(e.value)}
                        dataKey="id" paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate={t('defaultPaginationMessage')} onPage={onPage}
                        globalFilter={globalFilter} emptyMessage="No items found" header={header} responsiveLayout="scroll" loading={loadingTable}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="name" header={t("name")} body={nameBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                        <Column field="description" header={t("description")} body={descriptionBodyTemplate} headerStyle={{ width: '25%', minWidth: '10rem' }}></Column>
                        <Column field="unit" header={t("unit")} body={unitBodyTemplate} headerStyle={{ width: '25%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                    </DataTable>

                    <StockItemModalForm stockItemDialog={stockItemDialog} saveStockItem={saveStockItem} updateStockItem={updateStockItem} hideDialog={hideDialog} stockItemToEdit={stockItemEdit} />
                    <Dialog visible={deleteStockItemDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deleteStockItemDialogFooter} onHide={hideDeleteStockItemDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {stockItemDelete && <span>{t("areYouSureYouWantToDelete")} <b>{stockItemDelete.name}</b>?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={deleteStockItemsDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deletStockItemsDialogFooter} onHide={hideDeleteStockItemsDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {selectedStockItem && <span>{t("areYouSureYouWantToDelete")}?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(StockItem, comparisonFn);
