import { Button } from 'primereact/button';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import AuthContext from '../context';
import Customers from '../pages/Catering/Customers';
import OverviewService from '../service/OverviewService';
import UserService from '../service/UserService';
import { searchUserParams } from '../service/serviceObjects';
import { Chart } from 'primereact/chart';
import moment from 'moment'

const DashboardCatering = () => {
    const [menuItemsCount, setMenuItemsCount] = useState(0);
    const [customersCount, setCustomersCount] = useState(0);
    const [usersCount, setUsersCount] = useState(0);
    const [ordersCount, setOrdersCount] = useState(0);
    const [adminUsers, setAdminUsers] = useState([]);
    const authContext = useContext(AuthContext);

    const overviewService = new OverviewService();
    const userService = new UserService();
    const { t } = useTranslation();
    let history = useHistory();
    let currentDate = moment();

    let weekStart = currentDate.isoWeekday(1).format("YYYY-MM-DD");
    let weekEnd = currentDate.isoWeekday(7).format("YYYY-MM-DD");
    const [basicData, setBasicData] = useState({
        labels: [currentDate.isoWeekday(1).format("DD/MM/YYYY"), currentDate.isoWeekday(2).format("DD/MM/YYYY"), currentDate.isoWeekday(3).format("DD/MM/YYYY"), currentDate.isoWeekday(4).format("DD/MM/YYYY"), currentDate.isoWeekday(5).format("DD/MM/YYYY"), currentDate.isoWeekday(6).format("DD/MM/YYYY"), currentDate.isoWeekday(7).format("DD/MM/YYYY")],
        datasets: [
         
        ]
    });
    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };



        return {
            basicOptions
        }
    }
    useEffect(() => {
        let searchCountData = {
            customers: true,
            users: true,
            orders: true,
            menuItems: true,
            cateringId: authContext?.cateringId
        }
        overviewService.getCountOfEntities(searchCountData).then(data => {
            if (data.customersCount) {
                setCustomersCount(data.customersCount)
            }
            if (data.menuItemsCount) {
                setMenuItemsCount(data.menuItemsCount)
            }
            if (data.usersCount) {
                setUsersCount(data.usersCount)
            }
            if (data.ordersCount) {
                setOrdersCount(data.ordersCount)
            }
        });
        let searchUserObject = { ...searchUserParams }
        searchUserObject.cateringId = authContext?.cateringId
        userService.search(searchUserObject).then(data => {
            setAdminUsers(data.items)
        })

        let searchOverviewData = {
            cateringId: authContext?.cateringId,
            startDate: weekStart,
            endDate: weekEnd,

        }
        let listOfColors = ["#42A5F5", "#66BB6A", '#FFB300', "#66ccff", "#cc6600", "#ff99ff" ]
        overviewService.getCountOfEntitiesByCustomer(searchOverviewData).then(data => {
            let dataForChart = data.map((x, index) => ({
                label: x.customerName,
                backgroundColor: listOfColors[index],
                data: x?.orderDataOverviewListDTOS?.map(x=> x.numberOfOrders) ?? []
            }))
            let newChartData = {...basicData}
            newChartData.datasets = dataForChart
            setBasicData(newChartData)
        })
    }, []);
    const { basicOptions } = getLightTheme();

    return (
        <div className="layout-dashboard">
            <div className="grid">
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="overview-box sales">
                        <i className="overview-icon pi pi-palette"></i>
                        <span className="overview-title">{t('menuItems')}</span>
                        <i className="overview-arrow pi pi-chevron-circle-up"></i>
                        <div className="overview-numbers">
                            {menuItemsCount}
                        </div>
                        <div className="overview-subinfo">
                            <Button label={t('view')} className='p-button-rounded p-button-info' icon="pi pi-eye" onClick={() => history.push("/menuItems")} />
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="overview-box views">
                        <i className="overview-icon pi pi-building"></i>
                        <span className="overview-title">{t('companies')}</span>
                        <i className="overview-arrow pi pi-chevron-circle-up"></i>
                        <div className="overview-numbers">
                            {customersCount}
                        </div>
                        <div className="overview-subinfo">
                            <Button label={t('view')} className='p-button-rounded p-button-info' icon="pi pi-eye" onClick={() => history.push("/customers")} />

                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="overview-box checkin">
                        <i className="overview-icon pi pi-plus"></i>
                        <span className="overview-title">{t('orders')}</span>
                        <i className="overview-arrow pi pi-chevron-circle-up"></i>
                        <div className="overview-numbers">
                            {ordersCount}
                        </div>
                        <div className="overview-subinfo">
                            <Button label={t('view')} className='p-button-rounded p-button-info' icon="pi pi-eye" onClick={() => history.push("/orders")} />
                        </div>
                    </div>
                </div>
                <div className="col-12 lg:col-6 xl:col-3">
                    <div className="overview-box users">
                        <i className="overview-icon pi pi-users"></i>
                        <span className="overview-title">{t("users")}</span>
                        <i className="overview-arrow pi pi-chevron-circle-up"></i>
                        <div className="overview-numbers">
                            {usersCount}
                        </div>
                        <div className="overview-subinfo">
                            {/* <Button label={t('view')} className='p-button-rounded p-button-info' icon="pi pi-eye" onClick={() => history.push("/users")} /> */}
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    <div className="card">
                    <h5>{t('orders')} {currentDate.isoWeekday(1).format("DD/MM/YYYY") + " - " + currentDate.isoWeekday(7).format("DD/MM/YYYY")}</h5> 
                        <Chart type="bar" data={basicData} options={basicOptions} />
                    </div>
                </div>
                <div className="col-12">
                    <div className='grid'>
                        <div className='col-12 lg:col-8'>
                            <div className="card card-w-title tasks">
                                <h5>{t('customers')}</h5>
                                <Customers />
                            </div>
                        </div>
                        <div className="col-12 lg:col-4">
                            <div className='grid'>
                                <div className='col-12'>
                                    <div className="user-card card">
                                        <div className="user-card-header">
                                            <img src="assets/layout/images/dashboard/bg-header.png" alt="babylon-layout" className="profile-image" />
                                        </div>
                                        <div className="user-card-content">
                                            <img src="assets/layout/images/user-avatar.png" alt="babylon-layout" />

                                            <div className="user-card-name">
                                                <span>{authContext?.name}</span>
                                            </div>

                                            <div className="user-detail">
                                                <ul>
                                                    <li className="clearfix">
                                                        <i className="pi pi-user"></i>
                                                        <span className="project-title">{t('username')}</span>
                                                        <span className="project-detail">{authContext?.username}</span>
                                                    </li>
                                                    <li className="clearfix">
                                                        <i className="pi pi-user"></i>
                                                        <span className="project-title">{t('role')}</span>
                                                        <span className="project-detail">{authContext?.roleDTO.description}</span>
                                                    </li>
                                                    <li className="clearfix">
                                                        <i className="pi pi-envelope"></i>
                                                        <span className="project-title">{t('email')}</span>
                                                        <span className="project-detail">{authContext?.email}</span>
                                                    </li>
                                                    <li className="clearfix">
                                                        <i className="pi pi-phone"></i>
                                                        <span className="project-title">{t('phone')}</span>
                                                        <span className="project-detail">{authContext?.phone}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="card card-w-title team">
                                        <h5>{t('team')}</h5>

                                        <ul>
                                            {
                                                adminUsers?.map(x => {
                                                    return <li>
                                                        <img src="assets/layout/images/user-avatar.png" alt="babylon-layout" />
                                                        <div className="team-box">
                                                            <span className="team-member">{x.name}</span>
                                                            <span className="team-member-role">{x.roleDTO.name}</span>
                                                        </div>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(DashboardCatering, comparisonFn);
