import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import LicenseService from '../../service/LicenseService';

const LicenseForm = (props) => {
    const { licenseDialog, hideDialog, saveLicense, updateLicense, licenseToEdit, caterings } = props
    let defaultValues = {
        catering: '',
        licenseKey: '',
        status: '',
        dateActiveFrom: '',
        dateActiveTo: '',

    }
    const { t } = useTranslation();
    const licenseService = new LicenseService();

    const { control, formState: { errors }, handleSubmit, reset, setValue } = useForm({ defaultValues });
    let cateringOptions = []
    if (caterings?.length > 0) {
        cateringOptions = caterings.map(x => ({
            value: x.id, label: x.name
        }))
    }
    const generateLicenseKey = () => {
        licenseService.generateLicense().then(data => {
            setValue('licenseKey', data)
        })
    }
    useEffect(() => {
        if (licenseToEdit?.id) {
            setValue('id', licenseToEdit?.id)
        }
        if (licenseToEdit?.cateringDTO?.id) {
            setValue('catering', licenseToEdit?.cateringDTO?.id)
        }
        if (licenseToEdit?.licenseKey) {
            setValue('licenseKey', licenseToEdit?.licenseKey)
        }
        if (licenseToEdit?.status) {
            setValue('status', licenseToEdit?.status)
        }
        if (licenseToEdit?.dateActiveFrom) {
            setValue('dateActiveFrom', licenseToEdit?.dateActiveFrom)
        }
        if (licenseToEdit?.dateActiveTo) {
            setValue('dateActiveTo', licenseToEdit?.dateActiveTo)
        }
    }, [licenseToEdit])
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const submitFunction = (data) => {
        if (licenseToEdit) {
            updateLicense(data)
        } else {
            saveLicense(data)
        }
        reset()
    }
    const onHideDialog = () => {
        hideDialog()
        reset()
    }
    const licenseDialogFooter = (
        <div className='grid text-right'>
            <div className='col-offset-5 col-3'>
                <Button type="button" label={t('cancel')} icon="pi pi-times" className="p-button-text col-6" onClick={onHideDialog} />
            </div>
            <div className='col-3'>
                <Button label={t('save')} icon="pi pi-check" className="p-button-text col-6" type="submit" />
            </div>
        </div>
    );
    const statusOptions = [
        {
            label: t('VALID'), value: "VALID"
        },
        {
            label: t('EXPIRED'), value: "EXPIRED"
        },
        {
            label: t('SUSPENDED'), value: "SUSPENDED"
        }
    ]
    return (
        <Dialog visible={licenseDialog} style={{ width: '450px' }} header={t('newLicense')} modal className="p-fluid" onHide={onHideDialog}>
            <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                <div className="field">
                    <label htmlFor="catering" className={classNames({ 'p-error': errors.catering })}>{t('catering')}*</label>
                    <Controller name="catering" control={control} rules={{ required: t('cateringRequired') }} render={({ field, fieldState }) => (
                        <Dropdown id={field.name} {...field} disabled={licenseToEdit?.cateringDTO?.id} options={cateringOptions} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('catering')}
                </div>
                <div className="field">
                    <label htmlFor="licenseKey" className={classNames({ 'p-error': errors.licenseKey })}>{t('licenseKey')}*</label>
                    <Controller name="licenseKey" control={control} rules={{ required: t('licenseKeyRequired') }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} disabled className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('licenseKey')}
                    {!licenseToEdit?.licenseKey && <Button type="button" label={t('generate')} icon="pi pi-sync" className="col-6 lg:w-10rem mt-2" onClick={() => generateLicenseKey()} />}
                </div>
                <div className="field">
                    <label htmlFor="status" className={classNames({ 'p-error': errors.status })}>{t('status')}*</label>
                    <Controller name="status" control={control} rules={{ required: t('statusRequired') }} render={({ field, fieldState }) => (
                        <Dropdown id={field.name} {...field} options={statusOptions} className={classNames({ 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('status')}
                </div>
                <div className="field">
                    <label htmlFor="dateActiveFrom" className={classNames({ 'p-error': errors.dateActiveFrom })}>{t("dateActiveFrom")}*</label>
                    <Controller name="dateActiveFrom" control={control} rules={{ required: t("dateRequired") }} render={({ field, fieldState }) => (
                        <Calendar id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)} showIcon />)} />
                    {getFormErrorMessage('dateActiveFrom')}
                </div>
                <div className="field">
                    <label htmlFor="dateActiveTo" className={classNames({ 'p-error': errors.dateActiveTo })}>{t("dateActiveTo")}*</label>
                    <Controller name="dateActiveTo" control={control} rules={{ required: t("dateRequired") }} render={({ field, fieldState }) => (
                        <Calendar id={field.name} value={field.value} onChange={(e) => field.onChange(e.value)} showIcon />)} />
                    {getFormErrorMessage('dateActiveTo')}
                </div>
                {licenseDialogFooter}
            </form>
        </Dialog>

    );
};

export default LicenseForm;