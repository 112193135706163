import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { ProgressBar } from "primereact/progressbar";
import { classNames } from "primereact/utils";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomerService from "../../service/CustomerService";

const MicrosoftAuthComponent = (props) => {
    const { showDialog, loginFunction, onBack, errorMessage } = props
    const [value, setValue] = useState('');
    const [domainInvalid, setDomainInvalid] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false)

    const [currentCustomerAuth, setCurrentCustomerAuth] = useState();
    const customersService = new CustomerService();
    const { t } = useTranslation();

    const checkDomain = (event) => {
        setDomainInvalid(false)
        setCurrentCustomerAuth()
        if (event.target.value && event.target.value != '') {
            setShowProgressBar(true)
            customersService.getCustomerByDomain(event.target.value).then((response) => {
                if (response?.externalAuthDTO) {
                    setCurrentCustomerAuth({ ...response?.externalAuthDTO, customerId: response.id })
                } else {
                    setDomainInvalid(true)
                }
            }).catch(() => {
                setDomainInvalid(true)

            }).finally(() => {
                setTimeout(() => {
                    setShowProgressBar(false)
                }, 1000);
            })
        } else {
            setDomainInvalid(false)
        }

    }
    return (
        <Dialog visible={showDialog} style={{ width: '450px', height: '500px' }} header={t("domainSelection")} showHeader={false} modal>
            {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px', marginTop: "5px" }}></ProgressBar>}
            {errorMessage && <Message severity="error" text={t(errorMessage)} className='w-full mt-3' />}

            <div className="text-center mb-5 mt-5">
                <img src="assets/layout/images/LOGO.svg" alt="hyper" height={90} className="mb-3" />
            </div>
            <div className='text-center p-1'>
                <img src="https://docs.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_mssymbol_19.png" alt="Sign in with Microsoft" />
                <span className='ml-2'>{t('loginMicrosoftTitle')}
                </span>
            </div>
            <div className="text-left mt-4 w-24rem mx-5">
                <label htmlFor="domain" className="block">{t('domain')}</label>
                <InputText name="domain" value={value} onChange={(e) => setValue(e.target.value)} className={classNames({ 'p-invalid': domainInvalid, 'w-full': true })} onBlur={(event) => checkDomain(event)} disabled={showProgressBar} />
                {!domainInvalid && !currentCustomerAuth && <small id="username1-help" className="block">{t('domainFormat')} <b>example.com</b></small>
                }
                {domainInvalid && <small id="username2-help" className="p-error block">{t('domainIsNotAvailable')}</small>
                }
                {!domainInvalid && currentCustomerAuth && <small id="p-sucess" className="text-green-300">{t('domainValid')}</small>
                }
            </div>
            <div className="text-center mt-5">
                <Button label={t('login')} icon="pi pi-user" className="loginMcr" onClick={() => loginFunction(currentCustomerAuth)} disabled={!currentCustomerAuth} />
            </div>
            <div className='backToLogin text-left cursor-pointer p-1 mt-2' onClick={() => { onBack() }}>
                <i className='pi pi-arrow-left' />
                <span className='ml-2'>{t('backToStandardLogin')}
                </span>
            </div>
        </Dialog>
    );
}
export default MicrosoftAuthComponent;