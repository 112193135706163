import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import ItemCategoryService from '../../../service/ItemCategoryService';
import ItemCategoryModalForm from './ItemCategoryModalForm';
import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import { searchItemCategoryParams } from '../../../service/serviceObjects';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../context';

const ItemCategory = () => {
    const authContext = useContext(AuthContext);

    let itemCategory = {
        cateringId: authContext.cateringId || 0,
        name: null,
        description: null
    }
    const [itemCategoryDialog, setItemCategoryDialog] = useState(false);
    const [deleteItemCategoryDialog, setDeleteItemCategoryDialog] = useState(false);
    const [deleteItemCategoriesDialog, setDeleteItemCategoriesDialog] = useState(false);
    const [itemCategories, setItemCategories] = useState([]);
    const [selectedItemCategory, setSelectedItemCategory] = useState(null);
    const [itemCategoryEdit, setItemCategoryEdit] = useState(null)
    const [itemCategoryDelete, setItemCategoryDelete] = useState(null)
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const itemCategoryService = new ItemCategoryService();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const { t } = useTranslation();

    useEffect(() => {
        getAllItemCategories()
    }, []);
    const getAllItemCategories = (searchData) => {
        setLoadingTable(true)
        let searchObject = searchItemCategoryParams
        if (searchData) {
            searchObject = { ...searchItemCategoryParams, ...searchData }
        }
        searchObject.cateringId = authContext.cateringId ?? 0
        itemCategoryService.search(searchObject).then(data => {
            setFirst(data.pageNumber * data.pageSize);
            setRows(data.pageSize);
            setTotalRecords(data.itemCount)
            setItemCategories(data.items)
            setLoadingTable(false)
        })

    }
    const openNew = () => {
        setItemCategoryDialog(true);
    }

    const hideDialog = () => {
        if (itemCategoryEdit) {
            setItemCategoryEdit()
        }
        setItemCategoryDialog(false);
    }

    const hideDeleteItemCategoryDialog = () => {
        setItemCategoryDelete();
        setDeleteItemCategoryDialog(false);
    }

    const hideDeleteItemCategoriesDialog = () => {
        setDeleteItemCategoriesDialog(false);
    }
    const saveItemCategory = (data) => {
        if (data) {
            let submitRequest = { ...itemCategory, ...data }
            itemCategoryService.save(submitRequest).then((value) => {
                setItemCategoryDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('itemCategoryAdded'), life: 3000 })
                getAllItemCategories()
            }).catch(reason => {
                setItemCategoryDialog(false)
                toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorSavingItemCategory'), life: 3000 })
            })
        }
    }
    const updateItemCategory = (data) => {
        if (data) {
            let submitRequest = { ...itemCategory, ...data }
            itemCategoryService.update(submitRequest.id, submitRequest).then((value) => {
                setItemCategoryDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('itemCategoryUpdated'), life: 3000 })
                getAllItemCategories()
            })
        }
    }
    const editItemCategory = (item) => {
        setShowProgressBar(true)
        itemCategoryService.get(item.id).then((data) => {
            setShowProgressBar(false)
            setItemCategoryEdit(data)
            setItemCategoryDialog(true);
        })
    }

    const confirmDeleteItemCagegory = (item) => {
        setItemCategoryDelete(item);
        setDeleteItemCategoryDialog(true);
    }

    const deleteItemCategory = () => {
        itemCategoryService.delete(itemCategoryDelete.id).then(data => {
            setItemCategoryDelete(null);
            setDeleteItemCategoryDialog(false);
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('itemCategoryDeleted'), life: 3000 });
            getAllItemCategories()
        })
    }
    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteItemCategoriesDialog(true);
    }

    async function deleteSelectedItemCategory() {
        await selectedItemCategory.forEach(element => {
            itemCategoryService.delete(element.id).then(data => {
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('itemCategoryDeleted'), life: 3000 });
            })
        });
        setDeleteItemCategoriesDialog(false);
        setSelectedItemCategory(null);
        getAllItemCategories()

    }
    const onPage = (event) => {
        let searchParams = searchItemCategoryParams
        searchParams.pageNumber = event.page
        searchParams.pageSize = event.rows
        getAllItemCategories(searchParams)
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("new")} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label={t("delete")} icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedItemCategory || !selectedItemCategory.length} />
                </div>
            </React.Fragment>
        )
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("name")}</span>
                {rowData.name}
            </>
        );
    }

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("description")}</span>
                <span className={`status`}>{rowData.description}</span>
            </>
        )
    }
    const imageBodyTemplate = (rowData) => {
        return (
            <>
                <img src={rowData.image ? "data:image/png;base64," + rowData.image : "assets/layout/images/blank_category.png"} alt={rowData.name} className="w-3rem" />
            </>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions mt-2 text-center">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editItemCategory(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => confirmDeleteItemCagegory(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t("manageItemCategories")}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t("search")} />
            </span>
        </div>
    );

    const deleteItemCategoryDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteItemCategoryDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteItemCategory} />
        </>
    );
    const deletItemCategoriesDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteItemCategoriesDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItemCategory} />
        </>
    );
    const paginationTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("previous")}</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("next")}</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'All', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        }
    };
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    <DataTable ref={dt} value={itemCategories} selection={selectedItemCategory} onSelectionChange={(e) => setSelectedItemCategory(e.value)}
                        dataKey="id" paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate={t('defaultPaginationMessage')} onPage={onPage}
                        globalFilter={globalFilter} emptyMessage={t('noItemsFound')} header={header} responsiveLayout="scroll" loading={loadingTable}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="image" header={t("image")} body={imageBodyTemplate} headerStyle={{ width: '10%', minWidth: '5rem' }}></Column>
                        <Column field="name" header={t("name")} body={nameBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                        <Column field="description" header={t("description")} body={descriptionBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                    </DataTable>

                    <ItemCategoryModalForm itemCategoryDialog={itemCategoryDialog} saveItemCategory={saveItemCategory} updateItemCategory={updateItemCategory} hideDialog={hideDialog} itemCategoryToEdit={itemCategoryEdit} />
                    <Dialog visible={deleteItemCategoryDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deleteItemCategoryDialogFooter} onHide={hideDeleteItemCategoryDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {itemCategoryDelete && <span>{t("areYouSureYouWantToDelete")} <b>{itemCategoryDelete.name}</b>?</span>}
                        </div>
                    </Dialog>
                    <Dialog visible={deleteItemCategoriesDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deletItemCategoriesDialogFooter} onHide={hideDeleteItemCategoriesDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {selectedItemCategory && <span>{t("areYouSureYouWantToDelete")}?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ItemCategory, comparisonFn);
