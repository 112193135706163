import { Controller, useForm } from "react-hook-form";
import UserService from "../../service/UserService";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import CaptchaComponent from "./CaptchaComponent";
import { useState } from "react";

const ForgotPassword = () => {
    const [captchaOk, setCaptchaOk] = useState(false)

    let defaultValues = {
        email: ''
    }
    const { t } = useTranslation()
    const usersService = new UserService();

    const { control, formState: { errors }, handleSubmit, setError, reset } = useForm({ defaultValues: defaultValues, mode: 'all' });

    const submitFunction = (data) => {
        usersService.forgotPassword(data?.email).then(response => {
            window.location = "/"
        }).catch((error) => {
            setError('email', { type: 'custom', message: 'Not valid email' });
        }).finally(() => {

        })
    }
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    return (
        <div className="surface-card p-4 m-2 shadow-2 border-round w-full lg:w-3">
            <div className="text-center mb-5">
                <img src="assets/layout/images/LOGO.svg" alt="hyper" height={90} className="mb-3" />
                <div className="text-900 text-3xl font-medium mb-3">{t('forgotPassword')}</div>
            </div>
            <p className="text-gray-600">1. {t('forgotPasswordMessage1')}</p>
            <p className="text-gray-600">2. {t('forgotPasswordMessage2')}</p>
            <p className="text-gray-600">3. {t('forgotPasswordMessage3')}</p>
            <form onSubmit={handleSubmit(submitFunction)} className="p-fluid">
                <div className='col-12'>
                    <div className="field">
                        <label htmlFor="email" className={classNames({ 'p-error': errors.email })}>{t('email')}*</label>
                        <Controller name="email" control={control} rules={{
                            required: t('emailRequired'),
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: t('invalidEmailFormat')
                            }
                        }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        {getFormErrorMessage('email')}
                    </div>
                </div>
                <div className="col-12 flex justify-content-end">

                    <div className="w-15rem mb-3">
                        <CaptchaComponent onSuccess={() => setCaptchaOk(true)} />

                    </div>
                </div>

                <div className='flex justify-content-end px-5'>
                    <Button className='loginBtn' label={t('reset')} icon="pi pi-check" disabled={!captchaOk} />
                </div>
                <div className='flex justify-content-end px-5 pt-3'>
                    <Button className='p-button-text' type="button" label={t('backToLogin')} icon="pi pi-sign-in" onClick={() => window.location = "/"} />
                </div>
            </form>
        </div>


    );
}

export default ForgotPassword;