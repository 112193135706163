import React, { useContext, useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import MenuService from '../../../service/MenuService';
import { searchCustomerParams, searchMenuParams } from '../../../service/serviceObjects';
import ShareMenuFormModal from '../ShareMenuFormModal';
import { Toast } from 'primereact/toast';
import AuthContext from '../../../context';
import CustomerService from '../../../service/CustomerService';
const ActiveMenuOverview = () => {
    const authContext = useContext(AuthContext);

    // initial state
    const [calendarData, setCalendarData] = useState([]);
    const [externalEvents, setExternalEvents] = useState([])
    const [menuToShare, setMenuToShare] = useState(null);
    const [shareMenuDialog, setShareMenuDialog] = useState(false)
    const [initialMenuInfo, setInitialMenuInfo] = useState()
    const [myCustomers, setMyCustomers] = useState([])

    const toast = useRef(null);

    const menuService = new MenuService();
    const customerService = new CustomerService();
    const hideShareMenuModal = () => {
        setShareMenuDialog(false)
        setMenuToShare()
    }
    const saveSharedMenu = (item) => {
        let requestObject = {
            comment: item.comment,
            menuDate: item.menuDate,
            dateActiveFrom: item.activeMenuValidityDate[0],
            dateActiveTo: item.activeMenuValidityDate[1],
            menuDTO: menuToShare
        }
        menuService.share(requestObject).then((value) => {
            setShareMenuDialog(false)
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Menu shared', life: 3000 })
            setMenuToShare()
        }).catch(reason => {
            setShareMenuDialog(false)
            setMenuToShare()
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error with sharing menu', life: 3000 })
        })
    }
    const convertActiveMenusToCalendarData = (activeMenu) => {
        return {
            id: activeMenu.id,
            title: activeMenu.menuDTO.name,
            color: "#388e3c",
            start: activeMenu.menuDate,
            end: activeMenu.menuDate,
            custom: "questo è un campo custom"
        }
    }
    const returnCalendarData = (activeMenuList) => {
        let data = []
        if (activeMenuList?.length > 0) {
            activeMenuList.forEach(element => {
                data.push(convertActiveMenusToCalendarData(element))
            });
        }
        return data
    }
    const convertMenusToExternalItems = (menuList) => {
        let data = []
        if (menuList?.length > 0) {
            menuList.forEach(element => {
                let singleObject = { title: element.name, color: "#0097a7", id: element.id }
                data.push(singleObject)
            });
        }
        return data
    }
    const getActiveMenus = () => {
        let searchObject = {}
        if (authContext?.cateringId) {
            searchObject.cateringId = authContext.cateringId
        }
        menuService.searchActiveMenus(searchObject).then(data => {
            setCalendarData(returnCalendarData(data.items))
        })
    }
    const getAllMenus = (searchData) => {
        let searchObject = searchMenuParams
        if (searchData) {
            searchObject = { ...searchMenuParams, ...searchData }
        }
        menuService.search(searchObject).then(data => {
            setExternalEvents(convertMenusToExternalItems(data.items))
        })

    }
    useEffect(() => {
        getAllMenus()
        getActiveMenus()
        let draggableEl = document.getElementById("external-events");
        new Draggable(draggableEl, {
            itemSelector: ".fc-event",
            eventData: function (eventEl) {
                let id = eventEl.dataset.id;
                let title = eventEl.getAttribute("title");
                let color = eventEl.dataset.color;
                let custom = eventEl.dataset.custom;
                return {
                    id: id,
                    title: title,
                    color: color,
                    custom: custom,
                    create: true
                };
            }
        });
    }, []);
    const handleEventReceive = (eventInfo) => {
        setInitialMenuInfo({
            menuDate: eventInfo.event.start
        })
        setMenuToShare({
            id: eventInfo.draggedEl.getAttribute("data-id"),
            name: eventInfo.draggedEl.getAttribute("title")
        })
        let searchObject = searchCustomerParams
        searchObject.pageSize = 1000
        searchObject.cateringId = authContext.cateringId
        customerService.search(searchObject).then(data => {
            setMyCustomers(data.items)
            setShareMenuDialog(true)

        })
        setShareMenuDialog(true)
        const newEvent = {
            id: eventInfo.draggedEl.getAttribute("data-id"),
            title: eventInfo.draggedEl.getAttribute("title"),
            color: eventInfo.draggedEl.getAttribute("data-color"),
            start: eventInfo.date,
            end: eventInfo.date,
            custom: eventInfo.draggedEl.getAttribute("data-custom")
        };
        setCalendarData(calendarData.concat(newEvent));

    };
    return (
        <div className="App grid">
            <Toast ref={toast} />
            <div className='col-3'>
                <div className='card m-1 h-full' id="external-events">
                    {externalEvents.map((event) => (
                        <div
                            className="fc-event fc-h-event mb-1 fc-daygrid-event fc-daygrid-block-event p-2"
                            title={event.title}
                            data-id={event.id}
                            data-color={event.color}
                            data-custom={event.custom}
                            key={event.id}
                            style={{
                                backgroundColor: event.color,
                                borderColor: event.color,
                                cursor: "pointer"
                            }}
                        >
                            <div className="fc-event-main">
                                <div>
                                    <strong>{event.title}</strong>
                                </div>
                                {event.custom}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='col-9'>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,timeGridWeek,timeGridDay"
                    }}
                    initialView="dayGridMonth"
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    events={calendarData}
                    droppable={true}
                    eventReceive={handleEventReceive}
                />
            </div>
            {menuToShare && <ShareMenuFormModal myCustomers={myCustomers} shareMenuDialog={shareMenuDialog} hideDialog={hideShareMenuModal} shareMenu={saveSharedMenu} menuToShare={menuToShare} initialMenuData={initialMenuInfo} disableMenuDate={true} />}

        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ActiveMenuOverview, comparisonFn);
