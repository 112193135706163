import { Toast } from 'primereact/toast';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
const CustomerBasicInfo = (props) => {
    const { customer } = props
    const { t } = useTranslation();
    const toast = useRef(null);

    const handleCopy = () => {
        navigator.clipboard
            .writeText(customer.registerKey)
            .then(() => toast.current.show({ severity: 'success', summary: t('success'), detail: t('copied'), life: 3000 })
            )
    };
    return (
        <div className='grid'>
            <Toast ref={toast} />
            <div className='col-12 mt-3'>
                <h4>{t('basicInformation')}</h4>
            </div>
            <div className='col-4 p-3 border-bottom-1 border-300 '><i className='pi pi-info-circle text-lg'></i> {t('companyName')}</div>
            {customer?.name && <div className='col-8 font-bold p-3 border-bottom-1 border-300'>{customer.name}</div>}
            <div className='col-4 p-3 border-bottom-1 border-300'><i className='pi pi-question-circle text-lg'></i> {t('description')}</div>
            {customer?.description && <div className='col-8 font-bold p-3 border-bottom-1 border-300'>{customer.description}</div>}
            <div className='col-4 p-3 border-bottom-1 border-300'><i className='pi pi-home text-lg'></i> {t('address')}</div>
            {customer?.address && <div className='col-8 font-bold p-3 border-bottom-1 border-300'>{customer.address}  </div>}
            <div className='col-12 mt-3'>
                <h4>{t('contactData')}</h4>
            </div>
            <div className='col-4 p-3 border-bottom-1 border-300'><i className='pi pi-envelope text-lg'></i> {t('email')}</div>
            {customer?.email && <div className='col-8 font-bold p-3 border-bottom-1 border-300'>{customer.email}</div>}
            <div className='col-4 p-3 border-bottom-1 border-300'><i className='pi pi-phone text-lg'></i> {t('phone')}</div>
            {customer?.phone && <div className='col-8 font-bold p-3 border-bottom-1 border-300'>{customer.phone}</div>}
            <div className='col-12 mt-3'>
                <h4>{t('registerKey')}</h4>
            </div>
            <div className='col-4 p-3 border-bottom-1 border-300'><i className='pi pi-envelope text-lg'></i> {t('registerKeyMessage')}</div>
            <div className='col-3 font-bold p-3 border-bottom-1 border-300 surface-200 text-center'>{customer.registerKey}</div> <div className='mt-3 ml-3 cursor-pointer text-gray-500' onClick={handleCopy}>{t('copy')}</div>
        </div>
    )
}


export default CustomerBasicInfo