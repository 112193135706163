import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Ripple } from 'primereact/ripple';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { classNames } from 'primereact/utils';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CateringService from '../../service/CateringService';
import { searchCateringParams } from '../../service/serviceObjects';
import CateringFormDialog from './CateringForm';

const CateringTask = () => {
    const [newCateringDialog, setNewCateringDialog] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [caterings, setCaterings] = useState([]);
    const [selectedCatering, setSelectedCatering] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [totalRecords, setTotalRecords] = useState(10);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loadingTable, setLoadingTable] = useState(false);
    const [cateringToEdit, setCateringToEdit] = useState(null)
    const [deleteCateringDialog, setDeleteCateringDialog] = useState(false);
    const [cateringToDelete, setCateringToDelete] = useState(null)
    const history = useHistory();

    const { t } = useTranslation();
    const toast = useRef(null);
    const dt = useRef(null);
    const cateringService = new CateringService();
    useEffect(() => {
        getAllCaterings()
    }, [])
    const openNew = () => {
        setNewCateringDialog(true);
    }
    const onPage = (event) => {
        let searchParams = searchCateringParams
        searchParams.pageNumber = event.page
        searchParams.pageSize = event.rows
        getAllCaterings(searchParams)
    }
    const saveCatering = (data) => {
        if (data) {
            let submitRequest = { ...data }
            cateringService.save(submitRequest).then((value) => {
                setNewCateringDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('cateringAdded'), life: 3000 })
                getAllCaterings()
            }).catch(reason => {
                setNewCateringDialog(false)
                toast.current.show({ severity: 'error', summary: t('error'), detail: t('errorSavingCatering'), life: 3000 })
            })
        }
    }
    const updateCatering = (data) => {
        if (data) {
            let submitRequest = { ...data }
            cateringService.update(cateringToEdit.id, submitRequest).then((value) => {
                setNewCateringDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('cateringUpdated'), life: 3000 })
                getAllCaterings()
            })
        }
    }
    const getAllCaterings = (searchData) => {
        setLoadingTable(true)
        let searchObject = searchCateringParams
        if (searchData) {
            searchObject = { ...searchCateringParams, ...searchData }
        }
        cateringService.search(searchObject).then(data => {
            setFirst(data.pageNumber * data.pageSize);
            setRows(data.pageSize);
            setTotalRecords(data.itemCount)
            setCaterings(data.items)
            setLoadingTable(false)
        })

    }
    const editCatering = (item) => {
        setShowProgressBar(true)
        cateringService.get(item.id).then((data) => {
            setShowProgressBar(false)
            setCateringToEdit(data)
            setNewCateringDialog(true);
        })
    }
    const hideDialog = () => {
        if (cateringToEdit) {
            setCateringToEdit()
        }
        setNewCateringDialog(false);
    }
    const hideDeleteCateringDialog = () => {
        setDeleteCateringDialog(false);
    }
    const confirmDeleteCatering = (item) => {
        setCateringToDelete(item);
        setDeleteCateringDialog(true);
    }
    async function deleteSelectedCatering() {
        cateringService.delete(cateringToDelete.id).then(data => {
            setCateringToDelete(null);
            setDeleteCateringDialog(false);
            toast.current.show({ severity: 'success', summary: t('successful'), detail: t('cateringDeleted'), life: 3000 });
            getAllCaterings()
        })
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("new")} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    }
    const paginationTemplate = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("previous")}</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">{t("next")}</span>
                    <Ripple />
                </button>
            )
        },
        'PageLinks': (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return <span className={className} style={{ userSelect: 'none' }}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            )
        },
        'RowsPerPageDropdown': (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 'All', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        }
    };
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t("manageCaterings")}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t("search")} />
            </span>
        </div>
    )
    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("name")}</span>
                {rowData.name}
            </>
        );
    }

    const descriptionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("description")}</span>
                <span className={`status`}>{rowData.description}</span>
            </>
        )
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions mt-2 text-center">
                <Button icon="pi pi-eye" className="p-button-rounded p-button-info mr-2" onClick={() => history.push({
                    pathname: '/cateringOverview',
                    state: { ...rowData }
                })} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editCatering(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning " onClick={() => confirmDeleteCatering(rowData)} />
            </div>
        );
    }
    const deleteCateringDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteCateringDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCatering} />
        </>
    )
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    <DataTable ref={dt} value={caterings} selection={selectedCatering} onSelectionChange={(e) => setSelectedCatering(e.value)}
                        dataKey="id" paginator rows={rows} first={first} totalRecords={totalRecords} lazy className="datatable-responsive"
                        paginatorTemplate={paginationTemplate}
                        currentPageReportTemplate={t('defaultPaginationMessage')} onPage={onPage}
                        globalFilter={globalFilter} emptyMessage={t('noItemsFound')} header={header} responsiveLayout="scroll" loading={loadingTable}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="name" header={t("name")} body={nameBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                        <Column field="description" header={t("description")} body={descriptionBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                    </DataTable>
                    <CateringFormDialog cateringDialog={newCateringDialog} saveCatering={saveCatering} updateCatering={updateCatering} hideDialog={hideDialog} cateringToEdit={cateringToEdit} />
                    <Dialog visible={deleteCateringDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deleteCateringDialogFooter} onHide={hideDeleteCateringDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {cateringToDelete && <span>{t("areYouSureYouWantToDelete")} <b>{cateringToDelete.name}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )

}
const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(CateringTask, comparisonFn);
