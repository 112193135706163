import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../context';
// import { searchCustomersLocationsParams } from '../../service/serviceObjects';
import CustomerService from '../../service/CustomerService';
import CustomerLocationFormDialog from './CustomerLocationsForm';
// import AddCustomersLocationsForm from './AddCustomersLocationsForm';
const CustomersLocations = (props) => {
    const { customerData, getCustomerData } = props;
    const [newCustomersLocationsDialog, setNewCustomersLocationsDialog] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [customersLocations, setCustomersLocations] = useState([]);
    const [selectedCustomersLocations, setSelectedCustomersLocations] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [loadingTable, setLoadingTable] = useState(false);
    const [customerLocationToEdit, setCustomerLocationToEdit] = useState(null)
    const [deleteCustomersLocationsDialog, setDeleteCustomersLocationsDialog] = useState(false);
    const [customersLocationsToDelete, setCustomersLocationsToDelete] = useState(null)
    const authContext = useContext(AuthContext);

    const { t } = useTranslation();
    const toast = useRef(null);
    const dt = useRef(null);
    const customersService = new CustomerService();
    useEffect(() => {
        if (customerData?.locationDTOS?.length > 0) {
            setCustomersLocations(customerData.locationDTOS)

        }
    }, [customerData, customerData?.locationDTOS?.length])
    const openNew = () => {
        setNewCustomersLocationsDialog(true);
    }

    const updateCustomersLocations = (data, locationId) => {
        let locations = []
        if (customerData?.locationDTOS) {
            locations = [...customerData.locationDTOS]
        }
        if (locationId) {
            let indexOfElement = locations.findIndex(x => x.id == locationId)
            if (indexOfElement != -1) {
                locations[indexOfElement] = data
            }
        } else {
            locations.push(data)
        }
        if (data) {
            let submitRequest = { ...data }
            submitRequest.locationDTOS = locations
            customersService.update(customerData.id, submitRequest).then((value) => {
                setNewCustomersLocationsDialog(false)
                getCustomerData()
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('customerUpdated'), life: 3000 })
            })
        }
    }

    const editCustomersLocations = (item) => {
        setCustomerLocationToEdit(item)
        setNewCustomersLocationsDialog(true);
    }
    const hideDialog = () => {
        if (customerLocationToEdit) {
            setCustomerLocationToEdit()
        }
        setNewCustomersLocationsDialog(false);
    }
    const hideDeleteCustomersLocationsDialog = () => {
        setDeleteCustomersLocationsDialog(false);
    }
    const confirmDeleteCustomersLocations = (item) => {
        setCustomersLocationsToDelete(item);
        setDeleteCustomersLocationsDialog(true);
    }
    async function deleteSelectedCustomersLocations() {
        let locations = [...customerData.locationDTOS]
        if (customersLocationsToDelete) {
            locations = locations.filter(x => x.id != customersLocationsToDelete.id)
            let submitRequest = { ...customerData }
            submitRequest.locationDTOS = locations
            customersService.update(customerData.id, submitRequest).then((value) => {
                setNewCustomersLocationsDialog(false)
                toast.current.show({ severity: 'success', summary: t('successful'), detail: t('customerUpdated'), life: 3000 })
            })
        }
    }
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label={t("new")} icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                </div>
            </React.Fragment>
        )
    }
    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{t("manageCustomersLocations")}</h5>
        </div>
    )
    const customersLocationsNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("name")}</span>
                {rowData.locationName}
            </>
        );
    }

    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{t("address")}</span>
                <span className={`status`}>{rowData.locationAddress}</span>
            </>
        )
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions mt-2 text-center">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editCustomersLocations(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning " onClick={() => confirmDeleteCustomersLocations(rowData)} />
            </div>
        );
    }
    const deleteCustomersLocationsDialogFooter = (
        <>
            <Button label={t("no")} icon="pi pi-times" className="p-button-text" onClick={hideDeleteCustomersLocationsDialog} />
            <Button label={t("yes")} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedCustomersLocations} />
        </>
    )
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                    {showProgressBar && <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
                    <DataTable ref={dt} value={customersLocations} selection={selectedCustomersLocations} onSelectionChange={(e) => setSelectedCustomersLocations(e.value)}
                        dataKey="id" lazy className="datatable-responsive"
                        globalFilter={globalFilter} emptyMessage={t('noItemsFound')} header={header} responsiveLayout="scroll" loading={loadingTable}>
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="id" hidden headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                        <Column field="locationName" header={t("name")} body={customersLocationsNameBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                        <Column field="locationAddress" header={t("address")} body={addressBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate} headerStyle={{ width: '33%', minWidth: '10rem' }}></Column>
                    </DataTable>
                    <CustomerLocationFormDialog customerLocationDialog={newCustomersLocationsDialog} updateCustomerLocation={updateCustomersLocations} hideDialog={hideDialog} customerLocationToEdit={customerLocationToEdit} />
                    <Dialog visible={deleteCustomersLocationsDialog} style={{ width: '450px' }} header={t("confirm")} modal footer={deleteCustomersLocationsDialogFooter} onHide={hideDeleteCustomersLocationsDialog}>
                        <div className="flex align-items-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {customersLocationsToDelete && <span>{t("areYouSureYouWantToDelete")} <b>{customersLocationsToDelete.locationName}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default CustomersLocations;