import { JWT_NAME } from "../constants/auth"
import { getCookie } from "../utilities/general"

export const BLCUrl = "https://catering-bls-test-ffba5df5f1e5.herokuapp.com"
// export const BLCUrl = "http://DESKTOP-MIKUVMM:5000"
// export const BLCUrl = "http://192.168.145.93:5000"
export const NodeSericeURl = "https://catering-ui-test-f2e99de59fcf.herokuapp.com"
const token = getCookie(JWT_NAME)
export const defaultHeader = {
    "Access-Control-Allow-Origin": "*",
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': 'Bearer ' + token
} 